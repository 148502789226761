.bwi-modal-checkdelists {
  max-height: 480px;
  width: 100%;
}

.bwi-modal-checkdelists-header {
  height: 90px !important;
}

.bwi-modal-checkDelist-body {
  max-height: 180px;
  min-height: 230px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 25px;
  padding-right: 5px;
  width: 99%;
}

ul.bwi-modal-checkDelist_nav {
  left: 0%;
  margin-left: 16px;
  position: relative;
  padding-bottom: 15px;
}

ul.bwi-modal-checkDelist_nav li {
  width: 164px;
}

.bwi-checkdelists-header-row,
.bwi-checkdelists-data-row {
  padding-left: 16px;
  padding-bottom: 6px;
  word-break: break-word;
  text-align: left;
}

.bwi-checkdelists-header-col,
.bwi-checkdelists-data-col {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.bwi-checkdelists-footer {
  padding: 10px 5px 10px 20px;
  display: flex;
  justify-content: space-between;
}

/* 
.bwi-checkdelists-row {
    align-items: center;
    display: flex;
    margin-left: 10px;
    word-break: break-word;
} */
.bwi-e2e-planner-container{
  padding: 30px 0 40px 25px;
  display: flex;
}
.bwi-e2e-planner-container input{
  background-color: transparent;
    border: 1px solid #797775;
    color: #edf1f3;
    height: 24px;
    font-size: 12px;
    font-weight: 400;
    width: 100%;
}
.flex{
  display: flex;
}
.justify-between{
  justify-content: space-between;
}
.items-end{
  align-items: flex-end;
}

.bwi-e2e-planner-field{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.bwi-e2e-planner-field label{
  color: #8a8d8c;
    font-family: Unilever Shilling;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 11px;
}
.items-center{
  align-items: center;
}
.bwi-e2e-planner-field .dsf-checkinnoflex-date-edit-img{
  height: 18px;
  width: 18px;
}
.relative{
  position: relative;
}
.bwi-e2e-planner-field .bwi-error-msg{
  color: #f00;
  position: absolute;
  top: 45px;
}
.bwi-e2e-planner-email{
  border: 1px solid #797775;
  background-color: transparent;
}

.bwi-delist-flag{
  position: relative;
  
}
.bwi-flag-tooltip-container{
  position: relative;
  display: inline-block;
  right: 20px;
  cursor: pointer;
}
.bwi-flag-tooltip-container img{
  display: block;
}
.bwi-flag-tooltip{
  border: 1px solid #C5C5C5;
  background-color: #1E232F;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  padding: 24px;
  width: max-content;
  position: absolute;
  top: -74px;
  right: -8px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s;
}

.bwi-checkdelists-data-col .dsf-textField{
  width: 95%;
}
.bwi-flag-tooltip-container:hover .bwi-flag-tooltip{
  opacity: 1;
  visibility: visible;
  cursor: pointer;
}

.bwi-email-value-container{
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin: 4px 0px 15px 25px;
}

.value-chip{
  display: flex;
  align-items: center;
  background:#8fcaeb;
  margin-right: 4px;
}

.value-chip-text{
    padding: 4px;
    color: #292929;
    font-family: Unilever Shilling;
    font-size: 12px;
}

.value-chip-remove{
  color: #292929;
  background-color: white;
  padding: 3px 7px;
  cursor: pointer;
}

.bwi-e2e-planner-field-lable-info{
  display: flex;
}

/* .value-chip button{
  margin-left: 4px;
} */

.bwi-review-su-header{
  display: flex;
  align-items: baseline;
}