.dsf-dialog-delistingProjModal {
  min-width: 694px;
  /* min-height: 540px; */
  max-width: 694px !important ;
  max-height: 540px !important;
}

.bw-modal-delistingProj-header {
  padding-top: 10px;
  padding-bottom: 0px;
  padding-left: 15px;
  border: unset;
  text-align: left;
}

.bw-modal-delistProj-body {
  padding-bottom: 0px;
  padding-top: 30px;
}

.dsf-delistProj-input {
  max-height: unset !important;
  height: 27px !important;
}

.dsf-delistProj-error-msg {
  height: 0px;
}

.dsf-delistProj-error-msg .errorMsg {
  padding-left: unset !important;
}

.dsf-dialog-delistingProjModal .dsf-modal-workflowModal {
  width: 694px !important;
  min-height: 430px !important;
  max-height: 480px !important;
  padding-left: 4px;
}

.dsf-delistingProj-row {
  margin-bottom: 10px;
}

.dsf-delistingProj-footer-div {
  padding-top: 0px;
}

.delistProj-dropdown .MuiSelect-select {
  text-align: right;
  margin-right: 15px;
}

.dsf-delistingProj-right-label {
  padding-left: 23px;
}

.dsf-delistingProj-startdatepicker .dsf-custom-datepicker-open {
  transform: translate3d(-7px, -318px, 0px);
}

.dsf-delistingProj-enddatepicker .dsf-custom-datepicker-open {
  transform: translate3d(-313px, -318px, 0px);
}

.delistProj-autocomplete .MuiAutocomplete-inputRoot {
  border: unset;
  max-width: unset;
  width: 487px;
  height: 29px;
  position: relative;
  border: 1px solid #797775;
  border-radius: 0px;
}

.delistProj-autocomplete .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  border: unset;
}

.delistProj-autocomplete .MuiAutocomplete-inputRoot .MuiChip-filled {
  height: 24px;
}

/* set width as 0px for first div above dsf-custom-datepicker-open*/
.dsf-delistingProj-datepicker {
  height: 0px;
  padding-left: 0px;
}

.dsf-delistingProj-datepicker .dsf-custom-datepicker-parent-div {
  height: 0px;
}

.dsf-delistingProj-datepicker .dsf-txt-checkinnoflex {
  width: 128px;
}

.dsf-delistingProj-datepicker .MuiInputBase-root {
  max-width: unset;
  max-height: unset;
  height: 27px;
}

.dsf-delistingProj-startdatepicker {
  position: relative;
}

.dsf-delistingProj-startdatepicker .dsf-txt-checkinnoflex {
  position: absolute;
  left: 0px;
}

.dsf-delistingProj-startdatepicker .dsf-checkinnoflex-date-edit-img {
  position: absolute;
  left: 144px;
  top: 5px;
}

.dsf-delistProj-autocomplete .input-container {
  max-height: unset;
  max-width: unset;
  background-color: transparent !important;
  border: 1px solid #797775;
  height: 27px;
  color: #edf1f3 !important;
}

.dsf-delistProj-autocomplete .input-container input {
  border: none;
  width: 450px;
  /* padding-right: 30px; */
  text-align: right;
}

.dsf-delistProj-autocomplete .input-container .MuiInputBase-root {
  max-width: 414px;
}

.dsf-delistProj-autocomplete .custom-dropdown {
  position: relative;
}

.dsf-delistProj-autocomplete .options-container {
  position: absolute;
  top: 26px;
  background-color: #1e232f;
  border: 1px solid #797775;
  z-index: 2;
  min-width: 487px;
  min-height: 59px;
}

.dsf-delistProj-autocomplete .input-container input:focus {
  outline: none;
}

.dsf-delistProj-autocomplete .dsf-delistingProj-search .MuiInputBase-root {
  max-width: 414px;
  position: relative;
}

.dsf-delistProj-autocomplete .autocomplete-dropdown-icon {
  padding-right: 11px;
  padding-bottom: 4px;
}

.dsf-delistProj-autocomplete .dropdownDisable {
  height: 20px;
  padding-right: 3px;
  opacity: 0.4;
}

.dsf-delistProj-autocomplete .dropdownEnable {
  height: 13px;
  padding-left: 13px;
}

.dsf-delistProj-autocomplete .dropdownClose {
  padding-bottom: unset;
  padding-right: unset;
  padding-left: 13px;
}

.dsf-delistProj-autocomplete .dsf-delistingProj-search .MuiInputBase-input {
  position: absolute;
  top: 5px;
  max-width: unset;
  width: 414px !important;
  border: unset;
  padding: 0px;
}

.delistProj-category-dropdown .custom-select .Mui-disabled {
  color: #0d1116 !important;
  height: 20px;
}

.dsf-delistProj-autocomplete
  .dsf-delistingProj-search
  .dsf-delistingProj-search-icon {
  padding-left: 10px;
  padding-top: 3px;
  padding-right: 10px;
}

.dsf-delistProj-autocomplete
  .options-container
  .options-list
  .MuiCheckbox-root {
  color: rgb(237, 241, 243);
}

.dsf-delistProj-classification-dropdown-popper .MuiPaper-root,
.dsf-delistProj-status-dropdown-popper .MuiPaper-root {
  max-height: 175px;
}

.dsf-delistProj-phase-dropdown-popper .MuiPaper-root {
  max-height: 138px;
}

.dsf-delistingProj-footer-btn-div {
  display: flex;
  justify-content: space-between;
  padding-right: 3px;
}

.dsf-delistingProj-footer-btn-div .bw-footer-cancle-btn {
  margin-right: 10px;
}

.dsf-delistingProj-footer-label-div {
  padding-left: 12px;
}

.dsf-delistingProj-brand-list .bwi-autocomplete-select-all {
  margin-left: 10px !important;
  opacity: unset !important;
}
