.dsf-uniqueRPM-row {
  padding-bottom: 10px;
}
.dsf-uniqueRPM-row :nth-child(1) {
  margin-left: 0px;
  display: flex;
  align-items: center;
}
.checkboxDiv {
  margin-left: 14px !important;
}

.dsf-uniqueRPM-mail-row2 {
  padding-bottom: 18px;
}

.dsf-uniqueRPM-row-mail-div div {
  padding-bottom: 10px;
  margin-left: 0px;
  display: flex;
  align-items: center;
}

.bw-uniqueRPM-span {
  white-space: nowrap !important;
  text-align: left !important;
}

.dsf-col-textBox-div1 {
  display: flex;
  flex: 30%;
  justify-content: left;
}

.dsf-col-textBox-div2 {
  display: flex;
  flex: 70%;
  justify-content: left;
}

.dsf-col-textBox-div1 input,
.dsf-col-textBox-div2 input {
  background-color: transparent !important;
  border: 1px solid #797775;
  height: 24px;
  color: #edf1f3 !important;
}

.dsf-col-textBox-div1 input {
  width: 192px;
}

.dsf-col-textBox-div2 input {
  width: 600px;
}

.dsf-col-textBox-div1 label,
.dsf-col-textBox-div2 label {
  font-family: "Unilever Shilling";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 11px;
  color: #8a8d8c;
}

.dsf-uniqueRPM-mail-row1 {
  margin-top: 10px !important;
}

.dsf-uniqueRPM-mail-row2 {
  margin-bottom: 30px !important;
}

.dsf-uniqueRPM-mail-empty {
  padding: 15px;
}

.dsf-RPM-textField:focus {
  background-color: #353535 !important;
  border: 1px solid #797775 !important;
  box-shadow: none !important;
  border-radius: 0%;
}

.bw-mdmOps-footer-btn {
  width: 95px !important;
  height: 24px !important;
  padding: 1px 8px 2px 8px !important;
}

.bw-modal-footer button.bw-mdmOps-footer-btn:disabled {
  color: #a0a0a0;
}

/* .dsf-dialog-mdmOps {
  max-width: 1050px !important;
  width: 1050px !important;
}

.dsf-dialog-mdmOps .modal-header {
  padding-left: 32px !important;
}

.dsf-dialog-mdmOps .modal-body {
  padding-left: 25px;
}

.dsf-dialog-mdmOps .bw-mdmOps-checkbox {
  padding-right: 10px !important;
} */

.bw-modal-cg-piuser-body {
  padding-left: 4px !important;
}

.dsf-dialog-mdmOps .scroll-view-div {
  min-height: 160px;
  padding-left: 25px !important;
  padding-top: 15px;
}

.dsf-dialog-mdmOps .dsf-uniqueRPM-row{
  width:100%;
}
