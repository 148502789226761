.bwi-display-flex {
  display: flex;
}

.bwi-display-flex-start {
  display: flex;
  justify-content: flex-start;
}

.bwi-display-flex-row {
  display: flex;
  flex-direction: row;
}

.bwi-display-flex-column {
  display: flex;
  flex-direction: column;
}

.bwi-display-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bwi-display-flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bwi-font-break-all {
  word-break: break-all;
}

.bwi-checkbox {
  color: #edf1f3 !important;
  padding-left: 0 !important;
  padding-right: 6px !important;
  padding-top: 5px !important;
  pointer-events: inherit;
}

.bwi-proj-landing-content {
  background-color: #0d1116;
  bottom: 0;
  color: #edf1f3;
  height: 100%;
  left: 0;
  max-height: 100%;
  max-width: 100%;
  position: fixed;
  right: 0;
  overflow-y: scroll;
}

/* .bwi-proj-landing-content .dsf-header-div .dsf-header {
  background: #1A0B32;
} */

.bwi-proj-landing-body {
  background-color: #1a0b32;
  border-top: 0.25px solid #c7c7c7;
  /* border-top: 1px solid #797775; */
  height: 34vh;
  padding: 10px 36px 10px 64px;
}

.bwi-proj-landing-background {
  background-color: #0d1116;
  height: 65vh;
  padding-top: 5px;
}

.bwi-landing-row-header {
  display: flex;
  padding: 4px 0px;
  padding-right: 20px;
}

.bwi-landing-header-info {
  padding-left: 5px !important;
}

.bwi-proj-info-tooltip .dsf-dashbaord-project-desc-span {
  width: unset !important;
}

.bwi-landing-back-text {
  color: #c7c7c7;
  font-weight: 400;
  font-size: 12px;
}

.bwi-proj-status-label {
  font-weight: 400;
  font-size: 12px;
  color: #797775 !important;
}

.bwi-landing-delete-info {
  display: flex;
  justify-content: flex-end;
}

.bwi-landing-delete-icon {
  padding-bottom: 3px;
  cursor: pointer;
}

.bwi-landing-delete-info span,
.bwi-landing-status-info span {
  color: #edf1f3;
}

.bwi-landing-row-info {
  min-height: 58%;
  padding: 20px 0px 15px 25px;
}

.bwi-landing-proj-info {
  max-height: 105px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  padding-left: 0px;
}

.bwi-proj-div {
  display: flex;
  padding-bottom: 5px;
}

.bwi-proj-name-header {
  color: #c7c7c7;
  line-height: 30px;
}

.bwi-proj-name-col {
  padding-right: 0px;
  text-align: start;
  padding-left: 0px;
}

.bwi-flex2 {
  /* justify-content: center; */
  min-width: 83%;
  max-width: 83%;
  display: flex;
}

.bwi-proj-info-icon-col {
  padding-top: 3px;
  padding-left: 3px;
}

.bwi-loader-div {
  width: 112%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.bwi-proj-desc-text {
  color: #8a8d8c;
  line-height: 16.2px;
  text-align: left;
}

.bwi-landing-cursor-pointer {
  cursor: pointer;
}

.bwi-landing-status-info {
  display: flex;
  align-self: flex-start;
  max-height: 105px;
  justify-content: flex-start;
  overflow: unset;
}

.bwi-proj-status-div {
  padding-left: 24px;
}

.bwi-landing-row-workflow {
  background-color: #353535;
  position: absolute;
  max-height: 618px;
  min-height: 220px;
  width: 94%;
}

.bwi-landing-row-workflow-long {
  background-color: #1e232f;
  position: absolute;
  max-height: 510px;
  min-height: 220px;
  width: 94%;
}

.bwi-landing-row-workflow-E2E {
  background-color: #1e232f;
  position: absolute;
  min-height: 875px;
  width: 94%;
}

.bwi-modal {
  padding: 10px;
}

.bwi-modal-sub-title {
  color: #cfcfcf;
}

.bwi-landing-modal-header {
  padding: 10px 0px 0px 25px;
  text-align: start;
  width: 99%;
  height: 85px;
}

.bwi-modal-title {
  opacity: 0.8;
  color: #d7e4ff;
}

.bwi-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.bwi-submit-button,
.bwi-reset-button,
.bwi-unique-rpm-assign-btn,
.bwi-footer-button-secondary {
  align-items: center;
  color: #292929;
  display: flex;
  font-family: Unilever Shilling;
  font-size: 12px;
  font-weight: 600;
  height: 24px;
  justify-content: center;
  /* padding: 5px 25px; */
  width: 108px;
  margin: 0px 5px;
  border-radius: 0;
}

.bwi-submit-button,
.bwi-submit-button:hover,
.bwi-submit-button:active,
.bwi-submit-button:disabled,
.bwi-submit-button:focus {
  background: #00d6c8;
  color: #292929;
}

.bwi-reset-button {
  background: #c7c7c7;
}

.bwi-footer-button-movement {
  align-items: center;
  background: #353535;
  color: #c7c7c7;
  display: flex;
  font-family: Unilever Shilling;
  font-size: 12px;
  font-weight: 600;
  height: 24px;
  justify-content: center;
  /* padding: 5px 25px; */
  width: 108px;
  margin: 0px 5px;
  border-radius: 0;
}

.bwi-footer-button-movement:hover,
.bwi-footer-button-movement:active,
.bwi-footer-button-movement:focus,
.bwi-footer-button-movement:disabled {
  background: #353535 !important;
  color: #c7c7c7 !important;
  box-shadow: none !important;
}

.bwi-footer-button-prev {
  padding-right: 15px;
}

.bwi-footer-button-next {
  padding-left: 18px;
}

.bwi-footer-button-secondary:hover,
.bwi-footer-button-secondary:active,
.bwi-footer-button-secondary:focus,
.bwi-footer-button-secondary:disabled,
.bwi-reset-button:hover,
.bwi-reset-button:active,
.bwi-reset-button:focus,
.bwi-reset-button:disabled,
.dsf-Inventory-upload-btn:disabled {
  background: #c7c7c7;
  color: #292929;
}

.bwi-landing-scenario-planner-btn:hover,
.bwi-landing-scenario-planner-btn:focus,
.bwi-landing-scenario-planner-btn:active,
.bwi-landing-scenario-planner-btn:disabled {
  background: #00d6c8;
  color: #292929;
}

.bwi-landing-scenario-planner-btn {
  background: #00d6c8;
  color: #353535;
  align-items: center;
  display: flex;
  font-family: Unilever Shilling;
  font-size: 12px;
  font-weight: 600;
  height: 24px;
  justify-content: center;
  width: 180px;
  margin-bottom: 10vh;
  border-radius: 0;
}

.bwi-landing-status-info .bwi-stepper-cg-waiting .bwi-stepper-icon-img {
  cursor: url("../../../assets/images/landingPage/disabledPointer.svg"), pointer;
  pointer-events: auto;
}

.bwi-proj-info-icon-img {
  opacity: 0.8;
}

.bwi-dashboard-project-list .bwi-project-phase-col,
.bwi-proj-list-status-head {
  margin: 0px 16px;
}

.bwi-project-list-head-col2 .bwi-proj-list-info-head {
  min-width: 52px;
}

.bwi-project-list-head-col1 {
  min-width: 25%;
}

.bw-warning-ok-button {
  background: #8fcaeb !important;
}
