.bwi-dialog-add-review-action {
  max-height: 70% !important;
  max-width: 1250px !important;
  min-width: 1250px !important;
}

.bwi-modal-add-review-action {
  background-color: #1e232f;
  color: #edf1f3;
  padding: 10px;
}

.bwi-modal-add-review-action-header {
  border: initial;
  text-align: left;
  padding-bottom: 5px;
}

.bwi-add-review-action-content {
  position: relative;
  min-height: 100px;
  max-height: 410px;
}

.bwi-add-review-action-data {
  min-height: 100px;
  max-height: 375px;
}

.bwi-add-review-action-table-row-data {
  max-height: 330px;
  overflow-y: auto;
  overflow-x: hidden;
}

.bwi-add-review-action-table {
  text-align: left;
  padding: 10px;
  line-height: 2;
  /* opacity: 0.8; */
}

.bwi-add-review-action-content .loading-spinner {
  margin-top: 30px;
  left: 50%;
}

.bwi-add-review-action-content .no-data-found {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.bwi-add-review-action-table-header-row,
.bwi-add-review-action-table-row {
  padding: 0px 15px;
  padding-bottom: 6px;
  min-height: 28px;
}

.bwi-add-review-action-header-col {
  font-size: 12px;
  font-weight: 600;
  padding: 0px 5px;
  /* opacity: 0.8; */
}

.bwi-add-review-action-table-col {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  word-break: break-all;
  padding: 0px 5px;
  /* opacity: 0.8; */
}

.bwi-add-review-action-table-col-date {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  word-break: break-all;
  padding: 0px 5px;
  padding-left: 38px;
}

.bwi-add-review-action-table .dsf-custom-datepicker-parent-div {
  top: 40%;
  left: 59%;
}

.bwi-add-review-action-input {
  background-color: transparent !important;
  border: 1px solid #797775;
  color: #edf1f3 !important;
  width: 280px;
}

.bwi-action-mail-status-img {
  margin-bottom: 3px;
  margin-right: 4px;
}

.bwi-add-review-action-select-col {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
}

.bwi-add-review-action-button {
  position: absolute;
  bottom: 0;
  right: 0;
}

.bwi-add-review-action-Assign-btn-div {
  min-height: 35px;
}

.bwi-add-review-action-button {
  display: flex;
  flex-direction: row;
  padding-bottom: 5px;
  padding-right: 5px;
  /* background: #8FCAEB; */
}

.bwi-add-review-action-button .bw-delist-footer-btn {
  margin: 0px 5px 5px 5px !important;
  width: 140px !important;
  background: #8fcaeb;
  color: #292929;
}

.bwi-modal-add-review-action .bw-delist-footer-btn:disabled {
  color: #292929 !important;
  background: #8fcaeb !important;
}

.bwi-modal-add-review-action .bw-delist-footer-btn {
  background: #8fcaeb;
  color: #292929 !important;
}

.bwi-pointer-events-none {
  pointer-events: none;
  cursor: none;
}

.bwi-action-add-img-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.dsf-scenario-review-mail-dialog {
  width: 615px !important;
}

.dsf-scenario-review-mail-dialog .dsf-delistingProjDel-okmodal {
  height: auto;
}

.dsf-scenario-review-mail-dialog .scenario-action-div {
  margin-right: 5px !important;
}

.dsf-scenario-review-mail-dialog .bw-messagebox-header-title {
  line-height: 1.2;
}
