.dsf-card-main-div {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 25%;
  height: 133px;
  margin-right: 18px;
  top: 15px;
}
.dsf-card-main-div-color-PI {
  background: linear-gradient(180deg, #0d0f11 0%, #1e232f 100%);
  border-radius: 10px;
  border: 1px solid #1e232e;
  padding: 20px 0px 20px 8px;
}
.dsf-card-main-div-color {
  background: #1f2123;
}
.dsf-card-header {
  display: flex;
  flex: 0.5;
  flex-direction: row;
  position: relative;
  bottom: 10px;
}
.dsf-card-title {
  position: absolute;
  top: 8px;
  margin-left: 15px;
  font-family: "Unilever Shilling";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  align-items: center;
  color: #edf1f3;
}

.dsf-card-icon {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 8.34%;
  top: 25.5%;
  width: 16.67px;
  height: 16.67px;
}

.dsf-card-value {
  flex: 0.5;
  display: flex;
  top: -8px;
  bottom: 10px;
  position: relative;
}

.dsf-card-value-span {
  position: absolute;
  top: 8px;
  margin-left: 15px;
  font-family: "Unilever Shilling";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
}
