.custom-select {
  width: 108px;
  height: 24px;
  background: transparent;
  border: 1px solid #797775;
  border-radius: unset !important;
}

.custom-select .MuiInputBase-input {
  font: unset;
  padding: unset !important;
  text-align: center;
  font-family: "Unilever Shilling";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #edf1f3;
}

.MuiPopper-root {
  top: 50%;
  left: 50%;
}

.custom-select .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

.bw-innoflex-popper-class .MuiPopover-paper {
  background: #1e232f;
  border: 0.883721px solid #797775;
  border-radius: 0px;
}

.bw-innoflex-popper-class .MuiPopover-paper ul {
  padding: unset;
}

.bw-innoflex-popper-class .MuiPopover-paper li {
  background-color: unset;
  font: unset;
  font-family: "Unilever Shilling";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #edf1f3;
}

.bw-innoflex-popper-class .MuiPopover-paper li:hover,
.bw-innoflex-popper-class .MuiPopover-paper li:focus {
  background-color: #1a0b32;
}

.custom-select .Mui-disabled {
  color: #edf1f3 !important;
  -webkit-text-fill-color: #edf1f3 !important;
  opacity: 0.4 !important;
}

.bwi-selectable-popper .MuiPopover-paper {
  background: unset !important;
  opacity: unset !important;
  box-shadow: none !important;
}

.bwi-selectable-popper .MuiPopover-paper .bwi-selectable-li-items {
  background-color: #353535 !important;
  border-right: 1px solid #797775;
  border-left: 1px solid #797775;
  font-family: "Unilever Shilling";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #edf1f3;
}

.bwi-selectable-popper .MuiPopover-paper .bwi-selectable-li-items:hover {
  background-color: #797775 !important;
}

.bwi-selectable-popper .MuiPopover-paper .bwi-selectable-li-items-first {
  border-top: 1px solid #797775;
}

.bwi-selectable-popper .MuiPopover-paper .bwi-selectable-add-btn {
  border-bottom: none;
  border-top: 1px solid #797775;
  background: none !important;
}

.bwi-selectable-popper .MuiPopover-paper .bwi-selectable-add-btn button:focus {
  outline: none !important;
}

.bwi-add-review-action-add-input {
  background-color: #353535 !important;
  border: 1px solid #797775;
  color: #edf1f3 !important;
  width: 256px;
  margin-right: 6px;
}

.bwi-action-add-minus-img {
  padding-bottom: 4px;
}

.dsf-selectable-add-plus-img {
  width: 12px;
  height: 12px;
  margin-bottom: 3px;
  margin-right: 0px;
  margin-left: 2px;
}

/* customOptionsDropdown style start */

.bwi-options-container {
  position: absolute;
  top: 26px;
  background-color: #1e232f;
  border: 1px solid #797775;
  z-index: 2;
}

.bwi-options-list {
  text-align: left;
}

.bwi-options-row {
  align-content: center;
  padding: 5px;
  min-height: 24px;
  cursor: pointer;
  font: unset;
  font-family: "Unilever Shilling";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #edf1f3;
}

.bwi-options-row:hover {
  background-color: #340d71;
}

.bwi-custom-options-dropdown {
  font-size: 12px;
  font-weight: 400;
}

.bwi-options-dropdown-input,
.bwi-dropdown-icon-disable,
.bwi-options-list {
  opacity: 0.8;
}

.bwi-options-dropdown-input,
.bwi-dropdown-icon {
  margin-left: 5px;
}

.bwi-dropdown-icon-enable {
  padding-bottom: 2px;
  cursor: pointer;
}

.bwi-dropdown-icon-close {
  cursor: pointer;
}
/* customOptionsDropdown style end */
