
.justified-snackbar {
    display: inline-flex;    
}

.notification-snackbar{
    z-index: 0 !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation6.MuiSnackbarContent-root {
    height: 40px;
    padding-top: 4px;
    width: 262px;
}