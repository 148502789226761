.modalHeading {
    font-family: 'Unilever Shilling';
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #EDF1F3;
}

.modalSubHeading {
    font-family: 'Unilever Shilling';
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #CFCFCF;

}

.tracker-modal {
    background: #353535;
}

#tracker-table {
    margin: auto;
}

.dsf-tracker-btn {
    align-items: center !important;
    display: flex !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    border-radius: 0% !important;
    font-family: "Unilever Shilling" !important;
    padding: 6px 12px;
    opacity: 1 !important;
    float: right;
}

.tracker-button {
    background-color: #c7c7c7 !important;
    border-color: #c7c7c7 !important;
    color: #292929 !important;
    font-family: Unilever Shilling;
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: center;
}

.tracker-button:hover {
    align-items: center;
    display: flex;
    background-color: #d4e4fc !important;
    border-color: #d4e4fc !important;
    border-radius: 0%;
    font-size: 12px;
    color: #292929 !important;
    font-weight: bold;
    cursor: auto !important;
}

.bw-modal-uniqueRPM-body .ag-checkbox-input {
    cursor: pointer;
}

.ag-checkbox-input-wrapper.ag-checked::after{
    color: white !important;
}

/* .snapshot-modal-content {
    background-color: #353535;
    color: #EDF1F3;
    max-width: 100%;
    max-height: 100%;
    position: fixed;
    padding: 10px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}

.snapshot-modal-content .dsf-header {
    height: 38px !important;
}

.bw-snapshot-chart {
    height: 55vh;
    z-index: 1;
    background-color: #1F2123;

}

.bw-snapshot-actionTracker {
    height: 40vh;
    z-index: 2;
    background-color: #353535;
    padding-top: 5px;
}

.bw-snapshot-chart .col {
    padding: 8px;
}

.chart-info-div {
    justify-content: flex-end;
    /* margin-right: 90px; 

}*/

/*.chart-info-div div {
    display: flex;
    flex-direction: column;
    margin-right: 24px;
    z-index: 2;
}

.snapshot-chart-parent-row {
    margin-left: 10px !important;
}

.bw-snapshot-close-img {
    left: 90% !important;
    position: absolute !important;
}

.combo_chart_div {
    /* width: 160vh;
    z-index: 1;
    margin-top: -48px;
}

.snapshot-details-parent-div {
    text-align: left;
    position: relative;
    top: 8%;
}

.detail-row {
    align-items: center;
    margin-top: -17px;
    height: 20px;
}

.snapshot-details-col .row {
    align-items: center !important;
}

.snapshot-details-col {
    display: flex;
    align-content: space-around;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.bw-snapshot-actionTracker-Heading {
    float: "left";
} */


.dsf-dialog-InventoryCM {
    max-width: 996px !important;
    max-height: 560px !important;
    width: 996px !important;
    height: 560px !important;
}

.dsf-col-textBox-div2 {
    padding: 0;
}

.dsf-col-textBox-div2 input,
.dsf-col-textBox-div1 input {
    font-size: 12px;
    font-weight: 400;
}

.dsf-dialog-InventoryCM .bw-modal-uniqueRPM-body .dsf-uniqueRPM-mail-row2 .dsf-col-textBox-div2 input {
    width: 899px;
}

.dsf-uniqueRPM-row {
    margin-left: -5px;
    margin-right: -5px;
    flex-wrap: inherit;
}

.dsf-uniqueRPM-row .form-check-input {
    position: relative;
    cursor: pointer !important;
    margin-top: 3px;
}

.dsf-uniqueRPM-row :nth-child(1) {
    align-items: start !important;
}

.dsf-dialog-InventoryCM .dsf-uniqueRPM-mail-row2 {
    margin-bottom: 20px !important;
}

.dsf-uniqueRPM-row .flex-fill {
    padding: 0 5px;
    align-items: flex-start !important;
    text-align: left;
    width: 10%;
}

.buttonWidth {
    width: 108px !important;
    white-space: nowrap;
}

.highlight-row {
    font-family: 'Unilever Shilling';
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    color: #EDF1F3;
}

.bw-ICM-text {
    text-align: left;
}

.bw-ICM-fileUpload-row {
    text-align: left;
    padding-top: 5px;
    padding-bottom: 20px;
}

.bw-ICM-fileUpload-row .dsf-delist-form-upload-browse {
    width: 1018px;
}