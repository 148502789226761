.bwi-dashbaord-summary-div {
  padding: 10px 30px;
  margin-left: 24px;
  display: flex;
  flex-direction: column;
}

.bwi-pending-task-list {
  display: flex;
  flex: 1 1;
  width: 98%;
  margin-top: 10px;
}

.bwi-pending-task {
  flex: 1 1;
  min-width: 0;
  min-height: 106px;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  margin: 0 10px;
  border-radius: 5px;
  background-color: #1e232f;
}

.bwi-pending-task-carousel-list .bwi-pending-task {
  max-height: 106px;
}

.bwi-pending-task:first-child {
  margin-left: 0 !important;
}

.bwi-pending-task:last-child {
  margin-right: 0 !important;
}

.bwi-project-info {
  display: flex;
  flex-direction: column;
  padding: 10px 20px 10px 20px;
  min-width: 25%;
  height: auto;
  position: relative;
  flex: 100% 1;
}

.bwi-BW-info {
  display: flex;
  flex: 25% 1;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  /* padding-top: 20px; */
  width: 25%;
  height: auto;
}

.bwi-BW-info-btn {
  width: 84px;
  height: 24px;
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  border-radius: 0%;
  font-family: "Unilever Shilling";
  padding: 6px 12px;
  opacity: 1;
  text-wrap: nowrap;
  justify-content: center;
  background-color: #00d6c8 !important;
  border-color: #00d6c8 !important;
  color: #292929 !important;
}

.bwi-dashbaord-project-title-span {
  color: #d7e4ff;
  white-space: pre;
}

.bwi-dashbaord-project-desc-span {
  color: #8b94a8;
  width: 95%;
  height: 50%;
  padding-top: 6px;
  font-size: 12px;
  font-weight: 400;
}
