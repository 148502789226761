.dsf-dialog-messagebox {
  max-width: 1044px !important;
  max-height: 470px !important;
  width: 300px !important;
  height: 150px !important;
}

.dsf-modal-messagebox {
  background-color: #1e232f;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  color: #edf1f3;
  height: 150px;
}

.bw-modal-messagebox-header.modal-header {
  border: unset;
  text-align: left;
}

.bw-modal-messagebox-header.modal-header img {
  cursor: pointer;
}

.bw-messagebox-header-title {
  padding-right: 15%;
}

img.dsf-close-messagebox-img {
  right: 10px;
  height: 12px;
  width: 12px;
  position: relative;
  top: 7px;
}

.bw-modal-messagebox-msg {
  font-family: "Unilever Shilling";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16.73px;
  color: #ffffff;
}

.dsf-delist-form-upload-section {
  display: inline-flex;
}

.dsf-delist-form-upload-browse {
  width: 90%;
}

.fv {
  width: 150px;
  padding-left: 30px;
}

.dsf-delist-form-browse-button {
  height: 31px;
  width: 82px;
  background-color: #c7c7c7;
  color: #292929;
  border: 1px solid #797775;
  font-family: "Unilever Shilling";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  padding: 3.34944px 8.03865px 4.68921px;
  gap: 6.7px;
  border-radius: 0px;
}

.dsf-delist-form-browse-button:hover {
  height: 31px;
  width: 82px;
  background-color: #c7c7c7;
  color: #292929;
  border: 1px solid #797775;
  font-family: "Unilever Shilling";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  padding: 3.34944px 8.03865px 4.68921px;
  gap: 6.7px;
  border-radius: 0px;
}

.dsf-delist-form-browse-button:disabled {
  height: 31px;
  width: 82px;
  background-color: #c7c7c7;
  color: #a0a0a0;
  border: 1px solid #797775;
  font-family: "Unilever Shilling";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  padding: 3.34944px 8.03865px 4.68921px;
  gap: 6.7px;
  border-radius: 0px;
}

.dsf-delist-form-upload-btn {
  padding-left: 30px;
  height: 24px;
}

.dsf-Inventory-upload-btn {
  height: 24px !important;
  width: 108px !important;
}

.dsf-delist-form-upload-btn-css {
  height: 24px;
  width: 82px;
  background-color: #8fcaeb;
  color: #292929;
  border: 1px solid #797775;
  font-family: "Unilever Shilling";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  padding: 3.34944px 8.03865px 4.68921px;
  gap: 6.7px;
  border-radius: 0px;
  margin-bottom: 3px;
}

.dsf-delist-form-upload-btn-css:hover,
.dsf-delist-form-upload-btn-css:active,
.dsf-delist-form-upload-btn-css:focus {
  height: 24px;
  width: 82px;
  background-color: #8fcaeb;
  color: #292929;
  border: 1px solid #797775;
  font-family: "Unilever Shilling";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  padding: 3.34944px 8.03865px 4.68921px;
  gap: 6.7px;
  border-radius: 0px;
}

.dsf-delist-form-upload-btn-css:disabled {
  height: 24px;
  width: 82px;
  background-color: #8fcaeb;
  color: #292929;
  border: 1px solid #797775;
  font-family: "Unilever Shilling";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  padding: 3.34944px 8.03865px 4.68921px;
  gap: 6.7px;
  border-radius: 0px;
}

input.dsf-delist-form-upload-type.form-control {
  background: #1e232f;
  color: #edf1f3;
  border: 1px solid #797775;
  box-sizing: border-box;
  font-family: "Unilever Shilling";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  border-radius: 0px;
}

input.dsf-delist-form-upload-type.form-control:disabled {
  background: #353535;
  color: #797775;
  border: 1px solid #797775;
  box-sizing: border-box;
  font-family: "Unilever Shilling";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  border-radius: 0px;
}
