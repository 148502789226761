.dsf-header-div {
  display: block;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .dsf-dashbaord-summary-div {
    padding: 30px;
    margin-left: 24px;
    display: flex;
    flex-direction: row;
    flex: 1;
  }

  .widgets-div {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.dsf-dashbaord-title-span {
  margin-right: 7px;
}

.dsf-dashbaord-card-div {
  display: flex;
  flex-direction: row;
  width: 99%;
}

.dsf-dashbaord-project-summary-div {
  padding: 30px;
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.dsf-sort-icon {
  margin-top: 14px;
  margin-left: 6px;
  /* margin-bottom: 3px; */
  width: 18px;
  height: 12px;
  cursor: pointer;
}

.flex1 {
  display: flex;
  flex: 25%;
}

.flex20 {
  display: flex;
  flex: 20%;
}

.flex53 {
  flex: 53% !important;
}

.flex2 {
  display: flex;
  flex: 50%;
  /* justify-content: center; */
}

.flex3 {
  display: flex;
  flex: 25%;
  justify-content: center;
}

.project-info {
  flex-direction: column;
  padding: 15px 20px 20px 20px;
  min-width: 25%;
  height: auto;
  /* padding-top: 6px; */
}

.workflow-info {
  align-items: center;
  /* flex-wrap: wrap; */
  align-content: center;
  width: 50%;
  height: auto;
}

.BW-info {
  flex-wrap: wrap;
  align-content: center;
  width: 25%;
  height: auto;
}

.dsf-header-text {
  /* display: block; */
  font-size: 10px;
  color: #edf1f3;
}

.dsf-dashbaord-project-title-span {
  color: #d7e4ff;
  /* width: 80%; */
  /* height: 50%; */
  white-space: pre;
  /* display: inline-block; */
  /* overflow-wrap: break-word; */
}

.scneaioBW-span {
  color: #00a889;
}

.workflow-col-div {
  text-align: center;
  width: 52px;
  /* height: 80px; */
  /* flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0; */
}

.workflow-col-header-div {
  display: grid;
  text-align: center;
  width: 52px;
  height: 28px;
}

.header-div::before {
  content: "";
  height: 28px;
  width: 0.5px;
  margin-top: 2px;
  position: absolute;
  background-color: #626262;
}
.dsf-col-14 .header-div::before {
  content: "";
  height: 28px;
  width: 0.5px;
  margin-top: 2px;
  margin-left: -13px;
  position: absolute;
  background-color: #626262;
}
.header-div-end::after {
  content: "";
  height: 28px;
  margin-top: 2px;
  width: 0.5px;
  margin-left: 50px;
  position: absolute;
  background-color: #626262;
}

.workflow-div {
  height: 100%;
  display: flex;
  justify-content: center;
}

.workflow-div-position {
  position: absolute;
  top: -22px;
  right: 10px;
}

.project-details-list:hover {
  background-color: #333638;
}

.dsf-snapshot-btn {
  width: 84px !important;
  height: 24px !important;
  align-items: center !important;
  display: flex !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  border-radius: 0% !important;
  font-family: "Unilever Shilling" !important;
  padding: 6px 12px;
  opacity: 1 !important;
}
.dsf-adjust-btn {
  width: 84px !important;
  height: 24px !important;
  align-items: center !important;
  display: flex !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  border-radius: 0% !important;
  font-family: "Unilever Shilling" !important;
  padding: 6px 12px;
  opacity: 1 !important;
  text-wrap: nowrap;
  justify-content: center;
}

.dsf-snapshot-btn-disable {
  background-color: #c7c7c7 !important;
  border-color: #c7c7c7 !important;
  color: #a0a0a0 !important;
}

.dropdown-menu {
  min-width: 84px !important;
  background-color: #b7d3ff !important;
  color: #292929 !important;
  border-radius: 0 !important;
}

.dsf-snapshot-dropdown-btn-enable {
  width: 84px !important;
  background-color: #333638;
}

.dsf-snapshot-dropdown-btn-disable button {
  background-color: #c7c7c7 !important;
  border-color: #c7c7c7 !important;
  color: #a0a0a0 !important;
  width: 84px;
  height: 24px;
  padding: 0px 12px 6px 12px;
  border-radius: 0px;
}

.dsf-snapshot-dropdown-btn-enable button {
  background-color: #b7d3ff !important;
  border-color: #b7d3ff !important;
  color: #292929 !important;
  width: 84px;
  height: 24px;
  padding: 0px 12px 6px 12px;
  border-radius: 0px;
}

.dsf-snapshot-dropdown {
  background-color: #b7d3ff !important;
  border-color: #b7d3ff !important;
  color: #292929 !important;
  width: 84px;
  height: 24px;
  padding: 0px 12px 6px 12px;
  border-radius: 0px;
}

.dsf-snapshot-btn-enable {
  background-color: #b7d3ff !important;
  border-color: #b7d3ff !important;
  color: #292929 !important;
}

.PrivateSwipeArea-root {
  z-index: unset !important;
}

.dsf-snapshot-btn-enable:hover {
  width: 84px;
  height: 24px;
  align-items: center;
  display: flex;
  background-color: #d4e4fc !important;
  border-color: #d4e4fc !important;
  border-radius: 0%;
  font-size: 12px;
  color: #292929 !important;
  font-weight: bold;
}

.stateComplete:hover :nth-child(1),
.stateIncomplete:hover :nth-child(1),
.stateAttention:hover :nth-child(1),
.stateWaiting:hover :nth-child(1),
.stateNothing:hover :nth-child(1) {
  display: none;
}

.info-div:hover {
  background: url("../../assets/images/State=Nothing_Hover.svg") no-repeat;
  cursor: pointer;
}

.stateNothing:hover {
  background: url("../../assets/images/State=Nothing_Hover.svg") no-repeat;
}

.stateComplete:hover {
  cursor: pointer;
  background: url("../../assets/images/State=Complete_Hover.svg") no-repeat;
}

.stateIncomplete:hover {
  background: url("../../assets/images/State=InComplete_Hover.svg") no-repeat;
}

.stateAttention:hover {
  cursor: pointer;
  background: url("../../assets/images/State=Attention_Hover.svg") no-repeat;
}

.stateWaiting:hover {
  cursor: pointer;
  background: url("../../assets/images/State=Waiting_Hover.svg") no-repeat;
}

.BW-info-child-div {
  text-align: center;
  width: 75px;
  height: 28px;
  /* flex-basis: 33%; */
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .project-details-head {
    display: flex;
    flex: 1;
    /* width: 1126px; */
    width: 98%;
    align-items: center;
  }

  .project-details-list {
    display: flex;
    margin-top: 11px;
    height: 80px;
    /* width: 1126px; */
    width: 98%;
    border-radius: 0px;
    background-color: #1f2123;
    position: relative;
  }
}
.dsf-col-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dsf-col-12 {
  margin-right: 15px;
  display: flex;
  justify-content: right;
  width: 84px;
  height: 24px;
}

.dsf-col-13,
.dsf-col-14 {
  display: flex;
  justify-content: space-evenly;
}

.dsf-workflow-icon {
  width: 20px;
  height: 20px;
  align-self: center;
}

.dsf-workflow-icon-new {
  width: 16px;
  height: 16px;
  align-self: center;
}

.dsf-status-icon {
  width: 20px;
  height: 20px;
}

.dsf-status-validate-icon {
  width: 13.94px;
  height: 17.42px;
}

.dsf-status-execute-icon {
  width: 16.24px;
  height: 12.16px;
}

/*Style for modalProjectInfo showModal*/

.modal-dialog.modal-dialog-centered {
  max-width: 390px;
  max-height: 365px;
}

.modal-content.dsf-modal-project-info {
  max-height: 537px !important;
  height: fit-content !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-content.dsf-modal-bgcolor-PI {
  background-color: #1e232f;
  color: #d7e4ff;
}

.modal-content.dsf-modal-bgcolor {
  background-color: #353535;
  color: #edf1f3;
}

.dsf-size16.modal-title.h4 {
  font-weight: 700;
}

.bw-modal-header.modal-header {
  border: unset;
}

img.dsf-close-img {
  right: 10px;
  height: 12px;
  width: 12px;
  position: relative;
  top: 7px;
  cursor: pointer;
}

.bw-proj-label {
  color: #8a8d8c;
  line-height: 2;
}

.bw-proj-value {
  color: #edf1f3;
  word-break: break-word;
}

.bw-modal-footer.modal-footer {
  border: unset;
  padding-top: 0;
  padding-bottom: 15px;
}

/*Style for checkInnoflex showModal*/
.dsf-dialog-checkinnoflex {
  max-width: 820px !important;
  /* max-height: 537px !important; */
}

.dsf-modal-checkinnoflex {
  background-color: #353535;
  color: #edf1f3;
  max-width: 1171px !important;
  max-height: 537px !important;
  /* height:  437px !important; */
}
.dsf-modal-checkinnoflex-scroll {
  overflow-y: auto;
}

.bw-modal-checkinnoflex-header.modal-header {
  border: unset;
  text-align: left;
}

.bw-checkinnoflex-header-title {
  padding-right: 15%;
  height: 55px;
}

.dsf-modal-err-msg {
  color: #ec6157;
}

img.dsf-close-checkinnoflex-img {
  right: 10px;
  height: 12px;
  width: 12px;
  position: relative;
  top: 9px;
  left: 8px;
  cursor: pointer;
}

.bw-modal-checkinnoflex-body {
  padding-bottom: 0px;
}

.bw-checkinnoflex-row {
  padding-bottom: 10px;
}

.MuiInputBase-root {
  max-width: 108px;
  max-height: 24px;
}

.dsf-txt-checkinnoflex input {
  -webkit-text-fill-color: #edf1f3 !important;
  font-family: "Unilever Shilling";
  font-style: normal;
  font-weight: 400;
  height: 21px;
  font-size: 12px;
  padding: 1.5px 1px !important;
  text-align: center;
}

.dsf-txt-checkinnoflex div {
  border-radius: unset !important;
  border: solid #797775 1px !important;
}

.dsf-err-txt input {
  -webkit-text-fill-color: #ff544f !important;
}

.MuiTextField-root {
  padding: unset;
  text-align: center;
  width: 108px;
  height: 24px;
}

img.dsf-checkinnoflex-date-edit-img {
  height: 10px;
  width: 10px;
  position: relative;
  bottom: 3px;
  left: 10px;
  cursor: pointer;
}

.bw-checkinnoflex-footer-btn {
  width: 108px;
}

.bw-modal-footer button.bw-checkinnoflex-footer-btn:disabled {
  color: #a0a0a0;
}

.dsf-checkinnoflex-type .form-control {
  width: 108px;
  height: 24px;
  border-radius: unset;
  background: none;
  border: solid #797775 1px;
}

.dsf-checkinnoflex-type .form-control option {
  background: #353535;
  border: 0.883721px solid #797775;
  color: #edf1f3;
  font-family: "Unilever Shilling";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.dsf-checkinnoflex-type .form-control {
  color: #edf1f3;
  font-family: "Unilever Shilling";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding: unset;
  text-align: center;
}

.dsf-checkinnoflex-type .form-control:focus {
  background: none;
  color: #edf1f3;
  box-shadow: none;
}

/*.dsf-checkinnoflex-type .form-control option:visited{
    background: none;
}*/

.dsf-dialog-checkDelistsModal {
  max-width: 1080px !important;
  max-height: 449px !important;
  height: 1080px !important;
}

.dsf-modal-workflowModal {
  background-color: #1e232f;
  color: #edf1f3;
  max-width: inherit;
  max-height: inherit;
  padding: 8px;
}

.bw-modal-checkDelist-header.modal-header {
  border: unset;
  text-align: left;
}

.bw-checkDeslist-header-title-div {
  padding-right: 15%;
  display: contents;
}

.bw-checkDeslist-header-title-div div {
  display: flex;
  flex-direction: column;
}

.bw-checkDelists-row :nth-child(1) {
  margin-left: 10px;
  display: flex;
  align-items: center;
  word-break: break-word;
}

.bw-checkDelists-row {
  padding-bottom: 10px;
  /* justify-content: left; */
  flex-wrap: inherit;
  margin-right: -5px;
}

.bw-checkDelists-row div {
  /* display: flex;
  align-items: center;
  justify-content: flex-end !important; */
  width: 10%;
  text-align: left;
  align-items: flex-start !important;
  padding: 0 5px;
}

/* .bw-checkDelists-row{
  margin-left: 10px;
  padding-bottom: 10px;
  flex-wrap: inherit;
} */

.bw-modal-checkDelist-body {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  padding-bottom: 10px;
}

.test-tooltip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 18px;
}

.bw-checkDelists-span {
  /* justify-content: flex-start !important; */
  white-space: pre-wrap !important;
  text-align: left !important;
}

/* .delist-tooltip-header-div {
  display: flex;
  justify-content: flex-start;
} */

.dsf-delist-margin-div {
  margin-top: 50px !important;
}

.dsf-delist-footer-div {
  padding-top: 20px !important;
}

.bw-checkinnoflex-datepicker {
  height: 30px;
}

.picker-position .dsf-checkinnoflex-date-edit-img {
  bottom: 2px;
  left: 10px;
}

.picker-position .dsf-custom-datepicker-open {
  top: -64px;
  left: 234px;
}
.innoflex-header-div {
  display: flex;
  justify-content: space-evenly;
}

.bw-modal-footer button {
  border-radius: 0px;
}

.delist-tooltip-header-div img {
  margin-top: 6px;
}

.dsf-modal-project-info .bw-brand-value {
  word-break: break-all !important;
}

.dsf-modal-project-info .bw-country-value {
  word-break: break-all !important;
}

/* .dsf-dashbaord-project-desc-span {
  width: 100% !important;
} */

/* .dsf-dashbaord-project-title-span {
  width: 100% !important;
} */
.modal-sort-window,
.modal-sort-window-new {
  width: 250px;
  height: 230px;
  background-color: #1e232f;
  border: 0.5px solid #797775;
  position: absolute;
  z-index: 2;
  padding: 1rem;
  align-items: flex-start;
}

.modal-sort-window {
  margin-left: 19rem;
  margin-top: 3rem;
}

.modal-sort-window-new {
  margin-left: 6rem;
  margin-top: 4rem;
}

.modal-sort-window ul,
.modal-sort-window-new ul {
  margin-top: 5px;
}

.modal-sort-window li,
.modal-sort-window-new li {
  text-align: initial;
  list-style: none;
  margin-left: -40px;
  margin-top: 2px;
  cursor: pointer;
}
.modal-sort-window-new ul li{
  position: relative;
}
.modal-sort-window-new ul li:last-child img,
.modal-sort-window-new ul li:nth-last-child(2) img{
  right: 8rem;
  
}
.modal-sort-window-new ul li:nth-last-child(2) img{
  top: 30px;
}
.modal-sort-window-new ul li:nth-last-child(3) img,
.modal-sort-window-new ul li:nth-last-child(4) img{
  right: 4rem;
}
.modal-sort-window-new ul li:nth-last-child(2){
  margin-top: 10px;
}
.modal-sort-window-new ul li img{
  position: absolute;
  right: 0;
  top: 8px;
}
.modal-sort-window-new ul li:nth-last-child(2)::before{
  content: 'Filter By';
  display: block;
  color: #cfcfcf;
  font-weight: 400;
  font-size: 8px;
  margin: 15px 0 10px 0;
}
.flex1 {
  position: relative;
}
.modal-sort-window-new ul li{
  position: relative;
}
.modal-sort-window-new ul li:last-child img,
.modal-sort-window-new ul li:nth-last-child(2) img{
  right: 8rem;
  
}
.modal-sort-window-new ul li:nth-last-child(2) img{
  top: 30px;
}
.modal-sort-window-new ul li:nth-last-child(3) img,
.modal-sort-window-new ul li:nth-last-child(4) img{
  right: 4rem;
}
.modal-sort-window-new ul li:nth-last-child(2){
  margin-top: 10px;
}
.modal-sort-window-new ul li img{
  position: absolute;
  right: 0;
  top: 8px;
}
.modal-sort-window-new ul li:nth-last-child(2)::before{
  content: 'Filter By';
  display: block;
  color: #cfcfcf;
  font-weight: 400;
  font-size: 8px;
  margin: 15px 0 10px 0;
}
ul.checkDelist_impact {
  left: 0% !important;
  margin-left: 24px !important;
  position: relative;
  padding-bottom: 25px;
}

ul.checkDelist_impact li {
  width: 164px !important;
}

.dsf-delistingProj-plus-img {
  width: 12px;
  height: 12px;
  padding-top: 2px;
  margin-right: 2px;
  margin-left: 5px;
}

.delistingProj-delete {
  position: relative;
  right: -10px;
  top: 25px;
  width: 0px;
}

.delistingProj-delete img {
  height: 18px;
  cursor: pointer;
}

.dsf-delistingProjDel-okdialog.dsf-dialog-messagebox {
  width: 340px !important;
}

.dsf-delistingProjDel-okdialog .dsf-delistingProjDel-okmodal {
  height: auto;
}

.dsf-delistingProjDel-okmodal .bw-modal-messagebox-body {
  padding-top: 14px;
}

.dsf-delistingProjDel-okmodal .bw-modal-messagebox-msg {
  text-align: left;
}
.dsf-checkInnoflex-startdatepicker-bottom .dsf-custom-datepicker-open {
  transform: translate3d(-318px, -351px, 0px);
}
.dsf-checkInnoflex-startdatepicker-top .dsf-custom-datepicker-open {
  transform: translate3d(-315px, -30px, 0px);
}
.dsf-checkInnoflex-launchdatepicker-top .dsf-custom-datepicker-open {
  transform: translate3d(-95px, -8px, 0px);
}
.dsf-checkInnoflex-launchdatepicker-bottom .dsf-custom-datepicker-open {
  transform: translate3d(-93px, -304px, 0px);
}

.bwi-checkInnoflex-startdatepicker-left .dsf-custom-datepicker-open {
  transform: translate3d(20px, -253px, 0px);
}

.dsf-desc-length-txt {
  word-break: break-all;
}

.dsf-InventoryCM-checkbox {
  margin-right: 10px;
  pointer-events: unset !important;
  color: #edf1f3 !important;
  padding-left: 0px !important;
  padding-top: 5px !important;
  padding-right: 6px !important;
}

.checkbox-skip input {
  cursor: pointer;
}

.bw-modal-footer-btn-div {
  display: flex;
  justify-content: flex-end;
}

.bw-modal-footer-btn-div button {
  margin: 0px 4px;
}

.dsf-modal-warning {
  max-width: 487px !important;
  max-height: 213px !important;
}

.bw-warnmodal-header-title span {
  font-weight: 900;
  font-size: 16px;
  color: #c7c7c7;
  padding-left: 12px;
}

.bwi-homepage-project-info-stepper {
  width: 82%;
}

.bwi-workflow-col-header-div {
  display: grid;
  text-align: center;
  width: 14%;
  height: 28px;
}

.dsf-newProjectCreation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  padding: 4px 5px 5px 7px;
  border: 1px solid white;
  cursor: pointer;
  border-radius: 2px;
  width: 63px;
  height: 24px;
  margin-top: 8px;
}

.dsf-newProjectCreation span {
  font-size: 10px;
  font-weight: 600;
  color: #c7c7c7;
  font-family: "Unilever Shilling";
}

.dsf-dashbaord-project-detail-div {
  display: flex;
}

.dsf-cursor-disabled {
  cursor: url("../../assets/images/landingPage/disabledPointer.svg"), pointer;
  pointer-events: auto;
}

/***************************** New workFlow Changes ********************************/

.dsf-dashbaord-project-summary-div-new {
  padding: 30px 30px 65px 30px;
  margin-left: 24px;
}

.dsf-sort-icon-New {
  width: 15px;
  height: 10px;
  cursor: pointer;
}

.dsf-sortby-title {
  font-family: "Unilever Shilling";
  font-weight: 600;
  font-size: 10px;
  text-align: center;
  text-transform: uppercase;
}

.dsf-color-EDF1F3 {
  color: #edf1f3;
}

.dsf-color-CFCFCF {
  color: #cfcfcf;
}

.project-details-head {
  align-items: flex-end;
}

.dsf-workflow-stages-row {
  align-items: flex-end;
  justify-content: space-between;
}

.action-row {
  align-items: flex-end;
  justify-content: flex-end;
}

.project-details-list-new {
  margin-top: 11px;
  margin-left: -2px;
  height: 72px;
  width: 98%;
  background-color: #1e232f;
  align-items: center;
  justify-content: center;
  /* /* position: relative; */
}

.innoflex-status-div {
  position: relative;
  width: 20px;
  height: 72px;
  display: flex;
  justify-content: center;
  border-radius: 2px 0px 0px 2px;
}

.innoflex-div-rotate {
  display: flex;
  transform: rotate(-90deg);
  /* text-align: center; */
  align-items: center;
  justify-content: center;
}

.innoflex-status-title {
  font-family: "Unilever Shilling";
  font-weight: 400;
  font-size: 11px;
  color: #ffffff;
  text-align: center;
}

.projectInfo-status-div {
  display: flex;
  max-width: 25%;
  width: 25%;
}

.innoflex-div-rotate img {
  transform: rotate(90deg);
  margin-right: 6px;
}

hr.horizontal-line {
  margin-top: 8px;
  margin-bottom: 7px;
  width: 92%;
  opacity: 0.2;
  /* left: -16px;
  position: relative; */
  border: revert;
  color: #353a46;
}

.color-line-white::after {
  content: "";
  width: 107%;
  height: 1px;
  background: #cfcfcf;
  top: 60%;
  left: 55%;
  position: absolute;
}

.color-line-gray::after {
  content: "";
  width: 96%;
  height: 1px;
  background: #353a46;
  top: 60%;
  left: 55%;
  position: absolute;
}

.color-line-gray-DW::after {
  content: "";
  width: 138%;
  height: 1px;
  background: #353a46;
  top: 60%;
  left: 55%;
  position: absolute;
}

.color-line-gray-BW::after {
  content: "";
  width: 114%;
  height: 1px;
  background: #cfcfcf;
  top: 60%;
  left: 55%;
  position: absolute;
}

.color-line-gray-Action::after {
  content: "";
  width: 126%;
  height: 1px;
  background: #353a46;
  top: 60%;
  left: 55%;
  position: absolute;
}

.color-line-gray-before::before {
  content: "";
  width: 64%;
  height: 2px;
  background: #353a46;
  top: 59%;
  left: -20%;
  position: absolute;
}

.action-tracker-col {
  text-align: center;
}

.color-dot-white {
  height: 7px;
  width: 7px;
  background-color: #cfcfcf;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.snapshot-enabled {
  cursor: pointer;
  opacity: 1;
}

.snapshot-disabled {
  cursor: default;
  opacity: 0.5;
}

.project-summary-editable-col .bwi-proj-info-more-icon .MuiPaper-elevation {
  top: 285px;
}

.dsf-footer-div {
  width: 100%;
  height: 50px;
  flex: 1;
  height: 54px;
  position: fixed;
  bottom: 20px;
  /* left: 0px; */
  /* top: 0px; */
  /* background: #0d1418; */
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  z-index: 99;
}

.dsf-project-creation-div {
  margin-left: 50px;
  width: 140px;
  background: #8fcaeb;
  height: 32px;
  border-radius: 4px;
  padding: 4px 8px 10px 10px;
  box-shadow: 0px 0px 20px 0px #000000b2;
}

.dsf-create-project-title {
  font-family: "Unilever Shilling";
  font-size: 11px;
  font-weight: 600;
  margin-right: 5px;
  color: #1f2123;
}

.dsf-BW-value {
  font-family: "Unilever Shilling";
  font-weight: 600;
  font-size: 16px;
  /* color: #FFFFFF; */
  text-align: center;
}

.dsf-first-stepper .bwi-stepper {
  width: 115%;
  position: absolute;
  top: -26px;
  left: -18px;
}

.dsf-secont-stepper .bwi-stepper {
  width: 114%;
  position: absolute;
  top: -26px;
  left: -13px;
}

@media only screen and (max-width: 1350px) {
  .dsf-first-stepper .bwi-stepper {
    width: 113.5% !important;
    left: -4.5% !important;
  }

  .dsf-BW-value-base {
    padding: 0 !important;
    padding-left: 1% !important;
  }

  .dsf-BW-value-system {
    margin-right: 3% !important;
    padding: 0 !important;
  }

  .project-info-new {
    flex-direction: column;
    padding: 8px;
  }

  .dsf-dashbaord-project-desc-span {
    color: #8b94a8;
    width: 90%;
    height: 50%;
    /* padding-top: 6px; */
    /* overflow-wrap: break-word; */
  }
}

@media only screen and (min-width: 1350px) and (max-width: 1850px) {
  .dsf-first-stepper .bwi-stepper {
    width: 113.5% !important;
    left: -4.5% !important;
  }

  .dsf-BW-value-base {
    padding: 0 !important;
    padding-left: 2.5% !important;
  }

  .dsf-BW-value-system {
    margin-right: 3% !important;
    padding: 0 !important;
  }

  .project-info-new {
    flex-direction: column;
    padding: 10px;
  }

  .dsf-dashbaord-project-desc-span {
    color: #8b94a8;
    width: 90%;
    height: 50%;
    padding-top: 6px;
    /* overflow-wrap: break-word; */
  }
  .dsf-BW-value-system:hover {
    text-decoration: underline;
  }
}

@media only screen and (min-width: 1850px) {
  .dsf-first-stepper .bwi-stepper {
    width: 116% !important;
    left: -4.5% !important;
  }

  .dsf-BW-value-base {
    padding: 0 !important;
    padding-left: 3.5% !important;
    margin-left: 0% !important;
  }

  .dsf-BW-value-system {
    padding: 0 !important;
    margin-right: 2% !important;
  }

  .project-info-new {
    flex-direction: column;
    padding: 10px;
  }

  .dsf-dashbaord-project-desc-span {
    color: #8b94a8;
    width: 90%;
    height: 50%;
    padding-top: 6px;
    /* overflow-wrap: break-word; */
  }
}

.bw-footer-cancle-btn {
  color: #292929;
  font-family: "Unilever Shilling";
  padding: 5px 25px;
  background: #c7c7c7;
  font-size: 12px;
  font-weight: 600;
  width: 82px;
  height: 24px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.bw-footer-cancle-btn:hover,
.bw-footer-cancle-btn:active,
.bw-footer-cancle-btn:disabled,
.bw-footer-cancle-btn:focus {
  background: #c7c7c7;
  color: #292929;
}

.dsf-footer-okbutton-PI {
  color: #292929;
  font-family: "Unilever Shilling";
  padding: 5px 25px;
  background: #8fcaeb;
  font-size: 12px;
  font-weight: 600;
  width: 82px;
  height: 24px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.dsf-footer-okbutton-PI:hover,
.dsf-footer-okbutton-PI:active,
.dsf-footer-okbutton-PI:disabled,
.dsf-footer-okbutton-PI:focus {
  background: #8fcaeb !important;
  color: #292929 !important;
  box-shadow: none !important;
}

.dsf-title-color {
  color: #d7e4ff;
}

.bwi-pointer-none {
  pointer-events: none;
}
