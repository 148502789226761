.options-list {
  text-align: left;
}

.option-row {
  cursor: pointer;
  display: flex;
  height: 30px;
  align-items: center;
  font: unset;
  font-family: "Unilever Shilling";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #edf1f3;
}

.dropdown-parent-container {
  display: flex;
}

.nested-parent .dropdown-parent-container {
  padding-left: 30px;
}

.nested-parent .nested-children-list {
  padding-left: 22px;
}

/* autoCompleteList.css start */
.bwi-custom-autocomplete-list {
  max-height: 265px;
  min-height: 268px;
  overflow: hidden;
}

.bwi-custom-autocomplete-list .options-list {
  margin: 0px 5px 0px 15px;
  max-height: 155px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.bwi-custom-autocomplete-list .bwi-no-options-list {
  margin: 0px 5px 0px 15px;
  max-height: 185px;
  opacity: 0.3;
}

.options-container-input-chip {
  text-align: left;
}

.bwi-autocomplete-chip {
  height: 24px !important;
  background: #c7c7c7 !important;
}

.bwi-autocomplete-select-all span {
  font-size: 12px;
  font-weight: 400;
}

.bwi-custom-autocomplete-list .dsf-delistingProj-search .MuiInputBase-root {
  width: 100%;
  max-width: 100%;
}

.bwi-custom-autocomplete-list .options-container-input-div {
  margin-top: 10px;
}

.bwi-custom-autocomplete-list .dsf-delistingProj-search {
  margin-bottom: 2px;
}

.bwi-autocomplete-select-all {
  height: 35px;
  text-align: left;
  margin: 0px 15px 5px 15px;
  border-bottom: 0.2px solid #797775;
  margin-right: 15px;
  padding-bottom: 5px;
  opacity: 0.8;
}

.dsf-option-row-txt-disable {
  opacity: 0.3;
  pointer-events: none;
  cursor: none;
}

.bwi-option-row-not-selected {
  opacity: 0.7;
}

.bwi-custom-autocomplete-list .bwi-delisBOM-FGDate-row {
  right: 37px;
  width: 94%;
}

.bwi-delistbom-date {
  pointer-events: none;
  opacity: 0.5;
}
.bwi-delistbom-reference {
  width: 10%;
  margin-left: 15%;
}
.bwi-delistbom-reference-disable {
  pointer-events: none;
  opacity: 0.5;
  width: 10%;
  margin-left: 15%;
}
.bwi-delistbom-reference-input-text {
  background-color: transparent;
  color: #fff;
  border: 0.5px solid #797775;
  text-align: center;
  width: 128px;
  height: 24px;
}
.bwi-delistbom-date-text {
  text-align: left;
}

.bwi-delistbom-add-selected-button {
  margin-top: 3.8%;
}
/* autoCompleteList.css end */
