.bwi-dialog-admin-department-details {
  max-width: 924px !important;
  max-height: 600px !important;
}

.bwi-modal-admin-department-details {
  background-color: #1e232f;
  color: #edf1f3;
  max-width: inherit;
  max-height: inherit;
  padding: 20px 20px;
}

.bwi-modal-admin-task-header {
  border: none;
  padding: 0px;
  text-align: left;
  height: 100px !important;
}

.bwi-modal-admin-task-title {
  /* opacity: 0.7; */
  color: #d7e4ff;
}

.bwi-modal-admin-task-subtitle {
  /* opacity: 0.7; */
  color: #cfcfcf;
}

.bwi-modal-title-error {
  padding-left: 2px;
}

.bwi-modal-admin-task-body {
  padding: 5px 3px;
  max-height: 400px;
  min-height: 120px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}

.bwi-admin-lwdupload-col2 {
  align-items: flex-end;
}

.bwi-admin-lwdu-download-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bwi-admin-lwd-download-text {
  font-size: 11px;
  font-weight: 400;
  color: #b7d3ff;
}

.bwi-admin-owner-err-msg {
  line-height: 12px;
  padding-top: 2px;
}

.bwi-admin-textField {
  width: 250px !important;
  margin-right: 7px;
}

.bwi-admin-owner-edit-img {
  padding-bottom: 2px;
  cursor: pointer;
}

.bwi-admin-lwd-upload-col {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bwi-modal-admin-task-footer {
  border: unset;
  padding: 0px;
}

.bwi-footer-admin-task-button {
  width: 80px;
  height: 24px;
  padding: 0px;
  border: none;
  border-radius: 0px;
  color: #292929;
  background-color: #8fcaeb;
  font-size: 12px;
  font-weight: 600;
  opacity: 0.9;
}

.bwi-footer-admin-task-button:hover,
.bwi-footer-admin-task-button:active,
.bwi-footer-admin-task-button:focus,
.bwi-footer-admin-task-button:disabled,
.bwi-footer-admin-task-button:focus-visible {
  background-color: #8fcaeb !important;
  color: #292929 !important;
  box-shadow: none !important;
  border: none !important;
  outline: none;
}

.bwi-footer-admin-task-button:disabled {
  opacity: 0.5;
}

.bwi-admin-department-browse-div {
  border: 1px solid #797775;
  height: 24px;
  width: 325px;
}

.bwi-admin-lwd-file-browse,
.bwi-admin-lwd-file-browse:hover,
.bwi-admin-lwd-file-browse:active,
.bwi-admin-lwd-file-browse:focus {
  background-color: transparent !important;
  color: #797775;
  border: unset;
  height: 21px;
  padding: 0px 5px;
  font-size: 12px;
  font-weight: 400;
  box-shadow: none !important;
  /* color: #B7D3FF; */
}

.bwi-admin-lwd-file-browse-uploaded {
  color: #edf1f3 !important;
}

.bwi-admin-department-browse-div button,
.bwi-admin-department-browse-div button:hover,
.bwi-admin-department-browse-div button:active,
.bwi-admin-department-browse-div button:focus {
  background-color: transparent !important;
  border: unset;
  height: 21px;
  padding: 0px 10px;
  font-size: 11px;
  font-weight: 400;
  box-shadow: none !important;
}

.bwi-admin-lwd-file-browse-button {
  color: #b7d3ff !important;
}

.bwi-admin-lwd-file-delete-button {
  color: #ff9490 !important;
}
