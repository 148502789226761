.notifications-title-span{
    font-family:"Unilever Shilling";
    font-weight: 700;
    font-size: 14px;
    color: #EDF1F3;
}

.notification-menu-container{
    padding: 23px;
}

.notification-menu-heading-container{
    padding-bottom: 23px;
}

.notifications-highlight-span{
    font-family:"Unilever Shilling"!important;
    font-weight: 600!important;
    font-size: 12px!important;
    color: #EDF1F3!important;
    line-height: 16px!important;
    letter-spacing: 0em!important;
    text-align: left!important;

}

.bwi-menu-customized-button,
.bwi-menu-customized-button:hover {
    outline: none !important;
    padding: 2px 4px !important;
    height: 24px;
    width: 24px;
}