/*Style for modalProjectInfo showModal starts here*/

.modal-dialog-centered.bwi-dialog-rnd-signoff {
  max-width: 349px;
  max-height: 244px;
}

.modal-content.bwi-modal-rnd-signoff {
  background-color: #1E232F;
  color: #edf1f3;
}

.bwi-modal-header-rnd-signoff .bwi-modal-headertitle-rnd-signoff {
  font-weight: 600;
}

.bwi-modal-header-rnd-signoff {
  border: unset;
}

img.bwi-rnd-close-img {
  right: 10px;
  height: 12px;
  width: 12px;
  position: relative;
  top: 7px;
}

.bwi-modal-body-rnd-signoff {
  text-align: left;
}

.bwi-modal-footer-rnd-signoff button {
  width: 72px;
  padding: unset !important;
  height: 24px;
}

.bwi-modal-footer-rnd-signoff:focus,
.bwi-modal-footer-rnd-signoff:focus {
  background-color: unset;
  box-shadow: unset;
  border-color: unset;
}
