.bwi-modal-inventorycm {
  max-height: 495px;
  width: 99%;
}

.bwi-modal-inventorycm-header {
  height: 55px !important;
}

.bwi-modal-inventorycm-body {
  width: 96%;
  margin-left: 22px;
  margin-bottom: 12px;
}

.bwi-inventorycm-supplier-nav {
  padding-left: 9px;
  margin-bottom: 10px;
}

ul.bwi-inventorycm-nav-ul {
  left: 8px;
  margin-left: 8px;
  position: relative;
  padding-left: 0px;
}

ul.bwi-inventorycm-nav-ul li.active {
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  opacity: 1;
  background-color: #1a0b32;
}

ul.bwi-inventorycm-nav-ul li {
  text-wrap: nowrap;
  width: 175px;
  opacity: 1;
}

ul.bwi-inventorycm-nav-ul li img {
  padding-bottom: 2px;
  padding-left: 4px;
}

.ul.nav li {
  border: 1px solid #797775;
  color: #797775;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  height: 24px;
  list-style: none;
  opacity: 0.5;
  padding: 1px 12px 0;
  text-align: center;
  transition: all 0.7s;
  width: 75px;
}

.bwi-invcm-supplier-container {
  font-family: "Unilever Shilling";
  font-size: 12px;
  overflow: auto;
  max-height: 48px;
  width: 103%;
}

.bwi-invcm-supplier-span {
  color: #ffffff;
  max-width: 173px;
  min-width: 173px;
  max-height: 18px;
  min-height: 18px;
}

.bwi-invcm-supplier-arrow-steps .bwi-invcm-supplier-step {
  font-weight: 400;
  height: 24px;
  transition: all 0.7s;
  text-align: center;
  color: #797775;
  cursor: pointer;
  margin: 0 1px 0 0;
  padding: 2px 0px 2px 0px;
  width: 175px;
  float: left;
  position: relative;
  background-color: #1e232f;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid #797775;
  border-right: unset;
  border-left: unset;
}

.bwi-invcm-supplier-arrow-steps .bwi-invcm-supplier-span:before {
  content: "";
  position: absolute;
  transition: all 0.7s;
  bottom: -1px;
  left: 173px;
  width: 0;
  height: 0;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-left: 11px solid #1e232f;
  z-index: 5;
  width: 12px;
  height: 24px;
}

.bwi-invcm-supplier-arrow-steps .bwi-invcm-supplier-span:after {
  content: "";
  position: absolute;
  transition: all 0.7s;
  bottom: -0.5px;
  right: 164px;
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 12px solid #797775;
  z-index: 4;
  width: 12px;
  height: 24px;
}

.bwi-invcm-supplier-arrow-steps .bwi-invcm-supplier-step:before {
  content: "";
  position: absolute;
  transition: all 0.7s;
  bottom: -0.5px;
  right: -12px;
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 12px solid #797775;
  z-index: 4;
  width: 11px;
  height: 22px;
}

.bwi-invcm-supplier-arrow-steps .bwi-invcm-supplier-step:after {
  content: "";
  position: absolute;
  transition: all 0.7s;
  bottom: 0.5px;
  right: 166px;
  width: 0;
  height: 0;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-left: 11px solid #1e232f;
  z-index: 5;
  width: 11px;
  height: 22px;
}

.bwi-invcm-supplier-arrow-steps .bwi-invcm-supplier-step:last-child:before,
.bwi-invcm-supplier-arrow-steps .bwi-invcm-supplier-span-first:after,
.bwi-invcm-supplier-arrow-steps .bwi-invcm-supplier-span-last:before,
.bwi-invcm-supplier-arrow-steps .bwi-invcm-supplier-step:first-child:after {
  border: none !important;
}

.bwi-invcm-supplier-arrow-steps .bwi-invcm-supplier-step:last-child {
  border: 1px solid #797775;
}

.bwi-invcm-supplier-arrow-steps .bwi-invcm-supplier-step:first-child {
  border: 1px solid #797775;
  border-right: unset;
}

.bwi-invcm-supplier-arrow-steps .bwi-invcm-supplier-step span {
  position: relative;
}
.bwi-invcm-supplier-arrow-steps
  .bwi-invcm-supplier-step.bwi-invcm-supplier-current {
  color: #1f2123;
  background-color: #8fcaeb;
  font-weight: 600;
  border-right: 1px solid #c7c7c7;
}

.bwi-invcm-supplier-current .bwi-invcm-supplier-span {
  color: #1f2123;
}

.bwi-invcm-supplier-arrow-steps
  .bwi-invcm-supplier-step.bwi-invcm-supplier-current:before {
  border-left: 12px solid #8fcaeb;
  bottom: -0.5px !important;
  right: -12px !important;
  z-index: 7;
}

.bwi-invcm-supplier-current .bwi-invcm-supplier-span:before {
  border-left: 12px solid #8fcaeb;
  z-index: 7;
  left: 172px !important;
  bottom: -0.5px !important;
}

.bwi-invcm-supplier-status-icon {
  padding: 0px 0px 3px 3px;
}

.bwi-inventorycm-mail-label {
  margin-top: 10px;
}

.bwi-inventorycm-mail-label {
  display: flex;
  flex: 30% 1;
  justify-content: left;
}

.bwi-inventorycm-mail-label label {
  color: #8a8d8c;
  font-family: Unilever Shilling;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 11px;
}

.bwi-inventorycm-mail-label-msg {
  padding-left: 2px;
}

.bwi-inventorycm-mail-input {
  margin-bottom: 10px;
  padding-bottom: 7px;
  max-height: 30px;
  margin-right: -24px;
  /* justify-content: space-evenly; */
}

.bwi-inventorycm-mailid-text {
  display: flex;
  flex: 25% 1;
  justify-content: left;
  flex-direction: column;
}

.bwi-inventorycm-mailid-text input {
  background-color: transparent;
  border: 1px solid #797775;
  color: #edf1f3;
  height: 24px;
  font-size: 12px;
  font-weight: 400;
  width: 285px;
}

.bwi-inventorycm-mailmsg-text {
  display: flex;
  flex: 90% 1;
  justify-content: left;
  padding-left: 0px;
}

.bwi-inventorycm-mailmsg-text input {
  background-color: transparent;
  border: 1px solid #797775;
  color: #edf1f3;
  height: 24px;
  font-size: 12px;
  font-weight: 400;
  width: 899px;
}

.bwi-inventorycm-download-btn-col {
  display: flex;
  padding: 0px;
  justify-content: space-between;
}

.bwi-inventorycm-download-btn {
  width: 168px;
  align-items: center;
  background: #8fcaeb;
  color: #292929;
  display: flex;
  font-family: Unilever Shilling;
  font-size: 12px;
  font-weight: 600;
  height: 24px;
  justify-content: center;
  /* padding: 5px 25px; */
  border-radius: 0;
}

.bwi-inventorycm-download-btn:hover,
.bwi-inventorycm-download-btn:active,
.bwi-inventorycm-download-btn:focus,
.bwi-inventorycm-download-btn:disabled {
  border-color: #6c757d;
  color: #292929;
  opacity: 0.65;
  background: #8fcaeb;
}

.bwi-inventorycm-download-btn:first-child {
  margin-right: 4px;
}

.bwi-inventorycm-footer-btn {
  width: 84px !important;
}
.bwi-inventoryrpm-footer-btn {
  width: fit-content !important;
}

.bwi-inventorycm-upload-label {
  margin-top: 15px;
  text-align: left;
  color: #edf1f3;
  padding-left: 2px;
  margin-bottom: 10px;
}

.bwi-inventorycm-fileUpload-row .dsf-delist-form-upload-browse {
  width: 90%;
}

.bwi-inventorycm-fileUpload-row {
  max-height: 60px;
  text-align: left;
}
.bwi-inventorycm-rpmsupplier-fileUpload-row .dsf-delist-form-upload-browse {
  width: 90%;
  max-height: 60px;
  text-align: left;
}

.bwi-inventorycm-table-div {
  margin-left: -8px;
  text-align: left;
  max-height: 220px;
  overflow: auto;
  overflow-x: hidden;
  padding-right: 21px;
  width: 104%;
  max-height: 180px;
  min-height: 100px;
  opacity: 0.8;
}

.bwi-modal-inventorycm .bwi-skipinventory-div .bwi-checkbox-col,
.bwi-modal-inventorycm .bwi-skipinventory-div .bwi-checkbox-col span,
.bwi-modal-inventorycm .bwi-inventorycm-upload-label {
  opacity: 0.8;
}

.bwi-modal-inventorycm .bwi-skipinventory-div .bwi-checkbox-col {
  height: 24px;
  align-content: center;
}

.bwi-modal-inventorycm .bwi-skipinventory-div .bwi-checkbox {
  padding-top: 0px !important;
  padding-bottom: 2px !important;
}

.bwi-inventorycm-fileUpload-col {
  max-width: 102%;
  width: 102%;
}

.bwi-rpm-fileUpload-col {
  max-width: 88%;
  width: 88%;
}

.bwi-inventorycm-table-row {
  margin-left: 4px;
  padding-bottom: 4px;
}

.bwi-inventorycm-table-row div {
  padding: 0px 5px;
}

.bwi-inventorycm-footer {
  padding-bottom: 8px;
  /* margin-top: 5px; */
  margin-left: 19px;
  display: flex;
  justify-content: space-between;
}

.bwi-inventorycm-skip-footer {
  display: flex;
  justify-content: space-between;
  margin-left: 27px;
}

.bwi-skipinventory-div {
  display: flex;
  padding: 12px 0px 12px 0px;
}

.bwi-rpm-download-div {
  padding: 0px;
  cursor: pointer;
  text-align: right;
  width: 165px;
}

.bwi-inv-download-div {
  cursor: pointer;
}

.bwi-inv-download-Img {
  height: 18px;
  width: 18px;
  margin-bottom: 4px;
  margin-right: 4px;
}
.rpm-supplier-container-div {
  overflow-x: auto;
}
.rpm-supplier-container-table {
  width: 1800px;
  height: 160px;
}
.bwi-inventory-loader {
  position: absolute;
  top: 50px;
}
.break-all {
  word-break: break-all;
}
