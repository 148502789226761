.dsf-dialog-fgCheck-delistBOM {
    max-width: 909px !important;
    max-height: 449px !important;
    min-width: 909px !important;
    min-height: 549px;
    z-index: 100;
    /* margin-top: 0.5rem !important;
    margin-left: 7.75rem!important;
    margin-bottom: 0rem !important;
    left: 80px; */
    top: 35px;
}

.dsf-modal-fgCheck-delistBOM {
    background-color: #353535;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    color: #EDF1F3;
    height: 449px;
}

.dsf-modal-fgCheck-delistBOM input {
    cursor: pointer;
}

.dsf-delistBOMfg-body-title{
    text-align: left;
    padding-bottom: 10px;
}
.dsf-delistBOMfg-body-input {
    border: 1px solid #797775;
    padding: 5px;
    width: 856px;
    min-height: 244px;
    max-height: 244px;
    overflow-x: hidden;
}

.dsf-delistBOMfg-body-input-textarea {
    background-color: #353535;
    border: none;
    width: 100%;
    height: 228px;
    text-align: left;
    align-items: center;
    color: #EDF1F3;
    
}

.dsf-delistBOMfg-body-input-textarea:focus-visible {
    outline: none;
    border: none;
}

.bw-delistBOMfg-body-col {
    padding: unset;
}

.dsf-delistBOMfg-txt-disable span {
    color: #797775;
}

.dsf-delistBOMfg-checkbox-disable{
    opacity:0.3
}

.dsf-delistBOMfg-body-input .bw-delistBOMfg-body-col {
    text-align: left;
}

.bw-modal-delistBOMfg-body {
    padding-left: 25px;
}

.dsf-delistBOMfg-body-input .loading-spinner{
    position: absolute;
}