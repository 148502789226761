.dsf-custom-datepicker-open {
  transform: translate3d(-39px, -50px, 0px);
  position: relative;
  top: 0px;
  left: 150px;
  height: 290px;
  width: 300px !important;
  border: 0.5px solid #c5c5c5;
  z-index: 2;

  .MuiPickersToolbar-root,
  .MuiDialogActions-root {
    display: none !important;
  }

  .MuiPickersLayout-contentWrapper {
    background: #1E232F;
  }

  & button {
    border: unset;
    outline: unset;
  }

  .MuiPickersCalendarHeader-labelContainer {
    .MuiSvgIcon-root {
      color: #edf1f3;
    }
  }

  .MuiPickersArrowSwitcher-root {
    .MuiSvgIcon-root {
      color: #edf1f3;
    }
  }

  .MuiDateCalendar-root {
    width: 290px;
    height: 290px;

    .MuiPickersCalendarHeader-root {
      width: 100%;
      height: 100%;
      margin: unset;
      margin-top: 15px !important;
      margin-bottom: 0px !important;

      .MuiPickersFadeTransitionGroup-root {
        font-family: "Unilever Shilling";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #edf1f3;
      }
    }

    .MuiPickersFadeTransitionGroup-root {
      height: 79%;
      width: 100%;

      .Mui-selected {
        background-color: black !important;
        outline: unset;
      }

      & span,
      .MuiDayCalendar-weekNumber {
        font-family: "Unilever Shilling";
        font-style: normal;
        font-weight: 400;
        font-size: 8px;
        color: #cfcfcf;
      }

      .MuiDayCalendar-weekNumber {
        font-size: 12px !important;
      }

      & button:focus {
        outline: unset;
      }

      & button {
        font-family: "Unilever Shilling";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        color: #edf1f3;
      }

      .Mui-disabled {
        color: #a0a0a0 !important;
      }
    }
  }
}

/******************* datePickerNew style start *************/

.bwi-custom-datepicker-add-img {
  position: relative;
  bottom: 0px;
  left: 10px;
  cursor: pointer;
}

.bwi-custom-datepicker .MuiInputBase-root {
  max-width: unset !important;
}

.bwi-custom-datepicker input {
  text-align: left !important;
  margin-left: 10px;
  font-family: "Unilever Shilling";
  font-size: 12px;
  font-weight: 400;
}

.bwi-custom-datepicker-missing-txt {
  -webkit-text-fill-color: #797775;
}

.bwi-custom-datepicker-popper .MuiPickersLayout-contentWrapper {
  background-color: #1e232f !important;
}

/******************* datePickerNew style end *************/
