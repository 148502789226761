.dsf-modal-Country-info{
    min-width: 425px;
    min-height: 356px;
    max-width: 425px !important ;
    max-height: 356px !important;
    top:74px;
}

.dsf-modal-Country-info .modal-body{
    overflow: auto;
    overflow-x: hidden;
}

.country-container{
    padding: 0px;
    display: flex;
}

.country-col{
    padding: 0px;
    text-align: left;
    margin-left: 12px;
}

.country-row{
    padding-bottom: 4px;
}

.country-details{
  padding: 0px;
}

.country-details span{
    font-size: 12px;
    color: #EDF1F3;
    word-wrap: break-word;
}
