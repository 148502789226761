/*Style for modalProjectInfo showModal starts here*/

.modal-dialog-centered.dsf-dialog-rnd-signoff {
    max-width: 349px;
    max-height: 244px;
}

.modal-content.dsf-modal-rnd-signoff {
    background-color: #353535;
    color: #EDF1F3;
}

.dsf-modal-header-rnd-signoff .dsf-modal-headertitle-rnd-signoff {
    font-weight: 600;
}

.dsf-modal-header-rnd-signoff {
    border: unset;
}

img.dsf-rnd-close-img {
    right: 10px;
    height: 12px;
    width: 12px;
    position: relative;
    top: 7px;
}

.dsf-modal-body-rnd-signoff {
    text-align: left;
}

.dsf-modal-footer-rnd-signoff button {
    width: 72px;
    padding: unset !important;
    height: 24px;
}

.dsf-modal-footer-rnd-signoff:focus,
.dsf-modal-footer-rnd-signoff:focus {
    background-color: unset;
    box-shadow: unset;
    border-color: unset;
}