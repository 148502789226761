.dsf-dialog-delistBOM {
  
    max-width: 1081px !important;
    min-width: 1044px !important;
    z-index: 100;
}

.list-parent {
    /* border:1px solid #797775; */
    height: 250px !important;
    max-height: 220px !important;
    color:#EDF1F3;
    display: grid;
    /* justify-content: space-around;
    align-items: flex-start; */
}
.not-found{
    justify-self: center;
    align-self: flex-start;
}

.dsf-modal-delistBOM {
    background-color: #353535;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    color: #EDF1F3;
     max-height: 633px !important;
     min-height: 550px;
}

.bw-modal-delistBOM-header.modal-header {
    border: unset;
    text-align: left;
}

.bw-delistBOM-header-title {
    padding-right: 15%;
}

.dsf-modal-sub-title {
    font-family: 'Unilever Shilling';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16.73px;
    color: #CFCFCF;
}

img.dsf-close-delistBOM-img {
    right: 10px;
    height: 12px;
    width: 12px;
    position: relative;
    top: 7px;
    left: 0px !important;
}

.bw-delistBOM-row {
    /* padding-bottom: 10px; */
    padding-bottom: 5px;
}

.dsf-form-delist {
    text-align: left;
}

.dsf-delist-form-radio-type label.form-check-label {
    font-family: 'Unilever Shilling';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    text-align: left;
    color: #797775;
}

.dsf-delist-form-radio-type-selected label.form-check-label {
    font-family: 'Unilever Shilling';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    text-align: left;
    color: #EDF1F3;
}

.dsf-delist-form-radio-type input[type=radio] {
    background: #121415;
    background-color: #EDF1F3;
    width: 12px !important;
    height: 12px !important;
}

.dsf-delist-form-radio-type-selected input[type=radio] {
    background: #EDF1F3;
    background-color: #EDF1F3;
    accent-color: #EDF1F3;
    width: 12px !important;
    height: 12px !important;
}


.dsf-manual-entry-section {
    display: inline-flex;
    flex-direction: row;
    width: 100%;
    min-width: 100%;
}

.dsf-manual-entry-section-left {
    width: 45%;
}

.dsf-manual-entry-section-center {
    width: 10%;
    height: 100%;
    padding-left: 18px;
    padding-top: 100px;
}

.dsf-manual-entry-section-right {
    width: 45%;
}

.dsf-manual-entry-label {
    padding-bottom: 7px;
}

.dsf-manual-entry-card {
    width: 449px;
    height: 243px;
    border: 1px solid #797775;
    border-radius: 0px !important;
    background-color: #353535 !important;
}

.dsf-dialog-transferlist .MuiPaper-elevation {
    max-height: 250px !important;
}


.dsf-manual-entry-card-list {
    background-color: #353535 !important;
}

.dsf-manual-entry-card-list-item {
    height: 25px;
    font-family: 'Unilever Shilling';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    text-align: left;
}


.css-cveggr-MuiListItemIcon-root {
    min-width: 35px !important;
    margin-left: -10px !important;
}

.dsf-manual-entry-card-list-item-text {
    font-family: 'Unilever Shilling' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 17px !important;
    text-align: left !important;
}

.css-tlelie-MuiListItemText-root {
    font-family: 'Unilever Shilling' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 17px !important;
    text-align: left !important;
}

.dsf-manual-entry-card-header-area {
    display: inline-flex;
    background-color: #353535 !important;
    font-family: "Unilever Shilling" !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    border-radius: 0px !important;
    color: #EDF1F3 !important;
    height: 24px !important;
    width: 400px;
}

#dsf-manual-entry-card-header-search-bx {
    font-family: "Unilever Shilling" !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    border-radius: 0px !important;
    color: #EDF1F3 !important;
    height: 24px !important;
    text-decoration: none;
}

#dsf-manual-entry-card-header-search-bx:hover {
    font-family: "Unilever Shilling" !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    border-radius: 0px !important;
    color: #EDF1F3 !important;
    height: 24px !important;
    text-decoration: none;
}

#dsf-manual-entry-card-header-search-bx:disabled {
    font-family: "Unilever Shilling" !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    border-radius: 0px !important;
    color: #A0A0A0 !important;
    height: 24px !important;
    text-decoration: none;
}

.dsf-manual-entry-card-header-area .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary {
    min-width: 400px;
}

.dsf-manual-entry-card-header-search-icon {
    margin-top: 5px;
}

.dsf-manula-entry-navigation-btn {
    width: 24px !important;
    height: 24px !important;
    background-color: #C7C7C7 !important;
    color: #292929 !important;
    border: 1px solid #797775 !important;
    font-family: "Unilever Shilling" !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    border-radius: 0px !important;
}

.dsf-manula-entry-navigation-btn:hover {
    width: 24px !important;
    height: 24px !important;
    background-color: #C7C7C7 !important;
    color: #292929 !important;
    border: 1px solid #797775 !important;
    font-family: "Unilever Shilling" !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    border-radius: 0px !important;
}

.dsf-manula-entry-navigation-btn:disabled {
    width: 24px !important;
    height: 24px !important;
    background-color: #C7C7C7 !important;
    color: #A0A0A0 !important;
    border: 1px solid #797775 !important;
    font-family: "Unilever Shilling" !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    border-radius: 0px !important;
}

.custom-download-link {
    cursor: pointer;
    text-decoration: none;
}

.custom-download-link:hover {
    cursor: pointer;
    text-decoration: none;
}

.btn-custom-variant {
    background: orange;
}

.btn-custom-variant:active {
    background: orange;
}

.bw-delistBOM-row {
    text-align: left;
    padding-left: 17px;
    max-height: 200px !important;
    height: auto !important;
}

.dsf-manual-upload-header-right {
    padding-left: 27px;
}

.dsf-header-label {
    text-align: left;
    font-family: 'Unilever Shilling';
    font-style: normal;
    font-size: 12px;
    line-height: 17px;
}

.dsf-delist-form-upload-type {
    height: 24px;
}

.dsf-value-label {
    text-align: left;
    font-family: 'Unilever Shilling';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
}

.dsf-delist-bom-multiselect-ddlist fieldset.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    width: 476px;
    background: #353535;
    border: 1px solid #797775;
    border-radius: 0px;
    font-family: 'Unilever Shilling';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #EDF1F3;
}

.dsf-delist-bom-multiselect-ddlist fieldset.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline:hover {
    width: 476px;
    max-height: auto;
    background: #353535;
    border: 1px solid #797775;
    border-radius: 0px;
    font-family: 'Unilever Shilling';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #EDF1F3;
}

.dsf-delist-bom-multiselect-ddlist .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-1gywuxd-MuiInputBase-root-MuiOutlinedInput-root {
    display: flex;
    flex-direction: row;
    max-width: max-content;
    max-height: max-content;
    /* flex-wrap: wrap; */
}

.dsf-delist-bom-multiselect-ddlist .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-1gywuxd-MuiInputBase-root-MuiOutlinedInput-root:hover {
    display: flex;
    flex-direction: row;
    max-width: max-content;
    max-height: max-content;
    /* flex-wrap: wrap; */
}

.dsf-delist-bom-multiselect-ddlist .MuiAutocomplete-tag {
    z-index: 99999999999;
    top: -10px;
    float: left;
}

.dsf-delist-bom-multiselect-ddlist span.MuiAutocomplete-tag.MuiAutocomplete-tagSizeMedium {
    margin-top: -15px;
}

.dsf-delist-bom-multiselect-ddlist span.MuiChip-label.MuiChip-labelMedium.css-6od3lo-MuiChip-label {
    padding-left: 0px;
}

.dsf-delist-bom-multiselect-ddlist deleteIconFilledColorDefault.css-i4bv87-MuiSvgIcon-root {
    color: #EDF1F3;
}

.dsf-delist-bom-multiselect-ddlist input#checkboxes-tags-demo {
    font-size: 12px;
    font-weight: 400;
    color: #EDF1F3;
    font-family: 'Unilever Shilling';
    font-style: normal;
    margin-top: -15px;
    z-index: 9999999;
    /* max-width: 400px; */
    /* width: 50px; */
    min-width: 400px;
    width: 400px;
}

/* .MuiSvgIcon-root .MuiSvgIcon-fontSizeMedium .css-i4bv87-MuiSvgIcon-root {
    z-index: 99999999;
    color: #EDF1F3;
} */

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiChip-deleteIcon.MuiChip-deleteIconMedium.MuiChip-deleteIconColorDefault.MuiChip-deleteIconFilledColorDefault {
    z-index: 99999999;
    color: #EDF1F3;
}

.MuiAutocomplete-endAdornment button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator {
    z-index: 99999999;
    color: #EDF1F3;
    padding-top: -7px;
}

.MuiAutocomplete-endAdornment button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator {
    z-index: 99999999;
    color: #EDF1F3;
    padding-top: -7px;
}

.bw-delist-footer-btn {
    width: 108px;
}

.bw-modal-footer button.bw-delist-footer-btn:disabled {
    color: #A0A0A0;
}

.dsf-delist-form-browse-button,
.dsf-delist-form-upload-btn-css,
.bw-delist-footer-btn,
.dsf-delist-form-upload-btn-css:disabled,
.dsf-delist-form-browse-button:disabled,
.dsf-delist-form-browse-button:hover,
.dsf-delist-form-upload-btn-css:hover {
    height: 24px;
    width: 82px;
    padding: unset !important;
}

.dsf-dialog-delistBOM .bw-modal-footer {
    padding-right: 30px;
    padding-bottom: 30px !important;
}

.check-delist-data-disable {
pointer-events: none;
}

.check-delist-data-disable-txt{
    color: #A0A0A0
}

.dsf-btn-delist-fg-check{
    color: #292929;
    font-family: "Unilever Shilling";
    padding: 5px 25px;
    background: #c7c7c7;
    font-size: 12px;
    font-weight: 600;
    width: 332px;
    height: 24px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.dsf-btn-delist-fg-check:disabled{
    color: #A0A0A0;
}

.dsf-delistBOMfg-body-table {
    margin-left: -5px;
    margin-right: -5px;
    padding-bottom: 3px;
}

.dsf-header-delistBOM-col{
    padding:unset !important;
}

.dsf-btn-delist-fg-check:focus, .dsf-btn-delist-fg-check:hover{
    box-shadow: none;
    border: unset;
    color: #292929;
    background: #c7c7c7;
}

.bw-delistBOMfg-body-col {
    padding-left: 5px;
    padding-right: 5px;
}

.bw-delistBOM-header-title {
    padding-left: 10px;
    padding-bottom: 10px;
}

.dsf-header-delistBOM-col .dsf-header-label {
    padding-right: 10px;
}

.dsf-header-delistBOM-col-launch {
    padding-left: 30px !important;
}

.dsf-header-delistBOM-col-fg-btn {
    padding-left: 65px !important;
}