.dsf-dialog-transferlist {
  .MuiPaper-rounded {
    width: 320px;
  }

  .Mui-disabled {
    pointer-events: none;
  }

  .MuiGrid-root {
    /* min-height: 229px; */
    max-height: 245px;

    .MuiCardHeader-root {
      display: none;
    }

    .dsf-transferlist-list-search {
      padding-top: 7px;
    }

    .dsf-transferlist-list-left {
      width: 40%;

      .MuiList-root {
        max-height: 200px;
        min-height: 177px;
      }
    }

    .bw-transferlist-button {
      width: 10%;
      padding-left: 30px;
    }

    .dsf-transferlist-list-right {
      width: 45%;

      .MuiPaper-elevation {
        height: 250px;
      }
    }

    .MuiPaper-elevation {
      border: 1px solid #797775;
      border-radius: 0px;
      background: inherit;
      width: 462px;
      max-height: 234px;
    }

    .bw-transferlist-button {
      .MuiButtonBase-root {
        background: #c7c7c7;
        height: 24px;
        min-width: unset;
        width: 24px;
        border-radius: 0px;
        padding: unset;
        margin-top: 8px;
        color: #292929;
        cursor: pointer;
        left: 17px;
        border: 1px solid #797775;
        font-family: "Unilever Shilling";
        font-size: 12px;
        font-weight: 600;
      }

      .MuiButtonBase-root:disabled {
        color: #a0a0a0;
      }
    }

    .MuiList-root {
      color: #edf1f3;
      background-color: inherit;
      width: 97%;
      height: 93%;
      padding: unset;

      .MuiListItemIcon-root {
        padding-bottom: 0px;
      }

      li {
        padding: unset;
        padding-left: 10px;
        padding-bottom: 0px !important;
        max-height: 30px;
      }
    }
  }
}

.css-cveggr-MuiListItemIcon-root {
  min-width: unset !important;
  cursor: pointer;
  margin-bottom: 0px;
  margin-top: 0px;
  height: 26px;
}

.dsf-delistbom-scroll-disabled {
  overflow: hidden !important;
}

.dsf-delistbom-listtxt-disabled {
  color: #797775;
}

.list-parent .MuiListItem-root {
  padding-bottom: 0px;
  padding-top: 0px;
}
