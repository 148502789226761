.bwi-modal-delistBOM {
  width: 99%;
}

.bwi-modal-delistBOM-header {
  height: 75px !important;
  margin-bottom: 5px;
}

.bwi-modal-delistBOM-body {
  min-height: 305px;
}

.bwi-modal-delistBOM-footer {
  margin-top: 5px;
  padding-right: 6px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  margin-left: 21px;
}

.bwi-delistBOM-dropdown-row {
  padding: 0px 15px 15px 27px;
}

.bwi-modal-delistBOM-manual-upload {
  padding-left: 27px;
  padding-bottom: 10px;
}

.bwi-header-delistBOM-col {
  text-align: left;
  display: flex;
}

.bwi-delistBOM-dropdown-label {
  margin-right: 10px;
}

.bwi-delistBOM-dropdown-div,
.bwi-delistbom-rpm-supplier-dropdown {
  width: 389px;
  height: 24px;
}

.bwi-delistBOM-dropdown-div .input-container,
.bwi-delistbom-rpm-supplier-dropdown .input-container {
  display: flex;
  position: relative;
}

.bwi-delistBOM-dropdown-div input,
.bwi-delistbom-rpm-supplier-dropdown input {
  width: 100%;
  height: 24px;
  background-color: #1e232f;
  border: 1px solid #797775;
  color: #edf1f3;
  font-family: "Unilever Shilling";
  font-weight: 400;
  font-size: 12px;
  padding-left: 15px;
}

.bwi-delistBOM-dropdown-div img,
.bwi-delistbom-rpm-supplier-dropdown img {
  position: absolute;
  right: 3%;
  top: 38%;
}

.bwi-delistBOM-dropdown-div .options-container,
.bwi-delistbom-rpm-supplier-dropdown .options-container {
  background-color: #1e232f;
  border: 1px solid #797775;
  /* min-height: 59px;
    min-width: 487px; 
    position: absolute;
    top: 26px; */
  z-index: 2;
  position: relative;
}
.bwi-delistbom-rpm-supplier-dropdown .options-container {
  height: 200px;
  overflow-y: auto;
}
.bwi-delistbom-rpm-supplier-dropdown .options-container .options-list {
  overflow-y: hidden;
}

.bwi-delistBOM-dropdown-div .options-container .option-row,
.bwi-delistbom-rpm-supplier-dropdown .options-container .option-row {
  background-color: unset;
  font: unset;
  font-family: "Unilever Shilling";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #edf1f3;
  padding-left: 16px;
}
.bwi-delistbom-rpm-supplier-dropdown .options-container .option-row {
  padding: 5px 12px;
  height: inherit;
}
.bwi-delistBOM-dropdown-div .options-container .option-row:hover,
.bwi-delistBOM-dropdown-div .options-container .option-row:focus,
.bwi-delistbom-rpm-supplier-dropdown .options-container .option-row:hover,
.bwi-delistbom-rpm-supplier-dropdown .options-container .option-row:focus {
  background-color: #340d71;
}

.bwi-delistBOM-dropdown-div .options-container .menu-tooltip {
  position: absolute;
  left: 13%;
  /* top: 30%; */
  width: 330px;
  min-height: 121px;
  background: #1e232f;
  border: 0.5px solid #797775;
  font-family: "Unilever Shilling";
  font-weight: 400;
  font-size: 12px;
  color: #edf1f3;
  padding: 14px;
  z-index: 999;
}

.bw-delistBOMfg-btn img {
  margin-top: 2px;
}

.dsf-delistingProj-category-list
  .dsf-delistingProj-search
  .dsf-delistingProj-search-text
  input {
  font-family: "Unilever Shilling";
  font-weight: 400;
  font-size: 12px;
  color: #797775;
  -webkit-text-fill-color: #797775;
}

.bwi-delistBOM-function-nav-ul {
  left: 0% !important;
  margin-left: 8px !important;
  position: relative;
  padding-left: 0px;
}

.bwi-delistBOM-function-nav-ul li {
  text-wrap: nowrap;
  width: 186px !important;
  font-size: 12px;
  font-weight: 400;
}

.bwi-delistBOM-function-nav-ul li.active {
  font-size: 12px;
  font-weight: 900 !important;
  opacity: 0.8 !important;
  background: #340d71;
}

.bwi-delistBOM-list-span {
  font-size: 12px;
  font-weight: 400;
  opacity: 0.8;
}

.bwi-delistBOM-list-row-not-selected {
  opacity: 0.7;
}

.bwi-delistBOM-list-div {
  border: 1px solid #c5c5c5;
  min-height: 270px;
  max-height: 270px;
}

.bwi-delistBOM-list-left-container {
  max-height: 260px;
  min-height: 260px;
}

.bwi-delistBOM-list-data {
  max-height: 182px;
  min-height: 182px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 40px;
}

.bwi-delistBOM-data-disable {
  opacity: 0.5;
  pointer-events: none;
  cursor: none;
}

.bwi-delistBOM-table-data-disable {
  opacity: 0.3;
  pointer-events: none;
  cursor: none;
}

.bwi-delistBOM-list-right-row {
  min-height: 30px;
  padding-bottom: 5px;
  text-align: left;
  word-break: break-all;
  font-size: 12px;
  font-weight: 400;
  padding-left: 15px;
  display: flex;
  align-items: flex-start;
}

.bwi-delistBOM-list-right-col {
  word-break: break-word;
}

.bwi-delistBOM-list-right-header {
  min-height: 30px;
  padding-bottom: 8px;
  text-align: left;
  word-break: break-all;
  font-size: 12px;
  padding-top: 5px;
  font-weight: 600;
  padding-right: 7px;
  padding-left: 15px;
  color: rgba(255, 255, 255, 0.7);
}

.bwi-delistBOM-list-right-row .bwi-checkbox,
.bwi-delistBOM-list-right-header .bwi-checkbox {
  padding-bottom: 2px !important;
  padding-left: 5px !important;
  padding-right: 6px !important;
  padding-top: 0px !important;
}

.bw-delistBOMfg-right-btn {
  right: 30px !important;
}

.bwi-delistBOM-search-again {
  color: #b7d3ff;
  cursor: pointer;
}

.bwi-delistBOM-align-center {
  align-content: center;
}
/* .bwi-delistbom-overflow {
  overflow-x: auto;
} */
.bwi-delistBOM-list-right-container {
  overflow-x: hidden !important;
}
.bwi-delistbom-right-table {
  width: 770px;
}
.bwi-delistbom-delete-button {
  margin-top: 12px;
}
.bwi-delistbom-delete-icon {
  margin-right: 8px;
}
.bwi-delistbom-right-col {
  padding-right: 2%;
}
.bwi-delistbom-select-supplier-div {
  display: flex;
  margin-top: 10px;
  align-items: center;
}

.dropdown-search-icon {
  position: absolute;
  left: 3%;
  top: 20% !important;
}
.searchTextBox:focus {
  outline: none;
}

.bwi-delist-filter-arrow {
  width: 18px;
  height: 18px;
}
.bwi-left-box {
  width: calc(44.7% - 15px);
  margin-right: 15px;
}
.bwi-right-box {
  width: 55%;
}
.bwi-fg-country,
.bwi-fg-fgcode {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.bwi-delistBOM-dropdown-row.bwi-fg-left-container {
  padding: 0;
  display: flex;
  margin: auto;
}
.bwi-modal.bwi-modal-delistBOM.bwi-modal-fg-container {
  width: 100%;
}
