.scenario-upload-Img {
  height: 14px;
  width: 14px;
  align-self: center;
}

.add-Chart-Img {
  height: 36px;
  width: 36px;
  align-self: center;
}

.upload-div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px dashed #797775;
  margin: 6px;
}

.download-text {
  font-size: 11px;
  font-weight: 400;
  color: #b7d3ff;
  cursor: pointer;
}

.upload-button {
  border: 2px solid #b7d3ff;
  width: 108px;
  height: 24px;
  color: #b7d3ff;
  font-size: 11px;
  font-weight: 400;
  cursor: pointer;
  background: transparent;
  margin: 8px 0 14px 0;
}

.scenario-modal-content .upload-button:hover {
  background: #b7d3ff !important;
  color: black !important;
}

.custom-modal {
  width: 100%;
  height: 100%;
  background-color: brown;
  display: block;
}

.scenario-modal-content {
  background-color: #353535;
  color: #edf1f3;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  position: fixed;
  /* padding: 10px; */
  /* top: 45px; */
  left: 0px;
  right: 0px;
  bottom: 0px;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-radius: 0%;
  border-top-color: rebeccapurple;
  border-bottom-color: #797775;
}
.Scenario-template-div {
  display: flex;
  flex-direction: column;
}
/* .scenario-modal-content .dsf-header {
  height: 38px !important;
} */

.scenario-modal-content .dsf-header-title-div {
  margin-left: 9px;
}

.bw-planner-top-div {
  height: 45% !important;
  background-color: #353535 !important;
}

.bw-scenario-list-div {
  flex-direction: column !important;
  justify-content: space-between !important;
}

.bw-upload-scenario-div {
  justify-content: center !important;
  flex-direction: column !important;
  height: 100%;
  display: flex;
}

.scenario-div {
  width: 200px;
  max-width: 200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
}

.scenario-div div:hover {
  background-color: #1f2123;
}

.bw-scenario-list-div div {
  display: flex;
  flex-direction: column;
}

.checkDelist-modal-header-div div {
  width: 350px;
  max-width: max-content;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.col1 {
  width: 430px !important;
  max-width: 430px;
}

.bw-upload-scenario-div div {
  display: flex;
  flex-direction: column;
}

.scenario-upload-modal {
  position: fixed !important;
  width: 563px !important;
  height: 302px !important;
  /* top: 30%; */
  right: 30%;
  background-color: #1e232f !important;
  border-color: #797775;
  border-width: 1px;
  border-radius: 0%;
}

.scenario-add-action-modal {
  position: fixed !important;
  width: 920px !important;
  height: 490px !important;
  max-height: 490px !important;
  /* top: 30%; */
  right: 23%;
  background-color: #353535 !important;
  border-color: #797775;
  border-width: 1px;
  border-radius: 0%;
}

.scenario-upload-header-div {
  margin-bottom: 20px;
}

.bw-checkDeslist-header-title-div img {
  top: 8px;
  right: 8px;
  position: relative;
  cursor: pointer;
}

.review-action-body .col {
  white-space: nowrap !important;
}

.scenario-upload-div > * {
  margin-left: 15px !important;
}

.scenario-upload-div span {
  margin-bottom: 10px !important;
}

.scenario-upload-div input {
  background-color: #1e232f !important;
  border: 1px solid #797775;
  height: 24px;
  width: 520px !important;
  color: #edf1f3 !important;
}

.scenario-upload-footer-btn {
  width: 82px !important;
  height: 24px !important;
  padding: 1px 8px 1px 8px !important;
  background: #8fcaeb;
}

.scenario-upload-footer-div {
  margin-right: 0px;
  margin-bottom: 0rem;
  margin-top: -6px;
}

.fileUpload-div {
  width: 533px;
}

.scenario-action-div {
  text-align: left;
}

.review-action-body .custom-select,
.review-action-body .MuiInputBase-root {
  width: 300px !important;
  max-width: 300px !important;
}

.scenario-action-div .custom-select .MuiInputBase-input {
  text-align: left;
}

.input-sendTo {
  width: 254px;
  height: 24px;
}

.input-deadline {
  width: 147px !important;
  height: 24px !important;
  border-color: transparent !important;
}

.review-action-datepicker img {
  bottom: 4px !important;
  left: 50px !important;
}

.review-action-datepicker input {
  border-color: transparent !important;
  text-align: left;
  margin-left: 2px;
}

.review-action-datepicker div {
  border: none !important;
}

.review-action-datepicker .dsf-custom-datepicker-open {
  height: 267px !important;
}

.review-action-datepicker fieldset {
  width: 147px !important;
  border: 1px solid #797775 !important;
}

.input-action {
  width: 500px;
  margin-left: -23px;
  font-style: italic;
}

.input-attachments {
  width: 1172px;
  height: 220px;
  max-height: 220px;
  margin-left: 15px;
  background-color: transparent !important;
  /* border: 1px solid #797775; */
  color: #edf1f3 !important;
  resize: none;
  /* overflow-y: scroll; */
}
.input-attachments div {
  font-size: 12px;
  font-weight: 400;
  color: #edf1f3;
}

.review-action-div span {
  white-space: break-spaces !important;
}

.review-action-div .input-sendTo {
  width: 250px !important;
}

.review-action-footer-div button {
  padding: 0px 8px 1px 8px !important;
}

.scenario-action-div input {
  background-color: #353535 !important;
  border: 1px solid #797775;
  color: #edf1f3 !important;
}

.scenario-detail-div div {
  width: 100% !important;
  justify-content: space-evenly !important;
}

.scenario-action-footer-div {
  margin-right: 10px;
}

.scenario-list {
  display: contents !important;
  justify-content: space-between !important;
}

.scenario-list-row {
  height: 48px;
  /* display: flex;
    flex-direction: column !important;
    justify-content: left;
    align-items: flex-start; */
  z-index: 90 !important;
}

.color-dot {
  height: 10px;
  width: 10px;
  /* background-color: red; */
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
  cursor: pointer;
}

ul.nav {
  left: 90%;
  margin-left: 3px;
  position: relative;
  padding-left: 0px;
}

ul.nav li {
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s;
  opacity: 0.5;
  width: 85px;
  height: 24px;
  border: 1px solid;
  border-color: #797775;
  font-size: 12px;
  font-weight: 400;
  color: #797775;
  padding: 1px 12px 0px 12px;
}
ul.actionList-ul {
  height: 160px;
}
ul.actionList-ul li {
  list-style: none;
  cursor: pointer;
  width: 75px;
  height: 24px;
  margin-top: 5px !important;
  font-size: 12px;
  font-weight: 400;
  color: #797775;
  display: contents;
}
ul.review_action {
  left: 0% !important;
  margin-left: 8px;
  position: relative;
  padding-left: 0px;
}

ul.review_action li {
  width: 136px !important;
  text-wrap: nowrap;
}

ul.nav li.active {
  opacity: 1;
  font-size: 12px;
  font-weight: 700;
  color: #edf1f3;
  background: #340d71;
}

.chart_area_div_row {
  margin-right: -35px;
  margin-bottom: 8px;
}
.chart_area_div1 {
  width: 300px !important;
  height: 120px !important;
  background-color: #1f2123;
}
.chart_area_div {
  width: 200px !important;
  height: 120px !important;
}

.span-wrap {
  margin-top: 0px !important;
  flex-wrap: "wrap !important";
}

.scenario_list_parent_div {
  display: flex !important;
  flex-direction: column !important;
  width: 200px !important;
  text-align: initial;
  cursor: pointer;
}

img.dsf-close-scenarioPlanner-img {
  height: 12px;
  width: 12px;
  position: absolute;
  top: 9px;
  right: 29px;
  cursor: pointer;
}

.scenario-upper-div {
  /* height: 180px;
    max-height: 180px; */
  height: 35%;
  max-height: 35%;
  border-top: 1px solid;
  /* border-bottom: 1px solid; */
  /* border-radius: 0%; */
  border-top-color: #797775;
  /* border-bottom-color: transparent; */
  /* margin-bottom: 4px; */
  padding: 0px 10px;
  background: #1e232f;
}

.bw-planner-bottom-div {
  display: flex;
  background-color: #0d1116 !important;
  /* height: 435px; */
  height: 65%;
  margin-bottom: -9px;
  z-index: 100;
  padding: 0px 10px 10px 10px;
}

.scenario-line-chart-parent-div {
  padding: 10px;
  flex-basis: 73%;
  justify-content: flex-start !important;
  display: flex;
  flex-direction: column;
}

.scenario-lock-action-parent-div {
  padding: 10px;
  flex-basis: 27%;
}

.scenario-planner-graph-row {
  margin-bottom: 15px;
  display: flex;
  /* flex-wrap: nowrap !important; */
  margin-right: 0px !important;
  margin-left: 10px !important;
  /* white-space: nowrap; */
  z-index: 2;
  margin-left: -13px !important;
}

.scenario-planner-graph-row div {
  justify-content: space-between !important;
  margin-left: 3px;
}

.scenario-material-drop-down div {
  max-width: 90% !important;
  width: 90% !important;
  text-align: left !important;
}
.scenario-country-dropdown-row div {
  text-align: left !important;
}
.scenario-material-drop-down {
  width: 466px;
}

.scenario-planner-action-btn {
  padding: 0px 6px 0px 6px;
  width: 323px;
  height: 24px;
  text-align: center;
  background-color: #1f2123;
  border: 1px solid #797775;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 5px;
  color: #edf1f3;
}

.scenario-planner-action-btn .scenario-planner-action-btn:disabled {
  color: #a0a0a0;
}

.scenario-requiredAction-div span {
  font-style: italic;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
}

.scenario-requiredAction-div ul {
  text-align: left;
}

.scenario-requiredAction-div {
  max-width: 108%;
  margin-right: 10px;
  /* max-height: 325px; */
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.scenario-requiredAction-parent-div {
  max-height: 325px;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  margin-left: -15px;
}

.scenario-lock-scenario-div {
  position: fixed;
  bottom: 1%;
  align-content: space-between;
}

.lockUnlockModal {
  width: 349px;
  height: 195px;
  border: 0.5px solid;
  border-color: #797775;
  background-color: #1e232f;
  border-radius: 0%;
}

.lock-unlock-div {
  text-align: left;
}

.lock-unlock-footer-btn {
  width: 72px !important;
  height: 24px !important;
  padding: 1px 8px 1px 8px !important;
}

.scenario-modal-content button:hover {
  background-color: #1f2123 !important;
  border-color: #797775 !important;
  box-shadow: none !important;
}
.scenario-modal-content button:disabled {
  background-color: #1f2123 !important;
  border-color: #797775 !important;
  box-shadow: none !important;
  opacity: 0.5;
}

.scenario-modal-content button:focus {
  background-color: #1f2123 !important;
  border-color: #797775 !important;
  box-shadow: none !important;
}

.review-action-modal {
  position: fixed !important;
  width: 1220px !important;
  height: 509px !important;
  background-color: #353535 !important;
  border-color: #797775;
  border-width: 1px;
  border-radius: 0%;
}

.review-action-div img {
  bottom: 1px !important;
  left: 5px !important;
}

.review-action-div .dsf-custom-datepicker-open {
  transform: translate3d(-131px, -5px, 0px);
  height: 277px !important;
  position: relative;
}

/* chart class start*/
.chart-wrapper {
  max-height: 140px;
  width: 100%;
  /* overflow-x: hidden; */
  overflow: scroll;
}

.chart-wrapper::-webkit-scrollbar {
  display: none;
}

.col1::after {
  content: "";
  width: 1px;
  height: 148px;
  background-color: #797775;
  top: 13%;
  right: 0%;
  position: absolute;
}
.scenario_list_parent_div {
  position: relative;
  top: -2%;
}
.cur_pos_graph {
  position: fixed;
  top: 5.6rem;
  left: 11rem;
  cursor: pointer;
  /* top: 14%;
    left: 12%; */
  /* width:200px;
    max-width: 200px; */
}
.cur_pos_graph .test_chart {
  left: unset !important;
  position: absolute !important;
  width: 13rem;
  max-width: 13rem;
  margin-bottom: 10px;
}

.cur_pos_stacked_graph {
  position: absolute;
  top: 5.6rem;
  left: 10rem;
  overflow: scroll;
  height: 130px;
  width: 270px;
  cursor: pointer;
}

.cur_pos_stacked_graph::-webkit-scrollbar {
  display: none;
}

.cur_pos_stacked_graph .test_chart {
  left: unset !important;
  position: absolute !important;
  width: 13rem;
  max-width: 13rem;
  margin-bottom: 10px;
  top: -8%;
}

.test_chart_axis_label {
  left: 4%;
  /* margin-top: 5px; */
  margin-top: 0.7rem;
  line-height: 24px;
}

.test_chart {
  position: relative;
  top: -20%;
  left: -25%;
}

.test_chart_1 {
  position: relative;
  top: -19%;
}

.stacked_test_chart_1 {
  position: relative;
  top: 0%;
  left: -6%;
}

.scenario-upper-div ul.nav {
  z-index: 1;
  left: 102% !important;
  top: -8%;
}

.bw-upload-scenario-div {
  bottom: 24%;
  position: fixed;
  padding: 10px;
}

.scenario_list_parent_div {
  padding: 10px;
}

.test_line_chart_1 {
  width: 870px;
  height: 200px;
  z-index: 1;
}

.test_table_chart_1 {
  width: 870px;
  z-index: 1;
  overflow-x: scroll;
  transform: rotateX(180deg);
}
.test_table_chart_inside_1 {
  transform: rotateX(180deg);
  height: 200px;
}

.test_table_chart_1::-webkit-scrollbar {
  width: 5px; /* Set the width of the scrollbar */
  /* Set the background color of the scrollbar */
  background: #000000;
  position: absolute;
  left: 0; /* Position the scrollbar on the right of the element*/
}
.test_table_chart_1::-webkit-scrollbar-thumb {
  background: #b7d3ff;
}
::-webkit-scrollbar-track {
  background: #000000;
  border-radius: 5px;
}

.graph-header-selection-div {
  height: 20px !important;
}
/* chart class end*/

.fileUpload-div button {
  padding: 0px 3px 1px 3px;
  position: relative;
  left: 86%;
  border-radius: 0px;
  height: 24px;
  width: 73px !important;
  top: -48%;
}
.no-data-div {
  position: relative;
  top: 50%;
}
.no-selection-div {
  left: 40%;
  position: relative;
}
.actionList {
  max-height: 200px !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
.actionListRow {
  position: relative;
}
.actionListRow .dsf-checkinnoflex-date-edit-img {
  left: 113px !important;
}
.actionListRow .dsf-custom-datepicker-open {
  transform: translate3d(-192px, 7px, 0px);
  position: absolute;
  top: 0px;
  left: 54px;
  height: 290px !important;
  width: 300px !important;
  border: 0.5px solid #c5c5c5;
  z-index: 2;
}
.input-attachments .E2E-preview-div {
  padding: 8px 1px 8px 8px;
}
.picker-Position img.dsf-checkinnoflex-date-edit-img {
  bottom: 24px !important;
}

.bwi-scenario-goods-inactive-div {
  color: #cfcfcf !important;
  font-weight: 400;
}

.bwi-scenario-goods-active-div {
  color: #edf1f3 !important;
  font-weight: 600;
}

/* .scenario-action-div .MuiOutlinedInput-input {
    height: 22px !important;
    padding: 0px !important;
}

.Scenario-planner-header-selection-graph-row{
    display:flex;
    justify-content: space-between;
}

.row-margin-4{
    margin-right: 4rem;
} */
.data-not-found {
  transform: translate(-28px, 100px);
}

.material-selection-row {
  display: flex;
  margin-top: 14px;
  /* margin-left:0px; */
}
.material-selection-row .bwi-modal-Scenario_nav {
  margin-left: 8px;
  left: 0%;
  position: relative;
  padding-left: 0px;
  padding-bottom: 15px;
  padding-top: 16px;
}

.material-selection-row .nav li {
  width: 123px;
  padding: 1px 2px 0px 2px;
}

.scenario-RPM-dropdown {
  margin-left: 3rem;
}

.scenario-RPM-dropdown .label {
  margin-bottom: 4px;
}

.scenario-rmpm-dropdown-row
  .dsf-delistProj-autocomplete
  .input-container
  input {
  width: 114px;
}

.scenario-rmpm-dropdown-row
  .dsf-delistProj-autocomplete
  .custom-dropdown
  .options-containerirmpmList {
  position: absolute;
  top: 26px;
  background-color: #1e232f;
  border: 1px solid #797775;
  z-index: 2;
  min-width: 265px;
  min-height: 25px;
}

.scenario-rmpm-dropdown-row
  .dsf-delistProj-autocomplete
  .options-containerirmpmList
  .options-list
  .MuiCheckbox-root {
  color: rgb(237, 241, 243);
}

.options-containerirmpmList .nested-parent .dropdown-parent-container {
  padding-left: 10px;
}

.options-containerirmpmList .nested-parent .nested-children-list {
  padding-left: 0px;
}

.legend-div {
  position: absolute;

  z-index: 2;
  left: 55px;
  text-anchor: start;
  font-family: Arial;
  font-size: 8px;
  stroke: none;
  stroke-width: 0;
  fill: rgb(237, 241, 243);
}

.legend-div-Value {
  bottom: 292px;
}

.legend-div-Volume {
  bottom: 44%;
}

.legend-span {
  height: 17px;
  width: 1px;
  background-color: #ec6157;
  transform: rotate(90deg);
  position: absolute;
  top: -4px;
  left: -13px;
}

/* .fullscreen-modal .dsf-header-div .dsf-header {
  background: #353535 !important;
} */
