.dsf-dialog-InventoryCM {
    max-width: 1236px !important;
    max-height: 450px !important;
    width: 1236px !important;
    height: 450px !important;
}

.dsf-col-textBox-div2 {
    padding: 0;
}

.dsf-col-textBox-div2 input,
.dsf-col-textBox-div1 input {
    font-size: 12px;
    font-weight: 400;
}

.dsf-col-textBox-div1 input {
    width: 192px;
}

.dsf-dialog-InventoryCM .bw-modal-uniqueRPM-body .dsf-uniqueRPM-mail-row2 .dsf-col-textBox-div2 input {
    width: 630px;
    margin-right: 10px;
}

.dsf-col-textBox-div3 {
    align-content: flex-end;
    display: contents;
}

.dsf-uniqueRPM-row {
    margin-left: -5px;
    margin-right: -5px;
    flex-wrap: inherit;
}

.dsf-uniqueRPM-row :nth-child(1) {
    align-items: start !important;
}

.dsf-dialog-InventoryCM .dsf-uniqueRPM-mail-row2 {
    margin-bottom: 20px !important;
}

.dsf-uniqueRPM-row .flex-fill {
    padding: 0 5px;
    align-items: flex-start !important;
    text-align: left;
    width: 10%;
}

.buttonWidth {
    width: 108px !important;
    white-space: nowrap;
}

.bw-ICM-text {
    text-align: left;
    color: #EDF1F3;
}

.bw-ICM-fileUpload-row {
    text-align: left;
    padding-top: 5px;
    padding-bottom: 20px;
}

.dsf-inv-row-label{
    word-break: break-word;
}

.dsf-delist-form-upload-section {
    width: 99.5% !important;
}

.bw-ICM-fileUpload-row .dsf-delist-form-upload-browse {
    width: 100% !important;
}

.dsf-uniqueRPM-mail-row1 ul.review_action {
    margin-bottom: 5px;
}

.dsf-uniqueRPM-mail-row2 .buttonWidth {
    width: 228px !important;
}

.dsf-col-textMsg{
    flex-direction: column;
}

.dsf-dialog-InventoryCM .dsf-uniqueRPM-mail-row1 {
    margin-left: -7px;
}

.dsf-dialog-InventoryCM .bw-modal-footer {
    padding-top: 10px;
    justify-content: flex-end;
}

.scroll-view-div{
    max-height: 260px;
    overflow-y: auto;
}