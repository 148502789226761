.bwi-delistBOMfg-modal {
  position: relative;
}

.bwi-delistBOMfg-table {
  position: absolute;
  width: 100%;
  height: 268px;
}

.bwi-delistBOMfg-table-body {
  padding: 5px 10px 5px 0px;
}

.bwi-delistBOMfg-table-header {
  margin-left: 0px;
  margin-right: 0px;
  padding-bottom: 5px;
  min-height: 24px;
}

.bwi-delistBOMfg-table-data {
  max-height: 210px;
  min-height: 210px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 45px;
}
.bwi-delistBOM-list-left-container .bwi-delistBOMfg-table {
  overflow: hidden;
}

.bwi-delistBOMfg-row-not-selected,
.bwi-delistBOMfg-row-not-selected span {
  color: rgba(255, 255, 255, 0.7);
}

.bwi-delistBOMfg-table-col {
  padding: 0px 5px;
}

.bwi-delistBOMfg-table-col .bwi-checkbox {
  padding: 0px !important;
}

.dsf-delistBOMfg-body-input-textarea {
  background-color: transparent;
  border: none;
  width: 100%;
  height: 268px;
  text-align: left;
  color: #edf1f3;
  padding: 10px;
}

.bw-delistBOMfg-btn {
  align-items: center;
  background: #8fcaeb;
  color: #292929;
  display: flex;
  font-family: Unilever Shilling;
  font-size: 12px;
  font-weight: 600;
  height: 24px;
  min-width: 64px;
  justify-content: center;
  border-radius: 0;
}

.bw-delistBOMfg-btn-position {
  position: absolute;
  top: 230px;
  right: 20px;
}
.bwi-delistBOMfg-btn-position {
  position: absolute;
  bottom: 5px;
}
.bw-delistBOMfg-btn:disabled,
.bw-delistBOMfg-btn:focus,
.bw-delistBOMfg-btn:hover {
  background: #8fcaeb;
  color: #292929;
  box-shadow: none;
}

.bwi-delisBOM-FGDate-row {
  position: absolute;
  top: 220px;
  right: 20px;
  /* align-items: center; */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  background: #1e232f;
  margin: 0px -20px;
}
.bwi-delisbom-date {
  pointer-events: none;
  opacity: 0.5;
}
.bwi-delisbom-reference {
  width: 10%;
  margin-left: 15%;
}
.bwi-delisbom-reference-disabled {
  pointer-events: none;
  opacity: 0.5;
  width: 10%;
  margin-left: 15%;
}
.bwi-delisbom-reference-input-text {
  background-color: transparent;
  color: #fff;
  border: 0.5px solid #797775;
  text-align: center;
  width: 128px;
  height: 24px;
}

.bwi-delisBOM-FGDate-row .dsf-custom-datepicker-parent-div {
  top: 50%;
  right: 66%;
}
.bwi-delistBOMfg-btn-position {
  position: absolute;
  bottom: 5px;
}
