.dsf-dialog-UniqueRPMModal {
  max-width: 1230px !important;
  max-height: 520px !important;
  width: 1230px !important;
  height: 520px !important;
}

.bw-modal-uniqueRPM-body {
  width: 100%;
  padding-bottom: 0px;
}

.dsf-modal-uniqueRPM-body {
  padding-top: 2px;
}

.dsf-modal-uniqueRPM-body .scroll-view-div {
  padding-left: 12px !important;
}

.dsf-rpm-rd-approver-span {
  font-family: "Unilever Shilling";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #f1a33a;
  margin-left: 15px;
  .dsf-dialog-UniqueRPMModal {
    max-width: 1230px !important;
    max-height: 500px !important;
    width: 1230px !important;
    height: 500px !important;
  }

  .bw-modal-uniqueRPM-body {
    padding-bottom: 0px;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    -ms-overflow-style: inherit !important;
    max-width: 80%;
  }

  .dsf-rpm-rd-approver-span {
    font-family: "Unilever Shilling";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #f1a33a;
    margin-left: 15px;
    margin-top: 10px;
  }

  .dsf-uniqueRPM-row {
    padding-bottom: 10px;
    /* justify-content: space-between; */
  }

  .scroll-view-div .dsf-uniqueRPM-row {
    margin-left: -15px;
    display: flex !important;
    align-items: center !important;
  }

  .dsf-uniqueRPM-row span {
    /* width: fit-content; */
    /* max-width: max-content; */
    /* width: 100px; */
    white-space: break-spaces !important;
  }
  .dsf-uniqueRPM-row :nth-child(1) {
    margin-left: 0px;
    display: flex;
    align-items: center;
  }

  .checkboxDiv {
    margin-left: 14px !important;
  }

  .dsf-uniqueRPM-mail-row2 {
    padding-bottom: 18px;
  }

  .dsf-uniqueRPM-row-mail-div div {
    padding-bottom: 10px;
    margin-left: 0px;
    display: flex;
    align-items: center;
  }

  .dsf-uniqueRPM-mail-row1 {
    margin-top: 10px !important;
  }

  .dsf-uniqueRPM-mail-row2 {
    margin-bottom: 30px !important;
  }

  .dsf-uniqueRPM-mail-empty {
    padding: 15px;
  }

  .dsf-RPM-textField:focus {
    background-color: #353535 !important;
    border: 1px solid #797775 !important;
    box-shadow: none !important;
    border-radius: 0%;
  }
  margin-top: 10px;
}

.dsf-uniqueRPM-row {
  padding-bottom: 10px;
  width: 94%;
  /* justify-content: space-between; */
}

.checkbox-info-div {
  width: 100% !important;
  justify-content: end;
  padding-top: 15px;
  opacity: 0.5;
}

.dsf-uniqueRPM-row :nth-child(1) {
  margin-left: 0px;
  display: flex;
  align-items: center;
}

.dsf-uniqueRPM-row .header-checkbox {
  margin-left: 0px !important;
  padding-left: 2px;
  padding-right: 6px;
}

.dsf-uniqueRPM-mail-row2 {
  padding-bottom: 18px;
}

.dsf-uniqueRPM-row-mail-div div {
  padding-bottom: 10px;
  margin-left: 0px;
  display: flex;
  align-items: center;
}

.dsf-uniqueRPM-row .dsf-uniqueRPM-checkbox {
  pointer-events: unset !important;
  padding: unset !important;
  color: #edf1f3 !important;
  padding-right: 5px !important;
  padding-top: 1px !important;
}

.dsf-uniqueRPM-row .dsf-uniqueRPM-checkbox .MuiSvgIcon-root,
.dsf-skipInventory-div .MuiSvgIcon-root {
  height: 17px !important;
}

.dsf-dialog-RNDModal-PIUser .scroll-view-div {
  max-height: 210px;
  min-height: 120px;
  padding-top: 15px;
  padding-left: 3px;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.dsf-dialog-RNDModal-PIUser .dsf-rpm-dropdown-filter-open {
  padding-top: 5px;
}

.bw-uniqueRPM-span {
  white-space: nowrap !important;
  text-align: left !important;
}

.dsf-col-textBox-rpm-div1 {
  display: flex;
  flex-direction: column;
  flex: 25%;
  justify-content: left;
}

.dsf-col-textBox-rpm-header-div1 {
  display: flex;
  flex: 23%;
  justify-content: left;
}

/* .form-check-input {
  accent-color: #f1a33a;
  background-color: #353535 !important;
} */

.dsf-col-textBox-rpm-header-div2 {
  display: flex;
  flex: 73%;
  justify-content: left;
}

.dsf-uniqueRPM-mail-row2 .dsf-col-textBox-rpm-div1 {
  flex-direction: column;
}

.dsf-uniqueRPM-header-row .col-1,
.dsf-uniqueRPM-header-row .col-2,
.dsf-rpm-country-selection {
  display: flex;
  /* justify-content: space-between; */
}

.dsf-col-textBox-rpm-div2 {
  display: flex;
  flex: 55%;
  justify-content: left;
}

.dsf-col-textBox-rpm-div3 {
  display: flex;
  flex: 20%;
  justify-content: left;
}

.dsf-col-textBox-rpm-div1 input,
.dsf-col-textBox-rpm-div2 input {
  background-color: #353535 !important;
  border: 1px solid #797775;
  height: 24px;
  color: #edf1f3 !important;
}

.dsf-col-textBox-rpm-div1 input {
  width: 192px;
}

.dsf-col-textBox-rpm-div2 input {
  width: 600px;
}

.dsf-col-textBox-rpm-header-div1 label,
.dsf-col-textBox-rpm-header-div2 label {
  font-family: "Unilever Shilling";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 11px;
  color: #8a8d8c;
}

.dsf-uniqueRPM-mail-row1 {
  margin-top: 15px !important;
}

.dsf-uniqueRPM-mail-row2 {
  margin-bottom: 40px !important;
}

.dsf-RPM-textField:focus {
  background-color: #353535 !important;
  border: 1px solid #797775 !important;
  box-shadow: none !important;
  border-radius: 0%;
}
.uniqueRPM-list .bw-uniqueRPM-span {
  white-space: break-spaces !important;
}

.dsf-rnd-legend {
  display: flex;
  justify-content: center;
  align-items: center;
}

.legend-square {
  width: 12px;
  height: 12px;
  margin-right: 5px;
  border-radius: 2px;
  display: inline-block;
}

.legend-label-rpm {
  margin-right: 23px;
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 1px;
}

.dsf-rndsignoff-footer {
  justify-content: space-between !important;
}

.dsf-uniqueRPM-row .not-selected {
  color: #f1a33a;
}

.dsf-uniqueRPM-row .unique-selected {
  color: #61d4c4;
}

.dsf-uniqueRPM-row .non-unique-selected {
  color: #ec6157;
}

.dsf-rpm-assign-btn {
  color: #292929;
  font-family: "Unilever Shilling";
  padding: 5px 25px;
  background: #c7c7c7;
  font-size: 12px;
  font-weight: 600;
  width: 82px;
  height: 25px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.dsf-rpm-assign-btn:disabled {
  color: #a0a0a0;
  border-color: #6c757d;
  opacity: 0.65;
}

.dsf-dialog-RNDModal-PIUser {
  max-width: 1245px !important;
  width: 1245px !important;
}

.dsf-col-rpm-material-description,
.dsf-col-rpm-plant-name {
  padding-left: 30px;
}

.dsf-col-rpm-material {
  margin-left: 10px;
}

.dsf-materialicon{
  display: flex;
  /* align-items: center; */
}

.dsf-col-rpm-material-type span {
  white-space: unset !important;
  word-break: auto-phrase;
}

.dsf-uniqueRPM-header-row span {
  padding-right: 5px;
}

.dsf-uniquerpm-dropdown-filter {
  position: absolute;
  z-index: 1;
  background-color: #353535;
  color: #edf1f3;
  background: #353535;
  border: 1px solid #797775;
  border-radius: 0px;
  left: 18px;
  top: 20px;
  z-index: 2;
}

.dsf-uniquerpm-dropdown-filter .options-container {
  display: flex;
  flex-direction: column;
}

.dsf-uniquerpm-dropdown-filter .dsf-delistingProj-search {
  padding-top: 6px;
}

.dsf-uniquerpm-dropdown-filter .dsf-delistingProj-search-icon {
  padding-left: 10px;
  padding-right: 5px;
}

.dsf-uniquerpm-dropdown-filter .options-list {
  padding: 5px 0px;
  line-height: 1.7;
}

.dsf-uniquerpm-dropdown-filter .MuiCheckbox-root,
.dsf-uniquerpm-dropdown-filter .Mui-checked {
  padding: 0px 9px;
  color: #edf1f3 !important;
}

.dsf-uniquerpm-dropdown-filter .options-row {
  padding-bottom: 5px;
  width: unset;
}

.dsf-rpm-messagebox {
  width: unset !important;
  min-width: 349px !important;
}

.dsf-rpm-messagebox-modal {
  height: 232px !important;
}

.dsf-rpm-messagebox-modal .bw-modal-messagebox-msg {
  padding: 5px 0px;
  text-align: left;
  line-height: 1.7;
}

.dsf-rnd-piuser-row {
  margin-left: -30px !important;
}

.uniquerpm-modal-header-div {
  line-height: 22.3px;
}

.dsf-rpm-filter-arrow {
  padding-top: 1px;
  padding-right: 1px;
}

.dsf-uniquerpm-recipient-dropdown {
  left: unset;
  right: 17px;
}

.dsf-uniquerpm-skuname-dropdown .option-row,
.dsf-uniquerpm-recipient-dropdown .option-row {
  height: auto;
  word-break: break-all;
  min-height: 30px;
}

.dsf-uniqueRPM-header-filter-col {
  z-index: 1;
}

.bw-modal-RNDModal-PIUser-body {
  padding-left: 20px;
}

.dsf-rpm-dropdown-close {
  border: 0px;
}

.dsf-uniqueRPM-table {
  border: 1px solid;
}

.dsf-skipInventory-div {
  text-align: left;
  margin-top: 12px;
}

.dsf-skipInventory-div .header-checkbox {
  padding-left: 12px;
}

.dsf-uniqueRPM-mail-data {
  margin-top: 0px !important;
  height: 50px;
}

.dsf-uniqueRPM-recipient-enable {
  padding-bottom: 0px !important;
}

.dsf-uniqueRPM-legend-checkbox {
  margin-top: 0px !important;
}

.bw-uniqueRPM-legend-span {
  white-space: nowrap !important;
  text-align: left !important;
  padding-top: 1px;
}

.dsf-rpm-info-icon{
  width: 10px;
  height: 10px;
  margin-right: 4px;
  margin-top: 4px;
  cursor: pointer;
}