.dsf-dialog-E2EPlannerModal {
  width: 843px !important;
  max-width: inherit !important;
  max-height: inherit !important;
  min-height: inherit !important;
}

.bw-modal-E2EPlanner-body {
  padding-bottom: 0px;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  -ms-overflow-style: inherit !important;
}

/* row styles */
.dsf-E2EPlanner-mail-row1 {
  margin-top: 10px !important;
}

/* lable styles */
.form-label {
  display: block;
  font-family: "Unilever Shilling";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 11px;
  color:#CFCFCF;
  text-align: left;
}

.form-label-constraint {
  display: flex;
}

.form-label-constraint .bw-info-tool-tip-div .info-tooltip-div {
  position: absolute;
  left: 260px;
  top: -30px;
}

.form-label-constraint
  .bw-info-tool-tip-div
  .info-tooltip-div
  .bw-help-tool-tip-text {
  position: relative;
  margin-top: 0px !important;
  font-size: 10px;
  font-weight: 400;
  font-family: "Unilever Shilling";
  color: #edf1f3;
  line-height: 13px;
}

/* input styles */

.dsf-E2EPlanner-col input,
.E2E-preview-div {
  display: block;
  background-color: transparent !important;
  border: 1px solid #797775;
  color: #edf1f3 !important;
  font-family: "Unilever Shilling";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
}
.dsf-E2EPlanner-col input {
  height: 24px;
}

.dsf-RPM-textField:focus {
  background-color: #1E232F !important;
  border: 1px solid #797775 !important;
  box-shadow: none !important;
  border-radius: 0%;
}

.bw-modal-padding {
  padding-left: 25px;
  padding-right: 25px;
}

.row-spacing-5 {
  margin-left: -5px;
  margin-right: -5px;
}
/* .row-spacing-right {
  padding-right: 20px;
} */
.row-spacing-5 > .col {
  padding-left: 5px;
  padding-right: 4px;
}
.dsf-E2EPlanner-col input {
  width: 100%;
  display: block;
  padding: 0px 5px;
}

.dsf-E2EPlanner-col {
  width: 147px;
  flex-basis: auto;
}

.dsf-E2EPlanner-col.dsf-E2EPlanner-col-xl {
  width: 305px;
  padding-right: 32px;
}
.dsf-E2EPlanner-col.dsf-E2EPlanner-col-xl2 {
  width: 305px;
}
.dsf-E2EPlanner-col.dsf-E2EPlanner-col-select-xl {
  width: 304px;
}
.dsf-E2EPlanner-col.dsf-E2EPlanner-col-xxl {
  width: 464px;
}
/* DatePicker*/

.dsf-E2EPlanner-datepicker-wrap-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.dsf-E2EPlanner-datepicker-wrap-field .MuiFormControl-root,
.dsf-E2EPlanner-datepicker-wrap-field .MuiFormControl-root > div {
  width: 100%;
  height: 24px;
  border: none !important;
  padding: 0;
  margin: 0;
  max-width: 100%;
}

.dsf-E2EPlanner-datepicker-wrap-field .MuiInputBase-input {
  padding: 0px 5px !important;
  height: 22px;
}

.dsf-E2EPlanner-datepicker-wrap-field .dsf-checkinnoflex-date-edit-img {
  left: auto;
  right: -25px;
  bottom: 2px;
  position: absolute;
  flex-shrink: 0;
  margin-left: 15px;
  width: 18px;
  height: 18px;
}
.dsf-E2EPlanner-datepicker .dsf-custom-datepicker-open {
  position: absolute;
  left: -120px;
  top: 80px;
}

.E2E-preview-div {
  height: 223px;
  max-height: 223px;
  padding: 8px 0px 8px 8px;
  overflow: auto;

}

.E2E-preview-div::-webkit-scrollbar{
  height:5px;
}

.E2E-preview-text1 {
  font-family: "Unilever Shilling";
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #edf1f3;
}

.E2E-preview-text2 {
  font-family: "Unilever Shilling";
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #8a8d8c;
  padding-right: 2px;
}

.E2E-preview-text3 {
  font-family: "Unilever Shilling";
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
}

.E2E-preview-project-info {
  margin: 16px 0px;
}

.dsf-E2EPlanner-col {
  display: block;
  text-align: left;
}
.dsf-E2EPlanner-dropdown .MuiFormControl-root {
  display: flex;
}
.dsf-E2EPlanner-dropdown .MuiInputBase-root {
  max-width: 100%;
  width: 100%;
}

.dsf-E2EPlanner-dropdown .MuiInputBase-root .MuiSelect-select {
  text-align: left;
}

/* .E2E-preview-project-Attachments ul{
   padding-left: 0px;
} */

/* .Project-Graph div{
    position: relative;
} */
.Project-Graph {
  height: 200px !important;
}
.Project-Graph ul {
  height: 180px !important;
  margin-bottom: 10px;
  left: -9% !important;
  position: relative !important;
  width: 262px;
}
.Project-Graph .hl {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
.Project-Graph ul span {
  background-color: #ef5891 !important;
}

.Project-Graph .MuiTimelineItem-root .MuiTimelineContent-root {
  font-family: "Unilever Shilling";
  font-weight: 400;
  font-size: 8px;
  line-height: 11px;
}

.Project-Graph .MuiTimelineItem-root .DateColor {
  color: #cfcfcf;
}

.Project-Graph
  .MuiTimelineItem-root:nth-of-type(even)
  .MuiTimelineContent-root {
  /* top: -235%; */
  bottom: 44%;
  transform: rotate(-270deg);
  left: 6%;
  /* bottom: -8%;*/
  position: relative;
  text-align: center;
}

.Project-Graph
  .MuiTimelineItem-root:nth-of-type(odd)
  .MuiTimelineContent-root {
  transform: rotate(-270deg);
  position: relative;
  align-items: center;
  top: -17px;
  left: -44px;
  text-align: center;
}

.Project-Graph
  .MuiTimelineItem-root .ItemColor{
    color:#767373;
  }

.timeline {
  transform: rotate(-90deg);
}

.hl {
  border-top: 5px solid;
  width: 74px;
  color: #b7d3ff;
}

.hlright {
  position: relative;
}

.hlright hr {
  position: absolute;
  border-top: 5px solid;
  width: 10px;
  color: #b7d3ff;
  right: 0px;
}

.hlleft {
  position: relative;
}

.hlleft hr {
  position: absolute;
  border-top: 5px solid;
  width: 10px;
  color: #b7d3ff;
}

/* .MuiTimelineConnector-root{
    color: #EF5891;
  } */

.bw-modal-button {
  display: flex;
  color: #292929;
  font-family: "Unilever Shilling";
  padding: 5px 25px;
  background: #c7c7c7;
  font-size: 12px;
  font-weight: 600;
  width: 82px;
  height: 24px;
  justify-content: center;
  align-items: center;
}
