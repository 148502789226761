.ag-theme-alpine {
  --ag-foreground-color: #cfcfcf;
  --ag-background-color: #1e232f;
  --ag-header-foreground-color: #1e232f;
  --ag-header-background-color: #1e232f;
  --ag-row-border-color: #1e232f;
  --ag-odd-row-background-color: rgb(0, 0, 0, 0.03);
  --ag-header-column-resize-handle-color: #edf1f3;
  /* --ag-header-cell-hover-background-color:#340D71; */
  /* --ag-header-cell-moving-background-color:#340D71; */
  --ag-font-size: 12px;
  --ag-row-hover-color: #340d71;
  --ag-font-weight: 400;
  --ag-font-family: "Unilever Shilling";
  --ag-borders: none;
  --ag-borders-input: solid 1px;
  --ag-input-border-color: #edf1f3;
}
.ag-theme-alpine .ag-menu {
  background-color: #1e232f;
}

input[class^="ag-"] {
  background: transparent !important;
  color: #edf1f3 !important;
}

input[class^="ag-"]:not([type]):disabled,
input[class^="ag-"][type="text"]:disabled,
input[class^="ag-"][type="number"]:disabled,
input[class^="ag-"][type="tel"]:disabled,
input[class^="ag-"][type="date"]:disabled,
input[class^="ag-"][type="datetime-local"]:disabled,
textarea[class^="ag-"]:disabled {
  background: transparent !important;
  color: #edf1f3 !important;
}
