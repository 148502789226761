.bwi-modal-emailE2E {
  width: 100%;
}

.bwi-modal-emailE2E-header-title-div {
  height: 40px;
}

.bwi-modal-emailE2E-body {
  padding-right: 30px;
  height: 747px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bwi-modal-emailE2E-body .row-spacing-mail-attachment {
  padding-bottom: 10px;
}

.bwi-emailE2E-col input {
  width: 100%;
  display: block;
  padding: 0px 5px;
}

.bwi-emailE2E-col {
  width: 147px;
  flex-basis: auto;
  display: block;
  text-align: left;
}

.bwi-emailE2E-col.bwi-emailE2E-col-xl {
  width: 305px;
  /* padding-right: 32px; */
}

.bwi-emailE2E-col.bwi-emailE2E-col-xl2 {
  width: 226px;
}

.bwi-emailE2E-col.bwi-emailE2E-col-select-xl {
  width: 304px;
}

.bwi-emailE2E-col.bwi-emailE2E-col-xxl {
  width: 610px;
}

.form-label {
  display: block;
  font-family: "Unilever Shilling";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 11px;
  color: #cfcfcf;
  text-align: left;
}

.bwi-emailE2E-textField:focus {
  background-color: #1e232f !important;
  border: 1px solid #030303 !important;
  box-shadow: none !important;
  border-radius: 0%;
}

.bwi-emailE2E-col input,
.bwi-E2E-preview-div {
  display: block;
  background-color: #1e232f !important;
  border: 1px solid #797775;
  color: #edf1f3 !important;
  font-family: "Unilever Shilling";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
}

.bwi-emailE2E-col input {
  height: 24px;
}

.row-spacing-5 {
  margin-left: -5px;
  margin-right: -5px;
  margin-top: 10px;
}

.row-spacing-5 > .col {
  padding-left: 5px;
  padding-right: 4px;
}

.bwi-emailE2E-datepicker-wrap-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.bwi-emailE2E-datepicker-wrap-field .MuiFormControl-root,
.bwi-emailE2E-datepicker-wrap-field .MuiFormControl-root > div {
  width: 100%;
  height: 24px;
  border: none !important;
  padding: 0;
  margin: 0;
  max-width: 100%;
}

.bwi-emailE2E-datepicker-wrap-field .MuiInputBase-input {
  padding: 0px 5px !important;
  height: 22px;
}

.bwi-emailE2E-datepicker-wrap-field .dsf-checkinnoflex-date-edit-img {
  left: auto;
  right: -25px;
  bottom: 2px;
  position: absolute;
  flex-shrink: 0;
  margin-left: 15px;
  width: 18px;
  height: 18px;
}

.bwi-emailE2E-datepicker-wrap-field .dsf-custom-datepicker-parent-div {
  position: absolute;
  left: -179px;
  top: 81px;
}

.form-label-constraint {
  display: flex;
}

.form-label-constraint .bw-info-tool-tip-div .info-tooltip-div {
  position: absolute;
  left: 260px;
  top: -30px;
}

.form-label-constraint
  .bw-info-tool-tip-div
  .info-tooltip-div
  .bw-help-tool-tip-text {
  position: relative;
  margin-top: 0px !important;
  font-size: 10px;
  font-weight: 400;
  font-family: "Unilever Shilling";
  color: #edf1f3;
  line-height: 13px;
}

.bwi-E2E-preview-div {
  min-height: 223px;
  /* max-height: 223px; */
  padding: 8px 0px 8px 8px;
  /* overflow-y: auto;
  overflow-x: hidden; */
}

.bwi-E2E-preview-div::-webkit-scrollbar {
  height: 5px;
}

.bwi-E2E-preview-text1 {
  font-family: "Unilever Shilling";
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #edf1f3;
}

.bwi-E2E-preview-text2 {
  font-family: "Unilever Shilling";
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #8a8d8c;
  padding-right: 2px;
}

.bwi-E2E-preview-text3 {
  font-family: "Unilever Shilling";
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
}

.bwi-E2E-preview-project-info {
  margin: 16px 0px;
}

.bwi-modal-emailE2E-footer {
  padding: 20px 24px 20px 0px;
  display: flex;
  justify-content: space-between;
}
