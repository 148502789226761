.snapshot-modal-content {
    background-color: #353535;
    color: #EDF1F3;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    position: fixed;
    /* padding: 10px; */
    /* top: 45px; */
    left: 0px;
    right: 0px;
    bottom: 0px;
    /* border-top: 1px solid;
    border-bottom: 1px solid;
    border-radius: 0%;
    border-top-color: #797775;
    border-bottom-color: #797775; */
    /* display: flex;
    flex-direction: row; */
}
/* .snapshot-modal-content .dsf-header{
    height: 38px !important;
} */
.graph-col{
    width:fit-content;
    /* max-width: 73%; */
    z-index: 1;
    /* width: 60%; */
    /* flex-basis: 65%; */
}

.snapshot-modal-content .dsf-header-title-div {
    margin-left: 9px;
}

.snapshot-details-col{
    width:fit-content;
    /* max-width: 26%; */
    z-index: 2;
    padding-right: 0;
    position: relative;
    /* padding-left: 0; */
    /* width: 30%; */

    /* flex-basis: 35%; */
}

.line1::before, .line2::before, .line3::before, .line4::before{
    content: "";
    width: 1px;
    /* height: 15px; */
    background-color: #797775;
    /* top: -9%; */
    right: 49%;
    position: absolute;
    z-index: 1;
}

/* .snapshot-details-parent-div::before{
    content: "";
    width: 1px;
    height: 150px;
    background-color: #797775;
    top: -9%;
    right: 49%;
    position: absolute;
} */

.rnd-list{
    max-height: 90px;
    overflow:auto;
}

.rnd-list::-webkit-scrollbar{
    display: none;
}

.line1::before{
    height: 10px;
    top: -4%;
}

.line2::before{
    height: 23px;
    top: -29%;
}

.line3::before{
    height: 20px;
    top: -18%;
}
.line4::before{
    height: 41px;
    top: -69%;
}

.icon-parent-div{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid  #353535;
    display: flex;
    justify-content: center;
    align-items: center;
    /* z-index: auto; */
}

.icon-parent-div img{
    width: 12px;
    height: 12px;
}
.country-details-col{
    /* z-index: 2; */
    /* width: 10%; */
    width:fit-content;
    /* padding-right: 0;*/
    padding-left: 0;
}

.bw-snapshot-chart {
    /* height: 220vh; */
    /* height: 70%; */
    height: 65vh;
    /* width: 100%; */
    /* margin-left: 10px; */
    /* z-index: 1; */
    background-color: #0D1116;
    padding: 0px 10px;
}

.bw-snapshot-actionTracker {
    /* height: 140vh; */
    height: 35vh;
    /* height:30%; */
    /* width: 100%; */
    /* margin-left: 10px; */
    /* z-index: 2; */
    background-color: #1E232F;
    padding: 5px 10px 10px 10px;
}

.bw-snapshot-chart .col {
    padding: 6px;
}

.chart-info-div {
    justify-content: flex-end;
    /* margin-right: 90px; */
}

.chart-info-div div {
    display: flex;
    flex-direction: column;
    margin-right: 24px;
    z-index: 2;
}

.snapshot-chart-parent-row {
    margin-left: 10px !important;
    position: relative;
    z-index: 3;
}

.bw-snapshot-close-img {
    left: 70% !important;
    position: absolute !important;
}

.combo_chart_div {
    /* height:50vh; */
    height: 94%;
    max-height: 92%;
    /* width: 150vh; */
    z-index: 2;
    margin-top: -55px;
    /* overflow: scroll; */
}

.snapshot-details-parent-div {
    text-align: left;
    position: relative;
    top: 8%;
    width: 85%;
    min-width: 85%;
}

.detail-row {
    /* display: flex;
    justify-content: center; */
    align-items: center;
    margin-top: -17px;
    height: 20px;
}

.details-row p {
    /* display: flex;
    justify-content: center; */
   font-size: 10px;
   font-weight: 400;
   color: #EDF1F3;
}

.buttonWidth{
    width:108px !important;
    white-space: nowrap;
    color: #292929;
    font-family: "Unilever Shilling";
    padding: 5px 25px;
    background: #c7c7c7;
    border-radius: 0px;
    font-size: 12px;
    font-weight: 600;
    height: 24px;
    justify-content: center;
    align-items: center;
    display: flex;
}

/* .snapshot-details-col .row {
    /* display: flex; */
    /* align-items: center !important; */
    /* margin-top: 5px; */
/* } */

.snapshot-details-col {
    display: flex;
    align-content: space-around;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.bw-snapshot-actionTracker-Heading{
    float:"left";
}
.snapshot-details-col .dsf-close-checkinnoflex-img{
    /* margin-left: -13px !important; */
   position: fixed  !important; 
   right: 0% !important;
   top: 11%;
   left: 95% !important;
   cursor: pointer;
}