.ag-theme-quartz {
  --ag-foreground-color: #cfcfcf;
  --ag-background-color: #1e232f;

  --ag-odd-row-background-color: #2a3548;

  --ag-row-border-color: #0c0e10;
  --ag-header-cell-hover-background-color: #2a3548;
  --ag-header-cell-moving-background-color: #2a3548;
  --ag-font-size: 12px;
  --ag-row-hover-color: #8fcaeb;
  --ag-font-weight: 400;
  --ag-font-family: "Unilever Shilling";
}

.ag-theme-quartz .bwi-gird-cell {
  text-align: left;
  border-left: 1px solid #0c0e10;
  border-right: 1px solid #0c0e10;
}

.ag-theme-quartz .ag-header-cell {
  padding: 0 8px;
  border-left: 1px solid #0c0e10;
  border-right: 1px solid #0c0e10;
}

.ag-theme-quartz .ag-row-hover {
  color: #2a3548;
}

.ag-theme-quartz .ag-center-cols-viewport {
  overflow-x: hidden;
}
