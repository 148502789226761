.bwi-modal-unique-rpm {
  width: 98%;
}

.bwi-modal-unique-rpm-header {
  display: flex;
  justify-content: space-between;
  width: 100% !important;
  height: 90px !important;
}

.bwi-modal-uniqueRPM_nav {
  padding-top: 20px;
}

.bwi-modal-unique-rpm-body {
  padding-left: 25px;
}

.bwi-unique-rpm-table {
  border: 1px solid;
}

.bwi-rpm-grid-scroll {
  max-height: 185px;
  min-height: 120px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-left: 3px;
  padding-top: 10px;
  padding-left: 12px;
}

.bwi-rpm-grid-row {
  flex-wrap: inherit;
  margin-left: -5px;
  margin-right: -5px;
  width: 99%;
  padding-bottom: 10px;
  display: flex;
  align-items: flex-start;
}

.bwi-rpm-grid-header-row {
  align-items: center !important;
}

.bwi-rpm-grid-span {
  white-space: break-spaces;
}

.bwi-rpm-grid-row :nth-child(1) {
  margin-left: 0px;
  display: flex;
  align-items: center;
}

.bwi-rpm-grid-header-col {
  display: flex;
  align-items: flex-start;
  padding-right: 6px;
  padding-left: 6px;
  text-align: left;
}

.bwi-rpm-grid-row .header-checkbox {
  margin-left: 0 !important;
  padding-left: 2px;
  padding-right: 6px;
}

.bwi-rpm-grid-row .bwi-checkbox {
  padding-bottom: 3px;
}

.bwi-rpm-grid-row .bwi-checkbox svg {
  height: 17px;
}

.bwi-rpm-grid-header-row span {
  padding-right: 5px;
}

.bwi-rpm-grid-sort-div {
  display: flex;
  padding-right: 3px;
}

.bw-uniqueRPM-span {
  text-align: left;
  white-space: nowrap;
}

.bwi-desc-length-txt {
  word-break: break-all;
}

.bwi-rpm-grid-dropdown-filter {
  padding-top: 3px;
}

.bwi-rpm-grid-country-dropdown-toggle {
  padding-top: 3px;
}

.bwi-rpm-grid-dropdown-filter {
  background: #353535;
  color: #edf1f3;
  left: 18px;
  position: absolute;
  top: 20px;
  z-index: 2;
}

.bwi-rpm-dropdown-open {
  border: 1px solid #797775;
  border-radius: 0;
}

.bwi-rpm-grid-dropdown-filter .options-container {
  display: flex;
  flex-direction: column;
}

.bwi-rpm-grid-dropdown-filter .dsf-delistingProj-search {
  padding-top: 6px;
}

.bwi-rpm-grid-dropdown-filter .dsf-delistingProj-search-icon {
  padding-left: 20px;
  padding-right: 5px;
}

.bwi-rpm-grid-dropdown-filter .options-list {
  line-height: 1.7;
  padding: 5px 0;
}

.bwi-rpm-grid-dropdown-filter .option-row {
  height: auto;
  min-height: 30px;
  word-break: break-all;
}

.bwi-rpm-grid-dropdown-filter .Mui-checked,
.bwi-rpm-grid-dropdown-filter .MuiCheckbox-root {
  color: #edf1f3 !important;
  padding: 0 9px;
}

.bwi-rpm-grid-recipient-enable {
  padding-bottom: 0px !important;
  margin-left: -18px !important;
}

.bwi-rpm-grid-mail-data {
  height: 50px;
  margin-top: 0px;
}

.bwi-rpm-grid-mail-row1 {
  margin-top: 10px;
}

.bwi-rpm-grid-textBox-label {
  display: flex;
  flex: 23% 1;
  justify-content: left;
}

.bwi-unique-rpm-checkbox-info {
  justify-content: end;
  opacity: 0.5;
  padding-top: 15px;
  width: 100%;
  padding-bottom: 0px;
}

.bwi-unique-rpm-header-checkbox {
  margin-left: 0;
  padding-left: 2px;
  padding-right: 6px;
}

.bwi-unique-rpm-legend-span {
  padding-top: 1px;
  text-align: left !important;
  white-space: nowrap !important;
}

.bwi-rpm-grid-mail-row1 label {
  color: #8a8d8c;
  font-family: Unilever Shilling;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 11px;
}

.bwi-rpm-grid-textBox-label1 {
  display: flex;
  flex: 23% 1;
  justify-content: left;
}

.bwi-rpm-grid-textBox-label2 {
  display: flex;
  flex: 73% 1;
  justify-content: left;
}

.bwi-rpm-grid-textBox-div1 {
  display: flex;
  flex: 25% 1;
  flex-direction: column;
  justify-content: left;
}

.bwi-rpm-grid-textBox-div1 input {
  background-color: transparent;
  border: 1px solid #797775;
  color: #edf1f3;
  height: 24px;
  width: 70%;
}

.bwi-rpm-grid-textBox-div2 {
  display: flex;
  flex: 55% 1;
  justify-content: left;
}

.bwi-rpm-grid-textBox-div2 input {
  background-color: transparent;
  border: 1px solid #797775;
  color: #edf1f3;
  height: 24px;
  width: 90%;
}

.bwi-rpm-grid-textBox-div3 {
  display: flex;
  flex: 20% 1;
  justify-content: left;
}

.bwi-unique-rpm-assign-btn {
  background: #8fcaeb;
}

.bwi-unique-rpm-assign-btn:hover,
.bwi-unique-rpm-assign-btn:active,
.bwi-unique-rpm-assign-btn:focus,
.bwi-unique-rpm-assign-btn:disabled {
  border-color: #6c757d;
  color: #292929;
  opacity: 0.65;
  background: #8fcaeb !important;
}

.bwi-skipInventory-div {
  margin-top: 5px;
}

.bwi-modal-unique-rpm-footer {
  padding-bottom: 6px;
  display: flex;
  justify-content: space-between;
  padding-top: 6px;
  padding-left: 20px;
}

.bwi-rndsignoff-footer {
  display: flex;
  justify-content: space-between !important;
  margin-top: 20px;
  padding-bottom: 10px;
  margin-left: 20px;
}

.bwi-rpm-grid-row .not-selected {
  color: #f1a33a;
}

.bwi-rpm-grid-row .unique-selected {
  color: #61d4c4;
}

.bwi-rpm-grid-row .non-unique-selected {
  color: #ec6157;
}

.bwi-modal-unique-rpm-status-body .bwi-rpm-grid-scroll {
  max-height: 250px !important;
}

.bwi-rndsignoff-footer-btn {
  display: flex;
}

.bwi-rndsignoff-footer-btn-ok {
  margin-right: 0px !important;
}

.bwi-unique-rpm-status-wait-div {
  max-height: 270px;
  padding: 9%;
}

.bwi-rnd-legend-info {
  justify-content: end;
  padding-top: 15px;
  width: 100%;
  padding-bottom: 0px;
  padding-right: 13px;
}

.bwi-rpm-info-icon {
  width: 10px;
  height: 10px;
  align-self: center;
  margin-right: 4px;
  cursor: pointer;
}

.bwi-modal-material-redundant {
  background-color: #1e232f;
  color: #edf1f3;
  width: 348px;
  max-height: 279px;
  height: fit-content !important;
  padding-left: 8px;
}

.bwi-modal-material-redundant .bw-modal-body {
  max-height: 222px;
  overflow-y: auto;
  overflow-x: hidden;
}

.bwi-body-desc {
  font-family: Unilever Shilling;
  font-weight: 400;
  font-size: 12px;
  text-align: left;
}

.bwi-project-details-div {
  border-bottom: 1px solid white;
  padding: 10px 0;
}

.bwi-project-details-div:last-child {
  border-bottom: none;
}

.bwi-project-details-row {
  padding: 4px 0;
}
