.bwi-stepper .MuiButtonBase-root:hover,
.bwi-stepper .MuiButtonBase-root:focus-within {
  outline: unset;
}

.bwi-stepper .MuiStepIcon-root.active {
  transform: scale(1.2); /* Change the scale factor as needed */
}

.bwi-stepper-label img {
  z-index: 1;
}

.bwi-stepper-label-active img {
  height: 34px;
  background: #1f2123;
}

.bwi-stepper-homepage .bwi-stepper-label img {
  height: 20px;
}

.bwi-stepper-label-active .MuiStepLabel-alternativeLabel {
  font-weight: 900 !important;
}

.bwi-stepper-label .MuiStepLabel-alternativeLabel {
  font-family: "Unilever Shilling";
}

.bwi-stepper-invcm-disable .MuiStepConnector-line {
  background-color: #61d4c4 !important;
}

.bwi-stepper-cg-waiting .MuiStepConnector-line {
  background: linear-gradient(to right, #61d4c4 55%, #4b4b4b 45%);
}

.bwi-stepper-tooltip-cg {
  background-color: #1e232f;
  color: #edf1f3;
  border: 0.5px solid #797775;
  position: absolute;
  z-index: 2;
  width: 197px;
  height: 143px;
  text-align: left;
  padding: 11px;
  top: 60px;
}

.bwi-stepper-tooltip-cg p {
  font-size: 11px;
  line-height: 16.5px;
}
