.bwi-modal-checkInnoflex {
  width: 99%;
  max-height: 480px;
}

.bwi-modal-checkInnoflex-header {
  height: unset !important;
  margin-bottom: 5px;
}

.bwi-modal-checkInnoflex-header-title {
  display: flex;
  align-items: end;
  justify-content: space-between;
}

.bwi-checkinnoflex-header-row {
  padding-bottom: 8px;
}

.bwi-modal-checkInnoflex-body {
  padding-left: 25px;
  padding-right: 5px;
  /* width: 101%; */
}

.bwi-nested-innoflex-data-row {
  max-height: 225px;
  min-height: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 2px;
  /* width: 101%; */
}

.bwi-modal-checkInnoflex-body-content-scroll {
  padding-right: 15px;
  width: 101%;
}

.bwi-modal-checkinnoflex-footer {
  padding: 17px 0 10px 25px;
}

.dsf-custom-datepicker-parent-div {
  position: fixed;
  z-index: 9999;
}

.bwi-checkinnoflex-datepicker, .bwi-relative {
  position: relative;
}

.bwi-checkinnoflex-datepicker .bwi-custom-datepicker .MuiInputBase-root {
  max-width: 258px !important;
}

.bwi-checkinnoflex-datepicker .MuiTextField-root {
  width: 240px;
}

.bwi-modal-checkInnoflex-body
  .bwi-checkInnoflex-startdatepicker-left
  .dsf-custom-datepicker-parent-div {
  position: fixed;
  z-index: 9999;
  top: 74%;
  left: 41%;
}

.bwi-modal-checkInnoflex-body
  .bwi-checkInnoflex-enddatepicker-left
  .dsf-custom-datepicker-parent-div {
  position: fixed;
  z-index: 9999;
  top: 37%;
  left: 44%;
}

.bwi-modal-checkInnoflex-body
  .bwi-checkInnoflex-launchdatepicker-left
  .dsf-custom-datepicker-parent-div {
  position: fixed;
  z-index: 9999;
  top: 37%;
  left: 64%;
}

/* new layout changes styles start */

.bwi-nested-innoflex-row {
  display: flex;
  justify-content: space-between;
  align-items: end;
}
.bwi-nested-innoflex-flex17 {
  flex: 0 0 17%;
  display: flex;
}
.bwi-nested-innoflex-flex19 {
  flex: 0 0 19%;
  display: flex;
}
.bwi-justify-center {
  justify-content: center;
}
.bwi-nested-innoflex-flex20 {
  flex: 0 0 20%;
  display: flex;
}
.bwi-nested-innoflex-flex12 {
  flex: 0 0 12%;
  display: flex;
}
.bwi-nested-innoflex-flex10 {
  flex: 0 0 10%;
  display: flex;
}
.bwi-nested-innoflex-flex8 {
  flex: 0 0 8%;
  display: flex;
  align-items: center;
}
.bwi-nested-innoflex-flex7 {
  flex: 0 0 7%;
  display: flex;
  align-items: center;
}
.bwi-nested-innoflex-flex6{
  flex: 0 0 6%;
  display: flex;
  align-items: center;
}
.bwi-nested-innoflex-flex14 {
  flex: 0 0 14%;
  display: flex;
  align-items: center;
}
.bwi-nested-innoflex-flex15 {
  flex: 0 0 15%;
  display: flex;
  align-items: center;
}
.bwi-reference, .bwi-action, .bwi-country, .bwi-fgcodes{
  width: 70%;
  position: relative;
  display: flex;
  justify-content: space-between;
}
.bwi-rpm-filter-arrow.bwi-arrowup{
    transform: rotate(180deg);
} 
.bwi-rpm-filter-arrow.bwi-arrowdown{
  transform: rotate(0deg);
} 
.bwi-relative-container {
  position: relative;
  display: flex;
  cursor: pointer;
}
.bwi-ellipsis {
  position: relative;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 170px;
  cursor: pointer;
  text-align: left;
}
.bwi-nested-innoflex-flex80 {
  flex: 0 0 80%;
  display: flex;
}

.bwi-nested-innoflex-arrow {
  height: 16px;
}
.bwi-tick-parent, .bwi-relative {
  position: relative;
}
.bwi-tick-parent .bwi-tick-img {
  position: absolute;
  top: 5px;
}
.bwi-text-highlight {
  color: #00d6c8;
}
.bwi-nested-innoflex-arrow-down {
  height: 14px;
}

.bwi-nested-innoflex-flex40 {
  flex: 0 0 40%;
  display: flex;
  justify-content: space-around;
}
.bwi-nested-innoflex-flex33 {
  flex: 0 0 33%;
  display: flex;
  justify-content: space-around;
}
.bwi-nested-innoflex-flex25 {
  flex: 0 0 25%;
  display: flex;
  justify-content: space-around;
}
.bwi-flex-items {
  display: flex;
  align-items: end;
}
.bwi-nested-innoflex-flex-center {
  display: flex;
  justify-content: center;
}

.bwi-nested-innoflex-skip-validation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}

.bwi-nested-innoflex-row .bwi-checkinnoflex-datepicker {
  width: 50%;
}

.bwi-nested-innoflex-row .bwi-custom-datepicker input {
  text-align: unset !important;
}

.bwi-checkInnoflex-enddatepicker-left .dsf-custom-datepicker-open {
  transform: translate3d(248px, -2px, 0px);
}

.bwi-checkInnoflex-launchdatepicker-left .dsf-custom-datepicker-open {
  transform: translate3d(-680px, -2px, 0px);
}
.bwi-review-tooltip {
  border: 1px solid #c5c5c5;
  background-color: #1e232f;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  padding: 10px;
  width: 300px;
  position: absolute;
  bottom: -85px;
  right: -8px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s;
  height: 80px;
  z-index: 99;
  text-align: left;
  overflow-y: auto;
}
.bwi-action-tooltip{
  height: initial;
  bottom: -45px;
}


.bwi-relative-container:hover .bwi-review-tooltip {
  opacity: 1;
  visibility: visible;
  cursor: pointer;
}
.bwi-nested-innoflex-skip-validation .dsf-InventoryCM-checkbox {
  padding-bottom: 4px;
}
.bwi-right .dsf-InventoryCM-checkbox {
  right: 3px;
}
/* new layout changes styles end */
.bwi-custom-dropdown {
  position: absolute;
  background-color: #212529;
  border: 1px solid #ccc;
  padding: 10px;
  width: auto;
  height: 150px;
  top: 25px;
  max-width: 250px;
  text-align: left;
  z-index: 999;
}

.bwi-dropdown-search {
  width: 90%;
  margin-bottom: 5px;
  color: #fff;
  background-color: transparent;
  border: 0;
}
.bwi-dropdown-search:focus-visible{
  outline: 0;
}
.bwi-filter-dropdown-options {
  max-height: 95px;
  overflow-y: auto;
  overflow-x: hidden;
}
.bwi-filter-dropdown-options-checkbox {
  display: flex;
  align-items: baseline;
  margin-bottom: 5px;
}
.bwi-filter-dropdown-options-checkbox span{
  padding-left: 5px;
}
.bwi-flex{
  display: flex;
}
.bwi-reference-main{
  justify-content: flex-start;
}
.bwi-flag-align{
  justify-content: space-between;
}
.bwi-nested-innoflex-row-header{
  padding-bottom: 15px;
}
.bwi-tick-space{
  margin: 0 30px 0 -25px;
}
.bwi-country-header{
  width: 60%;
  display: flex;
  justify-content: space-between;
}
.bwi-empty-space{
  margin-right: 15px;
}