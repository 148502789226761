.dsf-dialog-task-details {
  max-width: 550px !important;
  max-height: 330px !important;
}

.dsf-modal-workflowModal {
  padding: 8px;
}

.dsf-task-modal-title {
  text-align: left;
  padding-right: 34px;
}
.dsf-textField {
  background-color: transparent;
  border: 1px solid #797775;
  color: #edf1f3 !important;
  font-family: "Unilever Shilling";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: left;
  height: 24px;
  width: 307px;
}

.dsf-textField::placeholder {
  color: #ec6157;
}

.bw-label {
  color: #edf1f3;
  line-height: 16px;
}

.dsf-dialog-task-details .bw-modal-body {
  max-height: 250px;
  overflow: auto;
  overflow-x: hidden;
}

.bw-modal-button {
  display: flex;
  color: #292929;
  font-family: "Unilever Shilling";
  padding: 5px 25px;
  background: #c7c7c7;
  font-size: 12px;
  font-weight: 600;
  width: 82px;
  height: 24px;
  justify-content: center;
  align-items: center;
}

.bw-modal-button:hover {
  color: #292929;
  background-color: #c7c7c7;
  border-color: #292929;
}

.bw-modal-button:active {
  color: #fff !important;
  background-color: #545b62 !important;
  border-color: #4e555b !important;
}

.bw-modal-button:disabled {
  color: #292929;
  background-color: #c7c7c7;
  border-color: #c7c7c7;
}
