.tableGraph td {
    font-family: 'Unilever Shilling';
    font-weight: 400;
    font-size: 8px;
}

.tableGraph {
    width: 770px;
    margin: 'auto';
    border-collapse: separate;
    border-spacing: 0 8px;
}