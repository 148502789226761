.dsf-header {
  width: 100%;
  flex: 1;
  height: 54px;
  left: 0px;
  top: 0px;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
}
.dsf-header-color-PI {
  background: #1a0b32;
}
.dsf-header-color {
  background: #353535;
}

.dsf-header-logo-div {
  left: 74px;
  position: relative;
  flex: 0.1 1;
  align-content: center;
  flex-wrap: wrap;
  display: flex;
}
.dsf-header-title-div {
  position: relative;
  flex: 0.4 1;
  align-content: center;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-left: 42px;
}
.dsf-header-title {
  /* flex: 0.4; */
  width: 348px;
  height: 54px;
  font-weight: 600;
  font-size: 22px;
  line-height: 50px;
  color: #ffffff;
}

.dsf-header-icon-div {
  flex: 0.4 1;
  justify-content: flex-end;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
}

.dsf-header-user-div {
  flex: 0.1 1;
  display: flex;
  left: 7%;
  position: relative;
  justify-content: center;
  align-items: center;
}
/* ..dsf-header-user-div { */

.dsf-brand {
  width: 140px;
  height: 24px;
  cursor: pointer;
}

.dsf-user-icon {
  top: -2px;
  position: relative;
  margin-right: 10px;
}

.dsf-header-icon {
  /* margin-right: 14px; */
  border: none;
  height: 24px;
  width: 24px !important;
  padding: 0px !important;
}
.dsf-header-icon:hover {
  background-color: #353535 !important;
}
.calender-icon {
  background: url("../../assets/images/calenderStatus.svg") no-repeat;
  cursor: pointer;
}

.bell-icon {
  background: url("../../assets/images/bell.svg") no-repeat;
  margin-left: 13.12px;
  pointer-events: none;
}

.ques-icon {
  background: url("../../assets/images/questionMark.svg") no-repeat;
  cursor: pointer;
  /* pointer-events: none; */
  margin-left: 9.56px;
}

.dsf-header-span {
  color: #ffffff;
  font-weight: 400;
  position: relative;
  align-self: center;
  top: -2px;
  white-space: nowrap;
}

.dsf-header-icon span {
  top: 12px;
  right: 12px;
  font-size: 8px;
  font-family: "Unilever Shilling";
}

.test-tooltip {
  max-width: 1000px;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  background: #1e232f !important;
  color: #edf1f3 !important;
  border-radius: 2px !important;
  border-color: #edf1f3;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root + .MuiDivider-root {
  background: #edf1f3;
}
