body,
html {
  background-color: #0d1116 !important;
  font-size: 14px;
  font-family: "Unilever Shilling";
  color: #0d1418;
  height: 100%;
  width: 100%;
  margin: 0;
  left: 0px;
  top: 0px;
}

h1 {
  font-family: "Unilever Shilling Bold";
  font-size: 56px;
  font-style: normal;
  font-variant: normal;
  font-weight: 600;
}

h2 {
  font-family: "Unilever Shilling Bold";
  font-size: 40px;
  font-style: normal;
  font-variant: normal;
  font-weight: 600;
}

h3 {
  font-family: "Unilever Shilling Medium";
  font-size: 32px;
  font-style: normal;
  font-variant: normal;
}

p {
  font-family: "Unilever Shilling";
  font-size: 14px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  pointer-events: none;
}
a {
  pointer-events: all;
}

span {
  color: #edf1f3;
  font-style: normal;
  pointer-events: none;
}

blockquote {
  font-family: "Unilever Shilling";
  font-size: 21px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 30px;
}

.has-error {
  border-radius: 4px;
  border: solid 2px #ff0000;
  background-color: rgba(0, 0, 0, 0.2);
}
.dsf-size8 {
  font-size: 8px;
}
.dsf-size18 {
  font-size: 18px;
}

.dsf-size16 {
  font-size: 16px;
}

.dsf-size24 {
  font-size: 24px;
}

.dsf-size10 {
  font-size: 10px;
}

.dsf-size11 {
  font-size: 11px;
}

.dsf-size12 {
  font-size: 12px;
}

.dsf-size20 {
  font-size: 20px;
}

.bg-color-b27293d {
  background-color: #27293d;
}

.dsf-p0 {
  padding: 0 !important;
}

.dsf-pright0 {
  padding-right: 0 !important;
}

.dsf-pleft0 {
  padding-left: 0 !important;
}

.dsf-span-xxl {
  font-size: 22px;
  font-weight: 600;
}

.dsf-span-xl {
  font-weight: 600;
  font-size: 20px;
}

.dsf-span-l {
  font-weight: 600;
  font-size: 18px;
}

.dsf-span-m {
  font-weight: 600;
  font-size: 16px;
}

.dsf-span-ml {
  font-weight: 900;
  font-size: 16px;
}

.dsf-span-mx {
  font-weight: 900;
  font-size: 24px;
}

.dsf-span-s {
  font-weight: 400;
  font-size: 12px;
}

.dsf-span-ss {
  font-weight: 600;
  font-size: 12px;
}

.dsf-span-sx {
  font-weight: 700;
  font-size: 12px;
}

.dsf-span-x {
  font-weight: 400;
  font-size: 10px;
}

.dsf-span-xs {
  font-weight: 600;
  font-size: 10px;
}

.dsf-span-sl {
  font-weight: 900;
  font-size: 12px;
}

.dsf-font-weight-700 {
  font-weight: 700 !important;
}

.dsf-font-weight-600 {
  font-weight: 600 !important;
}

.dsf-font-weight-400 {
  font-weight: 400 !important;
}

.modal {
  text-align: center;
}

.dsf-text-left {
  text-align: left;
}

.dsf-text-center {
  text-align: center;
}

.dsf-text-right {
  text-align: right;
}

.dsf-font-unileverMedium {
  font-family: "Unilever Shilling Medium";
}

.bw-info-tool-tip-div {
  /* display: flex;
  flex-direction: column-reverse; */
  position: relative;
  /* display: flex;
    flex-direction: column; */
}

.bw-info-tool-tip-text {
  /* padding: 12px 18px; riya commented*/
  /* height: 66px;
  width: 244px; */
  height: fit-content;
  width: fit-content;
  font-size: 10px;
  color: #edf1f3;
  z-index: 2;
  line-height: 14px;
  display: flex;
  text-align: left;
  /* align-items: center; */
  /* justify-content: space-evenly; */
}
.bw-info-tool-tip-div img {
  color: #a39f9f;
}

.tooltip-position-top {
  /* top: 23%; */
  /* position: fixed; */
  transform: translate3d(-70%, -83px, 0px);
  position: absolute;
}

.tooltip-position-top-delists {
  transform: translate3d(-45%, -133px, 0px);
  position: absolute;
  text-align: left !important;
  align-self: left !important;
}

.tooltip-position-default .bw-info-tool-tip-text {
  white-space: pre;
  margin-left: 10px;
  text-wrap: wrap;
}

.tooltip-position-top-delists-small-Text {
  /* transform: translate3d(-36%, -38px, 0px); */
  transform: translate3d(8%, -9px, 0px);
  position: absolute;
}

.tooltip-position-top-right-delists {
  transform: translate3d(8%, -75px, 0px);
  position: absolute;
  text-align: left !important;
  align-self: left !important;
}

.tooltip-position-bottom-right-delists {
  transform: translate3d(8%, -30px, 0px);
  position: absolute;
  text-align: left !important;
  align-self: left !important;
}

.tooltip-position-top-delists-email-Text {
  transform: translate3d(-75%, -63px, 0px);
  position: absolute;
}

.tooltip-position-default {
  position: absolute;
  transform: translate3d(8%, -9px, 0px);
}

.info-tooltip-div {
  background: #1e232f;
  border: 0.5px solid #797775;
  padding: 12px 18px;
  /* max-width: 244px !important;
  max-height: 66px !important; */
  /* width: max-content !important;
  height: max-content !important; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; riya commented*/
}

.additonalToolTipStyle {
  margin: -12px !important;
}

.bw-help-tooltip {
  position: fixed;
  background: #1e232f;
  border: 0.5px solid #797775;
  z-index: 2;
  right: 7%;
  top: 7% !important;
  padding: 20px 18px;
  max-width: 443px !important;
  max-height: 159px !important;
  width: max-content !important;
  height: max-content !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.bw-help-tool-tip-text {
  position: relative;
  margin-top: 8px;
  font-size: 10px;
  font-weight: 400;
  font-family: "Unilever Shilling";
  color: #edf1f3;
}

.bw-tooltip-header {
  position: relative;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  font-family: "Unilever Shilling";
  color: #d7e4ff;
}

.gap-div {
  margin-right: 25px;
}
.col-basis-5 {
  flex-basis: 5%;
}
.col-basis-10 {
  flex-basis: 10%;
}
.col-basis-15 {
  flex-basis: 15%;
}
.col-basis-20 {
  flex-basis: 20%;
}
.col-basis-25 {
  flex-basis: 25%;
}
.col-basis-30 {
  flex-basis: 30%;
}
.col-basis-40 {
  flex-basis: 40%;
}
.col-basis-50 {
  flex-basis: 50%;
}
.col-basis-60 {
  flex-basis: 60%;
}
.col-basis-70 {
  flex-basis: 70%;
}
.col-basis-75 {
  flex-basis: 75%;
}
.col-basis-80 {
  flex-basis: 80%;
}
.col-basis-85 {
  flex-basis: 85%;
}
.col-basis-90 {
  flex-basis: 90%;
}

.grey-span {
  color: #797775;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: #797775; */
  opacity: 0.5;
}

.bringViewInFront {
  z-index: 1;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 30%;
  left: 40%;
  position: relative;
  width: 30px;
  height: 20px;
}
.errorBorder {
  border: 1px solid #ec6157 !important;
}
.successBorder {
  border: 1px solid #797775;
}

.errorMsg,
.dsf-label-mandatory-field {
  color: #ec6157;
}

.bwi-padding-right-10 {
  padding-right: 10px;
}
