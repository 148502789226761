.bwi-carousel {
  position: relative;
  width: 98%;
  margin-top: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.bwi-carousel-item-container {
  width: 96%;
  height: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  box-sizing: border-box;
}

.bwi-carousel-item-container-extend-left,
.bwi-carousel-item-container-extend-right {
  width: 98% !important;
}

.bwi-carousel-button {
  width: 2%;
  height: 106px;
  background-color: #8fcaeb;
  border: none;
  cursor: pointer;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bwi-carousel-button:disabled {
  background-color: rgba(0, 0, 0, 0.2);
  cursor: not-allowed;
}

.bwi-carousel-button-left {
  left: 0;
  border-start-start-radius: 5px;
  border-end-start-radius: 5px;
}

.bwi-carousel-button-right {
  right: 0;
  border-start-end-radius: 5px;
  border-end-end-radius: 5px;
}

.bwi-carousel-item-container-extend-left::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 3.5%;
  height: 100%;
  background: linear-gradient(90deg, #0c0e10 0%, transparent 100%);
}

.bwi-carousel-item-container-extend-right::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 3.5%;
  height: 100%;
  background: linear-gradient(90deg, transparent 0%, #0c0e10 100%);
}
