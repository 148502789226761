.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
::-webkit-scrollbar {
  width: 7px;
}
/* Track */
::-webkit-scrollbar-track {
  
  /* background: #797775; */
  opacity: 0.5;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border: 1px;
  border-color: #f1f1f1;
  border-radius: 4px;
  background: #f1f1f1;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f1f1f1;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@font-face {
  font-family: "Unilever Shilling";   /*Can be any text*/
  src: local("UnileverShilling"),
    url("./assets/fonts/UnileverShilling.ttf") format("truetype");
}
@font-face {
  font-family: "Unilever Shilling Bold";   /*Can be any text*/
  src: local("UnileverShillingBold"),
    url("./assets/fonts/UnileverShillingBold.ttf") format("truetype");
}
@font-face {
  font-family: "Unilever Shilling Italic";   /*Can be any text*/
  src: local("UnileverShillingItalic"),
    url("./assets/fonts/UnileverShillingItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Unilever Shilling Medium";   /*Can be any text*/
  src: local("UnileverShillingMedium"),
    url("./assets/fonts/UnileverShillingMedium.ttf") format("truetype");
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* To resolve the Browser compatability issue for checkbox 
we done the below change which works for all browsers*/
input[type="checkbox"] {
  accent-color: dodgerblue;
}
.checkbox-group {
  display: block;
  margin-bottom: 15px;
}

.checkbox-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.checkbox-group label {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: baseline;
}

.checkbox-group label:before {
  content:'';
  -webkit-appearance: none;
  background-color: #edf1f3;
  border: 1px solid #edf1f3;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 3px;
}

.checkbox-group input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 2px;
  left: 5px;
  width: 6px;
  height: 11px;
  border: solid #0d1116;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}