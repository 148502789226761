.project-info-tooltip-label {
  display: flex;
  flex-direction: column;
  max-height: -webkit-fill-available;
}

.bw-proj-info-tooltip {
  position: relative;
  background: #353535;
  border: 0.5px solid #797775;
  gap: 6px;
  z-index: 2;
  width: max-content !important;
  height: max-content !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.bw-proj-info-tooltip-text {
  padding-top: 6px;
  position: relative;
  font-size: 12px;
  font-weight: 400;
  font-family: "Unilever Shilling";
  color: #edf1f3;
}

.bw-proj-info-tooltip-header {
  position: relative;
  line-height: 22px;
  font-weight: 600 !important;
  font-size: 16px !important;
}
