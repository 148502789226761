.deepdive-modal-content {
  background-color: #0d1116;
  color: #edf1f3;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border-top: 1px solid;
  border-radius: 0%;
  border: 1px solid #c7c7c7;
  /* overflow-y: scroll;
  overflow-x: hidden; */
}

.deepdive-fullscreen-modal {
  z-index: 100;
}

.deepdive-fullscreen-modal .dsf-header-div {
  max-height: 8vh;
  min-height: 8vh;
}

.deepdive-fullscreen-modal .dsf-header-div .dsf-header-empty-div {
  border-bottom: 1px solid #c7c7c7;
  opacity: 0.5;
  height: 2%;
}

.deepdive-fullscreen-modal .dsf-header-div .dsf-header {
  background: #1e232f !important;
  height: 98%;
  padding: 10px 0px 2px 0px;
}

.deepdive-fullscreen-modal .dsf-header-div .dsf-header .dsf-header-title-div {
  display: flex;
  align-items: baseline;
  margin-left: 24px !important;
}

.dsf-deepdive-parent-div {
  min-height: 92%;
  max-height: 80vh;
  overflow: auto;
  overflow-x: hidden;
  padding-right: 10px;
}

.dsf-deepdive-parent-row-col1 {
  padding: 0px 10px 0px 54px;
  background-color: #0d1116;
}

.dsf-deepdive-parent-row1 .loading-spinner {
  position: absolute;
  top: 45%;
  left: 50%;
}

.dsf-deepdive-title-row {
  height: 6%;
  padding: 6px 0px;
  margin-right: 0px;
}

.country_combo_chart_div {
  width: 100%;
}

.dsf-deepdive-title p {
  font-family: "Unilever Shilling";
  font-size: 12px;
  font-weight: 600;
  color: #c7c7c7;
  text-align: start;
  text-transform: uppercase;
  /* padding-left: 10px; */
}

.dsf-deepdive-close {
  text-align: end;
}

/******************* child-row1*******************/
.dsf-deepdive-child-row2,
.dsf-deepdive-child-row3 {
  margin-top: 16px;
}

.dsf-deepdive-child-row1-col1,
.dsf-deepdive-child-row1-col2,
.dsf-deepdive-child-row2-col1,
.dsf-deepdive-child-row2-col2,
.dsf-deepdive-child-row2-col3,
.dsf-deepdive-child-row3-col1 {
  padding: 0px 8px;
}

.dsf-deepdive-child-row1-col1-div,
.dsf-deepdive-child-row1-col2-div,
.dsf-deepdive-child-row2-col1-div,
.dsf-deepdive-child-row2-col2-div,
.dsf-deepdive-child-row2-col3-div {
  padding: 10px 14px 4px 8px;
  height: 180px;
  background: #1e232f;
}

.dsf-deepdive-child-row-col-div-text {
  padding: 10px 4px 4px 8px;
  height: 180px;
  background: #1e232f;
  align-items: center;
  display: flex;
  justify-content: center;
}

.dsf-deepdive-child-row-col-div-text span {
  font-size: 11px;
  font-weight: 400;
  color: #d7e4ff;
  margin-bottom: 15px;
}

.dsf-deepdive-stackedBar-chart-row-col2 {
  max-height: 140px;
  /* height: 140px; */
  overflow: scroll;
  padding-left: 5px !important;
}

.dsf-deepdive-stackedBar-chart-row-col2::-webkit-scrollbar {
  display: none;
}

/******************* R0w-1*************/

.dsf-dashboard-deepdive ul.dsp-deepdive-toggle-ul {
  left: 0%;
}

.dsf-dashboard-deepdive ul li.dsp-deepdive-toggle-li {
  width: 49%;
  height: 24px;
  border: 1px 0px 0px 0px;
  opacity: revert;
  background: #d7e4ff;
}

.dsf-dashboard-deepdive ul li.active {
  font-weight: 900;
  color: #1f2123;
}

.dsf-deepdive-donut-chart-row {
  margin-top: 6px;
}

.dsf-deepdive-donut-chart-row-col1 div {
  text-align: start;
  margin-left: 4px;
}

.dsf-bw-title {
  font-family: "Unilever Shilling";
  font-size: 11px;
  font-weight: 400;
  color: #cfcfcf;
}

.dsf-bw-value {
  font-family: "Unilever Shilling";
  font-size: 20px;
  font-weight: 600;
  color: #edf1f3;
  margin-top: 4px;
}

.donut-legends-main-div {
  position: relative;
  top: 24%;
}

.dsf-deepdive-totalBw-value-div {
  height: 50%;
}

.donut-volume-legends-main-div {
  padding-top: 30px;
}

/* .donut-chart-div div{
    z-index: 1;
} */

/***************** Row-2 ***********************/
.dsf-deepdive-barchart-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 10px;
}

.dsf-deepdive-barchart-name {
  font-family: "Unilever Shilling";
  font-size: 11px;
  font-weight: 600;
  color: #d7e4ff;
}

.dsf-deepdive-barchart-value {
  font-family: "Unilever Shilling";
  font-size: 11px;
  font-weight: 400;
  color: #cfcfcf;
}

.dsf-deepdive-barchart-value span {
  font-family: "Unilever Shilling";
  font-size: 11px;
  font-weight: 600;
}

.dsf-deepdive-barchart-value-FG {
  color: #8fcaeb;
}

.dsf-deepdive-barchart-value-RM {
  color: #fceda4;
}

.dsf-deepdive-barchart-value-PM {
  color: #c79ad5;
}

/***************** Row-3 ***********************/
.dsf-deepdive-child-row3-col1-div {
  padding: 10px 4px 4px 8px;
  height: 230px;
  background: #1e232f;
}

.dsf-deepdive-detaile-table {
  font-family: "Unilever Shilling";
  font-size: 11px;
  font-weight: 400;
  color: #b7d3ff;
}

.dsf-deepdive-detaile-table:hover {
  text-decoration: underline;
  cursor: pointer;
}

.dsf-deepdive-detaile-table img {
  margin-left: 5px;
}

.align-data-div {
  text-align: start;
  margin-left: 9px;
}

.dsf-legen-style {
  font-family: "Unilever Shilling";
  font-size: 11px;
  font-weight: 400;
  color: #edf1f3;
}

/************** detailed table ***************/
.bwi-dialog-deepdive-table {
  max-height: 70% !important;
  max-width: 90% !important;
  min-width: 90% !important;
}

.bwi-modal-deepdive-table {
  background-color: #0c0e10;
  color: #edf1f3;
  padding: 10px;
}

.bwi-modal-deepdive-table-header-title span {
  font-weight: 600;
  font-size: 16px;
  color: #d7e4ff;
}

.bwi-modal-deepdive-table-header {
  border: initial;
  text-align: left;
  padding-bottom: 5px;
}

.bwi-deepdive-table-tabrow {
  margin-bottom: 18px;
  display: flex;
  justify-content: space-between;
}

.bwi-deepdive-table-tabrow ul.dsp-deepdive-toggle-ul {
  left: 0%;
  background: #1e232f;
  width: 50%;
}

.bwi-deepdive-table-tabrow ul li.dsp-deepdive-toggle-li {
  width: 33.3%;
  height: 24px;
  border: 1px 0px 0px 0px;
  opacity: revert;
  color: #d7e4ff;
  border: 1px solid #2e374c;
}

.bwi-deepdive-table-tabrow ul li.active {
  font-weight: 900;
}

.bwi-deepdive-table-tabrow ul li.deactive {
  font-weight: 400;
  opacity: 0.3;
}

.bwi-deepdive-table-template span {
  font-size: 11px;
  font-weight: 400;
  color: #d7e4ff;
  margin-left: 4px;
}

.bwi-deepdive-table-template:hover {
  text-decoration: underline;
  color: #d7e4ff;
}

.dsf-deepdive-col-disable {
  pointer-events: none;
  /* opacity: 0.7; */
}

.bwi-deepdive-table-data {
  width: 100%;
}

.bwi-deepdive-table-data button {
  background: #8fcaeb;
}

.bwi-deepdive-table-data .loading-spinner {
  left: 0% !important;
  top: 0% !important;
}

/******************* basic style *************/
.span-gap {
  margin-right: 9px;
}

/******************* action style start *************/
.dsf-dashboard-deepdive {
  max-height: 92vh;
  min-height: 92vh;
  background: #1e232f;
}

.dsf-deepdive-parent-row1 {
  min-height: 100%;
  width: 101%;
}

/* .dsf-deepdive-parent-row-col2 {
  padding-left: 35px;
} */

.dsf-deepdive-action-parent-row {
  background: #1e232f;
  height: 100%;
  border-left: 0.25px solid #c7c7c7;
}

.dsf-deepdive-action-parent-div {
  width: 100%;
  padding: 15px;
  position: relative;
  word-break: break-word;
}

.dsf-deepdive-action-title-div {
  position: absolute;
}

.dsf-deepdive-action-title-div {
  width: 94%;
  min-height: 96%;
  max-height: 96%;
  overflow: auto;
  overflow-x: hidden;
  padding-bottom: 30px !important;
  padding: 0px 5px;
}

.dsf-deepdive-action-title-div::-webkit-scrollbar {
  /* display: none; */
  width: 7px !important;
}

.dsf-deepdive-action-title-span {
  font-family: "Unilever Shilling";
  font-size: 12px;
  font-weight: 600;
  color: #c7c7c7;
  text-align: left;
}

.dsf-deepdive-action-details-div {
  text-align: left;
  font-family: "Unilever Shilling";
}

.dsf-deepdive-action-button-div {
  position: absolute;
  bottom: 3%;
  width: 90%;
}

.dsf-deepdive-action-button-footer-div {
  width: 93% !important;
}

.dsf-deepdive-action-button-footer {
  width: 98% !important;
}

.dsf-deepdive-action-button {
  width: 95%;
  height: 24px;
  background: #8fcaeb;
  border: 1px solid #1f2123;
  color: #1f2123;
  font-family: "Unilever Shilling";
  font-size: 12px;
  font-weight: 600;
  padding-top: 2px;
  padding-bottom: 5px;
  border-radius: 2px;
}

.dsf-deepdive-action-button:hover,
.dsf-deepdive-action-button:focus,
.dsf-deepdive-action-button:active,
.dsf-deepdive-action-button:disabled {
  background: #8fcaeb !important;
  color: #1f2123 !important;
  box-shadow: none !important;
  outline: none !important;
}

.dsf-modal-add-edit-action {
  z-index: 9999;
}

.dsf-deepdive-action-material-div {
  margin: 15px 0px;
  width: 100%;
  border-bottom: 0.25px solid #c7c7c7;
}

.dsf-deepdive-action-material-div:last-child {
  border-bottom: none;
}

.dsf-deepdive-action-material-label {
  color: #d7e4ff;
  font-size: 12px;
  font-weight: 600;
}

/******************* action style end *************/

/******************* Add action style start *************/

.bwi-deepdive-add-edit-action-dialog {
  min-width: 500px !important;
  max-width: 500px !important;
}

.bwi-deepdive-add-edit-action-dialog-manual {
  min-width: 980px !important;
  max-width: 980px !important;
}

.bwi-deepdive-add-edit-action-dialog-manual .dsf-custom-datepicker-open {
  bottom: 230px !important;
  top: unset !important;
}

.bwi-deepdive-add-edit-action-modal {
  background-color: #1e232f;
  border: 0.1px solid #7d7d7d;
  border-radius: 0px;
  color: #ffffff;
  padding: 15px 10px;
}

.bwi-deepdive-add-edit-action-content {
  padding: 15px !important;
}

.bwi-deepdive-add-edit-action-header {
  padding: 2px 5px;
  border-bottom: none;
  padding-bottom: 5px;
}

.bwi-deepdive-add-edit-action-footer {
  padding: 2px 5px;
  border-top: none;
  margin: 0px;
  padding-top: 10px;
}

.bwi-deepdive-add-edit-action-footer .dsf-deepdive-action-button {
  width: 100%;
}

.bwi-deepdive-add-edit-action-footer-manual {
  padding: 2px 0px !important;
  display: flex;
  justify-content: left;
}

.bwi-deepdive-add-edit-action-footer-manual .dsf-deepdive-action-button {
  width: 10%;
}

.bwi-deepdive-add-edit-action-body {
  padding: 0px 5px;
  max-height: 520px;
  overflow: auto;
  overflow-x: hidden;
}

.bwi-deepdive-add-edit-action-row {
  margin: 12px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.bwi-deepdive-add-edit-action-row-manual {
  margin: 0px 0px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #292f3c;
  padding: 8px 1px 8px 8px;
}

.bwi-deepdive-add-edit-action-dialog-manual .bwi-deepdive-add-edit-action-row {
  margin-top: 4px !important;
}

.bwi-deepdive-add-edit-action-header-title span {
  color: #d7e4ff !important;
}

.bwi-deepdive-add-edit-action-label {
  font-family: "Unilever Shilling";
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 4px;
}

.bwi-deepdive-add-edit-action-label-title {
  font-family: "Unilever Shilling";
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 4px;
}

.bwi-deepdive-add-edit-action-row .custom-select,
.bwi-deepdive-add-edit-action-popper .MuiPopover-paper {
  background-color: #1e232f !important;
  color: #ffffff !important;
}

.bwi-deepdive-add-edit-action-row .custom-select .MuiInputBase-input {
  text-align: left !important;
}

.bwi-deepdive-add-edit-action-popper .MuiPopover-paper li:hover,
.bwi-deepdive-add-edit-action-popper .MuiPopover-paper li:focus {
  background-color: #2a3548;
}

.bwi-deepdive-add-edit-action-input {
  max-height: unset !important;
  height: 27px !important;
  background-color: #1e232f;
  border: 1px solid #7d7d7d;
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  padding: 0px 10px;
}

.bwi-deepdive-add-edit-action-textarea {
  max-height: unset !important;
  height: 160px !important;
  background-color: #1e232f;
  border: 1px solid #7d7d7d;
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  padding: 5px 10px;
}

.bwi-deepdive-add-edit-action-input:focus-visible,
.bwi-deepdive-add-edit-action-textarea:focus-visible {
  outline: none;
}

.bwi-deepdive-add-edit-action-row .input-attachments {
  width: 458px;
  height: unset !important;
  max-height: 290px !important;
  margin-left: 0px;
  background-color: #1e232f !important;
  border: none;
  color: #ffffff !important;
  font-size: 12px;
  font-weight: 400;
}

.bwi-deepdive-add-edit-action-row-manual .input-attachments {
  width: 458px;
  height: 340px !important;
  max-height: 340px !important;
  margin-left: 0px;
  background-color: #1e232f !important;
  border: none;
  color: #ffffff !important;
  font-size: 12px;
  font-weight: 400;
}

.bwi-deepdive-add-edit-action-dialog
  .bwi-deepdive-add-edit-action-row
  .E2E-preview-div {
  width: 458px;
  height: unset !important;
  max-height: 290px !important;
  margin-left: 0px;
  background-color: #1e232f !important;
  border: 1px solid #7d7d7d;
  color: #ffffff !important;
  font-size: 12px;
  font-weight: 400;
}

.bwi-deepdive-add-edit-action-dialog-manual
  .bwi-deepdive-add-edit-action-row
  .E2E-preview-div {
  width: 458px;
  height: 340px !important;
  max-height: 340px !important;
  margin-left: 0px;
  background-color: #292f3c !important;
  border: none;
  color: #ffffff !important;
  font-size: 12px;
  font-weight: 400;
}

.bwi-deepdive-add-edit-action-row .E2E-preview-div .E2E-preview-text1 {
  color: #ffffff;
}

/******************* Add action style end *************/

/******************* Action card style start *************/

.bwi-deepdive-action-card {
  background: #2a3548;
  margin: 20px 0px;
  font-family: "Unilever Shilling";
  padding: 5px 0px;
}

.bwi-deepdive-action-card .row {
  margin: 2px 0px;
  width: 100%;
}

.bwi-deepdive-action-card-toggle img {
  margin-bottom: 6px;
  margin-left: 60%;
}

.bwi-deepdive-action-card-description {
  font-size: 11px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 15px;
}

.bwi-deepdive-action-card-title {
  font-size: 12px;
  font-weight: 600;
  color: #d7e4ff;
}

.bwi-deepdive-action-card-label {
  font-size: 11px;
  font-weight: 400;
  color: #ffffff;
}

.bwi-deepdive-action-card-value {
  font-size: 11px;
  font-weight: 600;
  color: #ffffff;
}

.bwi-deepdive-action-card-status-col {
  margin: 4px 0px;
  padding: 0px 3%;
}

.bwi-deepdive-action-card-completed-col {
  margin-left: 59%;
}

.bwi-deepdive-action-card-completed-div img {
  margin-right: 8px;
}

.bwi-deepdive-action-card-contact-row {
  min-height: 40px;
}

.bwi-deepdive-action-card-title-row {
  margin-bottom: 25px !important;
}

/******************* Action card style end *************/

.fg-combo-chart-parent-div {
  overflow: auto;
  overflow-y: hidden;
  /* scrollbar-color: #50596C #50596C; */
}

.fg-combo-chart-parent-div::-webkit-scrollbar {
  width: 3px !important;
  height: 6px !important;
}
.fg-combo-chart-parent-div::-webkit-scrollbar-thumb {
  background-color: #50596c;
}

.dsf-deepdive-tab-parent-no-data {
  height: 94%;
  display: flex;
  align-items: center;
  justify-content: center;
}

svg > g > g.google-visualization-tooltip {
  pointer-events: none;
}
